import { Fragment, useEffect, useState } from 'react';
import RatingAllCategoryChart from '../components/RatingAllCategoryChart';
import RatingCategoryChart from '../components/RatingCategoryChart';
import Loading from "../Loading";
import SpiderChart from '../components/SpiderChart';
import TrendArrow from '../components/TrendArrow';
import TrendChart from '../components/TrendChart';
import api from "../Api";
import { jsPDF } from "jspdf";
import { toJpeg } from "html-to-image";
import pptxgen from "pptxgenjs";

function SummaryReport({ language, setNavbar, setActiveMenu, forceRefresh, currentRound, setCurrentRound, selectedforReport, currentQuestions, currentReportType }) {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPersonnel, setSelectedPersonnel] = useState({id: 0, full_name: '', level: 999, group_id: 'zzz', work_contract: ''});
    const [lowestHierarchy, setLowestHierarchy] = useState(false);
    const [tools, setTools] = useState([]);
    const [expDate, setExpDate] = useState();

    useEffect(() => {
        setActiveMenu(4);
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    useEffect(() => {
        let today = new Date(Date.now());
        setExpDate(today.toLocaleDateString("default", { day: 'numeric', month: 'long', year: 'numeric' }));
    },[]);

    useEffect(() => {
        const fetchTool = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/recommended-tool/round/${selectedforReport[0]?.round_id}/team`);
                setTools(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        
        fetchTool();
    },[selectedforReport])


    useEffect(() => {
        let selected = selectedforReport.find((personnel) => personnel.selected && personnel.has_child);
        let lowest = true;
        selectedforReport.filter((item) => String(item.group_id).startsWith(selected.group_id) && item.level >= selected.level + 1).forEach((item) => {
            if(item.has_child) { 
                lowest = false;
            }
        });
        setLowestHierarchy(lowest);
        setSelectedPersonnel(selected);
        setIsLoading(false);
    },[selectedforReport]) 

    const overallAverageAnswer = (selected, supervisor, prev = false) => {
        let total = [];
        let count = 0;
        let answer = 0;
        selected.forEach((item) => {
            for(let i = 0; i<16; i++) {
                if (prev) {
                    if (supervisor) 
                        answer = isNaN(Number(String(item.prev_supervisor_answer).substring(i,i+1))) ? 0 : Number(String(item.prev_supervisor_answer).substring(i,i+1))
                    else
                        answer = isNaN(Number(String(item.prev_personnel_answer).substring(i,i+1))) ? 0 : Number(String(item.prev_personnel_answer).substring(i,i+1));
                } else {
                    if (supervisor) 
                        answer =  Number(String(item.supervisor_answer).substring(i,i+1))
                    else
                        answer = Number(String(item.personnel_answer).substring(i,i+1));
                }

                if (count === 0 ) 
                    total.push(answer)
                else
                    total[i] = total[i] + answer;
            }
            count++;
        });
        for(let i = 0; i < total.length; i++) {
            total[i] = Math.round(total[i] / count);
        }
        return total.join('');
    }

    const trendByCategory = (selected, category, supervisor = false) => {
        let total = 0;
        let count = 0;
        selected.forEach((item) => {
            count++;
            for(let i=0; i < 4; i++) {
                if (supervisor) {
                    total+= Number(String(item.supervisor_answer).substring((category * 4)+i, (category * 4) +i+1));
                } else {
                    total+= Number(String(item.personnel_answer).substring((category * 4)+i, (category * 4) +i+1));
                }
            }
        });
        let trend = Math.round(((total / (count * 4)) + Number.EPSILON) * 10) / 10;
        return isNaN(trend) ? 0 : trend;
    }

    const ratingPerCategoryAvg = (selected, category) => {
        let count = 0;
        let rating = 0;
        selected.forEach((item) => {
            count++;
            for(let i=0; i < 4; i++) {
                rating += Number(String(item.personnel_answer).substring((category * 4) + i,(category * 4) + i+1));
            }
        });
        let avg = Math.round(((rating / (4 * count)) + Number.EPSILON) * 10) / 10;
        avg = isNaN(avg) ? 0 : avg;
        let result = '';
        if (avg === 0) result = <div className="h-[30px] w-[30px]" />;
        if (avg > 0) result = <TrendArrow trend={2} width="30px" height="30px" />;
        if (avg > 2.4) result = <TrendArrow trend={3} width="30px" height="30px" />;
        if (avg > 3.4) result = <TrendArrow trend={5} width="30px" height="30px" />;
        return result;
    }

    const ratingPerAnswerGroupAvg = (selected, category, group) => {
        let count = 0;
        let rating = 0;
        selected.forEach((item) => {
            count++;
            for(let i=0; i < 4; i++) {
                let a = Number(String(item.personnel_answer).substring((category * 4) + i,(category * 4) + i+1));
                switch(group) {
                    case 0:
                        if( a > 3) rating++;
                        break;
                    case 1:
                        if( a === 3) rating++;
                        break;
                    case 2:
                        if( a < 3) rating++;
                        break;
                    default:
                }
            }
        });
        let percentage = Math.round((((rating / (4 * count)) * 100) + Number.EPSILON) * 10) / 10;
        return isNaN(percentage) ? 0 : percentage;
    }

    const ratingPerAnswerGroupAvgAll = (selected, group, prev = false) => {
        let count = 0;
        let rating = 0;
        selected.forEach((item) => {
            count++;
            for(let i=0; i < 16; i++) {
                let a = Number(String(item.personnel_answer).substring(i,i+1));
                if (prev) a = Number(String(item.prev_personnel_answer).substring(i,i+1));
                switch(group) {
                    case 0:
                        if( a > 3) rating++;
                        break;
                    case 1:
                        if( a === 3) rating++;
                        break;
                    case 2:
                        if( a < 3) rating++;
                        break;
                    default:
                }
            }
        });
        let percentage = Math.round((((rating / (16 * count)) * 100) + Number.EPSILON) * 10) / 10;
        return isNaN(percentage) ? 0 : percentage;
    }

    const ratingUrgentCallperQuestionAvg = (selected, category, no) => {
        let count = 0;
        let urgent = 0;
        selected.forEach((item) => {
            count++;
            let a = Number(String(item.personnel_answer).substring((category * 4) + no,(category * 4) + no+1));
            if (a < 3) urgent++;
        });
        let rating = Math.round(((urgent / count) + Number.EPSILON) * 10) / 10;
        rating = isNaN(rating) ? 0 : rating;
        let result = '';
        if (rating === 0) result = <div className="h-[30px] w-[30px]" />;
        if (rating > 0) result = <TrendArrow trend={5} width="30px" height="30px" />;
        if (rating > 0.2) result = <TrendArrow trend={3} width="30px" height="30px" />;
        if (rating > 0.6) result = <TrendArrow trend={2} width="30px" height="30px" />;
        return result;
    }

    const countUrgentCallperQuestion = (selected, category, no) => {
        let count = 0;
        selected.forEach((item) => {
            let a = Number(String(item.personnel_answer).substring((category * 4) + no,(category * 4) + no+1));
            if (a < 3) count++;
        });
        return count;
    }

    const percentageDeviation = ( item ) => {
        let deviation = 0
        for(let i=0; i < 16; i++) {
            let a = Number(String(item.personnel_answer).substring(i,i+1));
            let b = Number(String(item.supervisor_answer).substring(i,i+1));
            if( Math.abs(a-b) > 1) deviation++;
        }
        let percentage = Math.round((((deviation / 16) * 100) + Number.EPSILON) * 10) / 10;
        return isNaN(percentage) ? 0 : percentage;
    }

    const percentageUrgentcall = ( item ) => {
        let urgencall = 0
        for(let i=0; i < 16; i++) {
            let a = Number(String(item.personnel_answer).substring(i,i+1));
            if( a < 3) urgencall++;
        }
        let percentage = Math.round((((urgencall / 16) * 100) + Number.EPSILON) * 10) / 10;
        return isNaN(percentage) ? 0 : percentage;        
    }

    const handlePDFDocument = () => {
        setIsLoading(true);
        let today = new Date(Date.now());
        setExpDate(today.toLocaleDateString("default", { day: 'numeric', month: 'long', year: 'numeric' }));
//        let sectionIDs = ["cover-0-1", "cover-0-2", "cover-0-3", "page-1-1"];
        let sectionIDs = ["cover-0-1", "cover-0-2", "cover-0-3", "page-1-1", "page-1-2", "page-1-3", "page-2-1", "page-2-2", "page-2-3", "page-3-1", "page-3-2", "page-3-3", "page-3-4", "page-4-1", "page-4-2", "page-4-3", "page-4-4", "page-5-1", "page-5-2", "page-5-3","page-5-4a","page-5-4b","page-5-4c","cover-1-1"];
        let activePages = [];
        let saveFileName="HR Booklet.pdf";

        sectionIDs.forEach(id => {
            let element = document.getElementById(id); 
            if (element !== null) activePages.push({id: id, element: element, width: element.offsetWidth, height: element.offsetHeight});
        });

        setTimeout(function () {
            let pdf = null;
            const jpgs = activePages.map(page => toJpeg(page.element, {width: 1745, height: 1237}));
            Promise
                .all(jpgs)
                .then(jpgs => {
                    jpgs.forEach((jpg, index) => {
                        if (pdf === null) {
                            pdf = new jsPDF({
                                orientation: "landscape",
                                format: "a4",
                                unit: "mm",  //297x210
                                compress: true,
                            });
                        } else {
                            pdf.addPage("a4","landscape");
                        }
//                        String(activePages[index].id).substring(0, 5) === 'cover' ? 
//                            pdf.addImage(jpg, "JPG", 0, 0, 297, 210) :
//                            pdf.addImage(jpg, "JPG", 0, 0, 297, Math.round(297 * (activePages[index].height / activePages[index].width)));
                        pdf.addImage(jpg, "JPG", 0, 0, 297, 210);
                    });
                    pdf.save(`${saveFileName}`);
                    setIsLoading(false);
                });
        }, 17);
    }

    const handlePPTXDocument = () => {
        setIsLoading(true);
        let today = new Date(Date.now());
        setExpDate(today.toLocaleDateString("default", { day: 'numeric', month: 'long', year: 'numeric' }));
//        let sectionIDs = ["cover-0-1", "cover-0-2", "cover-0-3", "page-1-1"];
        let sectionIDs = ["cover-0-1", "cover-0-2", "cover-0-3", "page-1-1", "page-1-2", "page-1-3", "page-2-1", "page-2-2", "page-2-3", "page-3-1", "page-3-2", "page-3-3", "page-3-4", "page-4-1", "page-4-2", "page-4-3", "page-4-4", "page-5-1", "page-5-2", "page-5-3","page-5-4a","page-5-4b","page-5-4c","cover-1-1"];
        let activePages = [];
        let saveFileName="HR Booklet.pptx";

        sectionIDs.forEach(id => {
            let element = document.getElementById(id); 
            if (element !== null) activePages.push({id: id, element: element, width: element.offsetWidth, height: element.offsetHeight});
        });

        setTimeout(function () {
            let pptx = null;
            let slide = null;
            const jpgs = activePages.map(page => toJpeg(page.element, {width: 1745, height: 1237}));
            Promise
                .all(jpgs)
                .then(jpgs => {
                    jpgs.forEach((jpg, index) => {
                        if (pptx === null) {
                            pptx = new pptxgen();
                            pptx.defineLayout({ name:"A4", width:11.69, height:8.27 });
                            pptx.layout = "A4";
                            pptx.title = "HR Booklet";
                            pptx.subject = "HR Booklet";
                            pptx.author = "REMS";
                            pptx.company = "DRÄXLMAIER";
                            slide = pptx.addSlide();
                        } else {
                            slide = pptx.addSlide();
                        }
//                        String(activePages[index].id).substring(0, 5) === 'cover' ? 
//                        index < 4 ? 
//                            slide.addImage({ data: jpg, x: 0, y: 0, w: 11.69, h: 8.27 }) :
//                            slide.addImage({ data: jpg, x: 0, y: 0, w: 11.69, h: Math.round(11.69 * (activePages[index].height / activePages[index].width)) });
                        slide.addImage({ data: jpg, x: 0, y: 0, w: 11.69, h: 8.27 });
                    });
                    pptx.writeFile({ fileName: saveFileName });
                    setIsLoading(false);
                });
        }, 17);
        
    }

    return (
        <div className="w-11/12 py-3">
            <div className="flex flex-row">
                <h1 className="text-2xl font-medium text-primary-black">Management Summary</h1>
            </div>
            <div className="flex flex-row text-primary-black py-3">
                <button className="bg-primary-green text-primary-white text-lg font-medium px-10 py-1 rounded-lg mr-3" onClick={handlePDFDocument}>Download PDF</button>
                <button className="bg-primary-green text-primary-white text-lg font-medium px-10 py-1 rounded-lg" onClick={handlePPTXDocument}>Download PPTX</button>
            </div>

            <div className="w-full flex flex-col items-center">
                <div className="w-11/12">
                    
                    <Fragment>{/* cover-00 */}
                        <div className="py-7">
                            <section id="cover-00" className="bg-white">
                                <div className="w-full pt-[56.25%] bg-cover0 bg-no-repeat bg-left-top bg-cover flex flex-row relative border-2">
                                    <div className="absolute top-0 left-0 bottom-0 right-0">
                                        <div className="flex flex-col">
                                            <div className="w-full pt-[18%] flex flex-row">
                                                <div className="w-1/12"></div>
                                                <div className="w-5/12">
                                                    <svg viewBox="0 0 320 222" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <text x="-4" y="54" fill="white" textAnchor="start" style={{font: '72px Helvetica'}}>RE</text>
                                                        <text x="104" y="54" fill="#0097ac" textAnchor="start" style={{font: '72px Helvetica'}}>MS</text>
                                                        <text x="-2" y="150" fill="white" textAnchor="start" fontWeight="bold" fontSize="36px" fontFamily={"Helvetica Neue LT Pro"}>{currentReportType}</text>
                                                        <text x="-2" y="190" fill="white" textAnchor="start" fontWeight="normal" fontSize="24px" fontFamily={"Helvetica Neue LT Pro"}>{selectedPersonnel?.full_name}</text>
                                                        <text x="-2" y="218" fill="white" textAnchor="start" fontWeight="normal" fontSize="24px" fontFamily={"Helvetica Neue LT Pro"}>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1)[0]?.round_name}</text>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Fragment>

                    <Fragment>{/* cover-01 */}
                        <div className="py-7">
                            <section id="cover-01" className="bg-white">
                                <div className="w-full pt-[56.25%] bg-cover1 bg-no-repeat bg-left-top bg-cover flex flex-row relative border-2">
                                    <div className="absolute top-0 left-0 bottom-0 right-0">
                                        <div className="flex flex-row">
                                            <div className="w-[2%]"></div>
                                            <div className="w-full flex flex-col">
                                                <div className="pt-[2%] w-full" />
                                                <div className="w-full flex flex-row">
                                                    <div className="mr-auto" />
                                                    <div className="w-[12%]">
                                                        <svg viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                            <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                            <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                            <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                            <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                            <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                            <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                            <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                            <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                            <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                            <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="pt-[25%] px-[5%]">
                                                    <svg viewBox="0 0 450 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <text x="-2" y="28" fill="white" textAnchor="start" fontWeight="bold" fontSize="36px" fontFamily={"Helvetica Neue LT Pro"}>{currentReportType}</text>
                                                    </svg>
                                                    <div className="w-full"><hr className="border-primary-green border-2" /></div>
                                                    <svg viewBox="0 0 450 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <text x="0" y="28" fill="white" textAnchor="start" fontWeight="normal" fontSize="24px" fontFamily={"Helvetica Neue LT Pro"}>CONFIDENTIAL</text>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="w-[2%]"></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Fragment>

                    <Fragment>{/* cover-02 */}
                        <div className="py-7">
                            <section id="cover-02" className="bg-white">
                                <div className="w-full pt-[56.25%] bg-cover1 bg-no-repeat bg-left-top bg-cover flex flex-row relative border-2">
                                    <div className="absolute top-0 left-0 bottom-0 right-0">
                                        <div className="flex flex-row">
                                            <div className="w-[2%]"></div>
                                            <div className="w-full flex flex-col">
                                                <div className="pt-[2%] w-full" />
                                                <div className="w-full flex flex-row">
                                                    <div className="mr-auto" />
                                                    <div className="w-[12%]">
                                                        <svg viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                            <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                            <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                            <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                            <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                            <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                            <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                            <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                            <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                            <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                            <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="pt-[25%] px-[5%]">
                                                    <svg viewBox="0 0 450 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <text x="-2" y="28" fill="white" textAnchor="start" fontWeight="bold" fontSize="36px" fontFamily={"Helvetica Neue LT Pro"}>Team Profile</text>
                                                    </svg>
                                                    <div className="w-full"><hr className="border-primary-green border-2" /></div>
                                                    <svg viewBox="0 0 450 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <text x="0" y="28" fill="white" textAnchor="start" fontWeight="normal" fontSize="24px" fontFamily={"Helvetica Neue LT Pro"}>CONFIDENTIAL</text>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="w-[2%]"></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Fragment>

                    <Fragment>{/* page template */}
                        <div className="py-7 hidden">
                            <section id="page-template" className="bg-white">
                                <div className="w-full pt-[56.25%] flex flex-row relative border-2">
                                    <div className="absolute top-0 left-0 bottom-0 right-0 flex">
                                        <div className="flex flex-row grow">
                                            <div className="w-[2%]"></div>
                                            <div className="w-full flex flex-col">
                                                <div className="w-full flex flex-row">
                                                    <div className="w-[88%]">
                                                        <div className="w-[12%]">
                                                            <svg viewBox="0 0 193 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="0" y="0" width="193" height="36" fill="#000000" stroke="#000000" />
                                                                <text x="10" y="28" fill="white" textAnchor="start" fontWeight="normal" fontSize="24px" fontFamily={"Helvetica Neue LT Pro"}>CONFIDENTIAL</text>
                                                            </svg>
                                                        </div>
                                                        <div className="w-[50%]">
                                                            <svg viewBox="0 0 500 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <text x="-2" y="42" fill="#0097ac" textAnchor="start" fontWeight="bold" fontSize="36px" fontFamily={"Helvetica Neue LT Pro"}>REMS Team Profile</text>
                                                                <text x="0" y="66" fill="black" textAnchor="start" fontWeight="500" fontSize="22px" fontFamily={"Helvetica Neue LT Pro"}>Supervisor: {selectedPersonnel?.full_name}</text>
                                                            </svg>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="w-[12%] pt-[2%]">
                                                        <svg viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                            <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                            <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                            <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                            <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                            <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                            <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                            <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                            <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                            <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                            <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="w-full">
                                                    <svg viewBox="0 0 1137 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0" y="0" width="1137" height="36" fill="#2a6473" stroke="#2a6473" />
                                                        <text x="12" y="26" fill="white" textAnchor="start" fontWeight="500" fontSize="22px" fontFamily={"Helvetica Neue LT Pro"}>Overview - Whole Hierarchy</text>
                                                    </svg>
                                                </div>
                                                <div className="w-full grow flex items-center">
                                                    <div className="w-full">
                                                    {/* you content here - start */}
                                                    {/* you content here - end */}
                                                    </div>
                                                </div>
                                                <div className="w-full h-[3.6%]">
                                                    <hr className="border-primary-dark-green" />
                                                    <svg viewBox="0 0 1600 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <text x="0" y="19" fill="black" textAnchor="start" fontWeight="normal" fontSize="18px" fontFamily={"Helvetica Neue LT Pro"}>{expDate}</text>
                                                        <text x="1600" y="19" fill="black" textAnchor="end" fontWeight="normal" fontSize="18px" fontFamily={"Helvetica Neue LT Pro"}>Internal: All rights reserved. Distribution within the company, customer and partners.</text>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="w-[2%]"></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Fragment>

                    { !lowestHierarchy && <Fragment>
                        <Fragment>{/* page-01-01 */}
                            <div className="py-7">
                                <section id="page-01-01" className="bg-white">
                                    <div className="w-full pt-[56.25%] flex flex-row relative border-2">
                                        <div className="absolute top-0 left-0 bottom-0 right-0 flex">
                                            <div className="flex flex-row grow">
                                                <div className="w-[2%]"></div>
                                                <div className="w-full flex flex-col">
                                                    <div className="w-full flex flex-row">
                                                        <div className="w-[88%]">
                                                            <div className="w-[12%]">
                                                                <svg viewBox="0 0 193 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect x="0" y="0" width="193" height="36" fill="#000000" stroke="#000000" />
                                                                    <text x="10" y="28" fill="white" textAnchor="start" fontWeight="normal" fontSize="24px" fontFamily={"Helvetica Neue LT Pro"}>CONFIDENTIAL</text>
                                                                </svg>
                                                            </div>
                                                            <div className="w-[50%]">
                                                                <svg viewBox="0 0 500 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <text x="-2" y="42" fill="#0097ac" textAnchor="start" fontWeight="bold" fontSize="36px" fontFamily={"Helvetica Neue LT Pro"}>REMS Team Profile</text>
                                                                    <text x="0" y="66" fill="black" textAnchor="start" fontWeight="500" fontSize="22px" fontFamily={"Helvetica Neue LT Pro"}>Supervisor: {selectedPersonnel?.full_name}</text>
                                                                </svg>
                                                            </div>                                                        
                                                        </div>
                                                        <div className="w-[12%] pt-[2%]">
                                                            <svg viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="w-full">
                                                        <svg viewBox="0 0 1137 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="0" y="0" width="1137" height="36" fill="#2a6473" stroke="#2a6473" />
                                                            <text x="12" y="26" fill="white" textAnchor="start" fontWeight="500" fontSize="22px" fontFamily={"Helvetica Neue LT Pro"}>Overview - Whole Hierarchy</text>
                                                        </svg>
                                                    </div>
                                                    <div className="w-full grow flex items-center">
                                                        <div className="w-full">
                                                        {/* you content here - start */}
                                                            <div className="w-full flex flex-row">
                                                                <div className="w-[6.5%]"></div>
                                                                <div className="w-[25%] bg-primary-dark-green text-primary-white px-4 py-1 border-primary-dark-green border-2">
                                                                    <div>Employee Engagement level: {ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0)}%</div>
                                                                    <div>Distribution of ratings across all categories</div>
                                                                </div>
                                                                <div className="w-[0.5%]"></div>
                                                                <div className="w-[20%] bg-primary-dark-green text-primary-white px-4 py-1 border-primary-dark-green border-2 text-center">
                                                                    <div>Trend</div>
                                                                    <div>All Employees</div>
                                                                </div>
                                                                <div className="w-[0.5%]"></div>
                                                                <div className="w-[20%] bg-primary-dark-green text-primary-white px-4 py-1 border-primary-dark-green border-2 text-center">
                                                                    <div>Trend</div>
                                                                    <div>Non-key Employees</div>
                                                                </div>
                                                                <div className="w-[0.5%]"></div>
                                                                <div className="w-[20%] bg-primary-dark-green text-primary-white px-4 py-1 border-primary-dark-green border-2 text-center">
                                                                    <div>Trend</div>
                                                                    <div>Key Employees</div>
                                                                </div>
                                                                <div className="w-[6%]"></div>
                                                            </div>
                                                            <div className="w-full flex flex-row">
                                                                <div className="w-[6.5%] flex flex-col items-center justify-center">
                                                                    <div className="transform origin-center rotate-[-90deg] whitespace-nowrap font-semibold">
                                                                        <div>Current round:</div>
                                                                        <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.round_name}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-[25%] border-primary-dark-green border-2">
                                                                    <RatingAllCategoryChart width="70%" 
                                                                        rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0)} 
                                                                        rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1)}
                                                                        rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2)} />
                                                                </div>
                                                                <div className="w-[0.5%]"></div>
                                                                <div className="w-[20%] border-primary-dark-green border-2">
                                                                    <TrendChart width="85%" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false)}/>
                                                                </div>
                                                                <div className="w-[0.5%]"></div>
                                                                <div className="w-[20%] border-primary-dark-green border-2">
                                                                    <TrendChart width="85%" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1  && item.key_status === 'Non-key'), false)}/>
                                                                </div>
                                                                <div className="w-[0.5%]"></div>
                                                                <div className="w-[20%] border-primary-dark-green border-2">
                                                                    <TrendChart width="85%" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1  && item.key_status === 'Key'), false)}/>
                                                                </div>
                                                                <div className="w-[6%]"></div>
                                                            </div>
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                                                <div className="w-full flex flex-row">
                                                                    <div className="w-[6.5%] flex flex-col items-center justify-center">
                                                                        <div className="transform origin-center rotate-[-90deg] whitespace-nowrap font-semibold">
                                                                            <div>Previous round:</div>
                                                                            <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-[25%] border-primary-dark-green border-2 border-t-0">
                                                                        <RatingAllCategoryChart width="70%"
                                                                            rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, true)} 
                                                                            rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, true)}
                                                                            rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, true)} />
                                                                    </div>
                                                                    <div className="w-[0.5%]"></div>
                                                                    <div className="w-[20%] border-primary-dark-green border-2 border-t-0">
                                                                        <TrendChart width="85%" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false, true)}/>                                                            </div>
                                                                    <div className="w-[0.5%]"></div>
                                                                    <div className="w-[20%] border-primary-dark-green border-2 border-t-0">
                                                                        <TrendChart width="85%" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1  && item.key_status === 'Non-key'), false, true)}/>
                                                                    </div>
                                                                    <div className="w-[0.5%]"></div>
                                                                    <div className="w-[20%] border-primary-dark-green border-2 border-t-0">
                                                                        <TrendChart width="85%" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1  && item.key_status === 'Key'), false, true)}/>
                                                                    </div>
                                                                    <div className="w-[6%]"></div>
                                                                </div>
                                                            }
                                                            <div className="w-full flex flex-row pt-[0.5%]">
                                                                <div className="w-[6.5%]"></div>
                                                                <div className="w-[25%] border-primary-dark-green border-2">
                                                                    <div className="py-[2%] flex flex-row text-xs items-center justify-center">
                                                                        <div className="w-[13%] pr-2">
                                                                            <div className="bg-[#d7ecd0] text-center">4 - 5</div>
                                                                            <div className="bg-[#fcf6bc] text-center">3</div>                        
                                                                            <div className="bg-[#f7b59b] text-center">1 - 2</div>
                                                                        </div>
                                                                        <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                                        </svg>
                                                                        <div className="pl-1 pr-2">EE &ge; 70%</div>
                                                                        <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g transform="rotate(270 100 100)">
                                                                                <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                                            </g>
                                                                        </svg>
                                                                        <div className="pl-1 pr-2">EE &ge; 50%</div>
                                                                        <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g transform="rotate(180 100 100)">
                                                                                <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                                            </g>
                                                                        </svg>
                                                                        <div className="pl-1 pr-2">EE &lt; 50%</div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-[0.5%]"></div>
                                                                <div className="w-[61%] border-primary-dark-green border-2 flex flex-row items-center justify-center">
                                                                    <TrendArrow trend={2} height="40px" width="40px" />
                                                                    <div className="pl-1 pr-8">Average &lt; 2.5</div>
                                                                    <TrendArrow trend={3} height="40px" width="40px" />
                                                                    <div className="pl-1 pr-8">2.5 &le; Average &lt; 3.5</div>
                                                                    <TrendArrow trend={5} height="40px" width="40px" />
                                                                    <div className="pl-1 pr-8">Average &ge; 3.5</div>
                                                                </div>
                                                                <div className="w-[6%]"></div>
                                                            </div>
                                                            {/* you content here - end */}
                                                        </div>
                                                    </div>
                                                    <div className="w-full h-[3.6%]">
                                                        <hr className="border-primary-dark-green" />
                                                        <svg viewBox="0 0 1600 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <text x="0" y="19" fill="black" textAnchor="start" fontWeight="normal" fontSize="18px" fontFamily={"Helvetica Neue LT Pro"}>{expDate}</text>
                                                            <text x="1600" y="19" fill="black" textAnchor="end" fontWeight="normal" fontSize="18px" fontFamily={"Helvetica Neue LT Pro"}>Internal: All rights reserved. Distribution within the company, customer and partners.</text>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="w-[2%]"></div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Fragment>

                        <Fragment>{/* page-01-02 */}
                        <div className="py-7">
                            <section id="page-01-02" className="bg-white">
                                <div className="w-full pt-[56.25%] flex flex-row relative border-2">
                                    <div className="absolute top-0 left-0 bottom-0 right-0 flex">
                                        <div className="flex flex-row grow">
                                            <div className="w-[2%]"></div>
                                            <div className="w-full flex flex-col">
                                                <div className="w-full flex flex-row">
                                                    <div className="w-[88%]">
                                                        <div className="w-[12%]">
                                                            <svg viewBox="0 0 193 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="0" y="0" width="193" height="36" fill="#000000" stroke="#000000" />
                                                                <text x="10" y="28" fill="white" textAnchor="start" fontWeight="normal" fontSize="24px" fontFamily={"Helvetica Neue LT Pro"}>CONFIDENTIAL</text>
                                                            </svg>
                                                        </div>
                                                        <div className="w-[50%]">
                                                            <svg viewBox="0 0 500 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <text x="-2" y="42" fill="#0097ac" textAnchor="start" fontWeight="bold" fontSize="36px" fontFamily={"Helvetica Neue LT Pro"}>REMS Team Profile</text>
                                                                <text x="0" y="66" fill="black" textAnchor="start" fontWeight="500" fontSize="22px" fontFamily={"Helvetica Neue LT Pro"}>Supervisor: {selectedPersonnel?.full_name}</text>
                                                            </svg>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="w-[12%] pt-[2%]">
                                                        <svg viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                            <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                            <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                            <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                            <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                            <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                            <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                            <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                            <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                            <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                            <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="w-full">
                                                    <svg viewBox="0 0 1137 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0" y="0" width="1137" height="36" fill="#2a6473" stroke="#2a6473" />
                                                        <text x="12" y="26" fill="white" textAnchor="start" fontWeight="500" fontSize="22px" fontFamily={"Helvetica Neue LT Pro"}>REMS Profile - Whole Hierarchy</text>
                                                    </svg>
                                                </div>
                                                <div className="w-full grow flex items-center">
                                                    <div className="w-full">
                                                    {/* you content here - start */}
                                                        <div className="w-5/12 ">
                                                            <SpiderChart width="85%" supervisor_answer="" personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false)} />
                                                        </div>

                                                    {/* you content here - end */}
                                                    </div>
                                                </div>
                                                <div className="w-full h-[3.6%]">
                                                    <hr className="border-primary-dark-green" />
                                                    <svg viewBox="0 0 1600 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <text x="0" y="19" fill="black" textAnchor="start" fontWeight="normal" fontSize="18px" fontFamily={"Helvetica Neue LT Pro"}>{expDate}</text>
                                                        <text x="1600" y="19" fill="black" textAnchor="end" fontWeight="normal" fontSize="18px" fontFamily={"Helvetica Neue LT Pro"}>Internal: All rights reserved. Distribution within the company, customer and partners.</text>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="w-[2%]"></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        </Fragment>

                    </Fragment>}
                </div>
            </div>



            { !lowestHierarchy && 
                <Fragment>
                    <div className="py-7">
                        <section id="page-1-2" className="bg-white">
                            <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                                <div className="absolute top-0 left-0 w-full h-full">
                                    <div className="px-8">
                                        <div className="flex flex-row">
                                            <div className="mr-auto">
                                                <div className="mt-[6px]">
                                                    <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                                </div>
                                                <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                    REMS Team Profile
                                                </div>
                                                <div className="text-black text-3xl font-medium">
                                                    Supervisor: {selectedPersonnel?.full_name} 
                                                </div>
                                            </div>
                                            <div className="w-[192px] pt-8">
                                                <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                    <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                    <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                    <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                    <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                    <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                    <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                    <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                    <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                    <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                    <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                                            REMS Profile - Whole Hierarchy
                                        </div>
                                        <div className="w-full flex py-7">
                                            <div className="w-5/12">
                                                <SpiderChart width="640px" supervisor_answer="" personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false)} />
                                            </div>
                                            <div className="w-7/12 pt-9">
                                                <table className="w-full">
                                                    <tbody>
                                                        <tr className="text-lg text-primary-white">
                                                            <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Category</th>
                                                            <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">Trend</th>
                                                            <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left">Urgent Calls</th>
                                                            <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Proposed Tools</th>
                                                        </tr>
                                                        <tr className="text-lg text-primary-white">
                                                            <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">
                                                                Employee
                                                            </th>
                                                            <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4 text-sm">
                                                                *Also, in previous round
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">JOB</td>
                                                            <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                                                <div className="flex items-center justify-center">
                                                                    <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0)} height="60px" width="60px" />
                                                                </div>
                                                            </td>
                                                            <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                                { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                                    <Fragment key={index}>
                                                                        { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(index, index + 1)) < 3 &&
                                                                            <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false, true).substring(index, index + 1)) < 3 && <span>*</span>}</div>
                                                                        }
                                                                    </Fragment>
                                                                ))}
                                                            </td>
                                                            <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                                { tools.filter(tool => String(tool.question_no).substring(0,1) === "J").map((tool) => {
                                                                    let result = null;
                                                                    Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(Number(String(tool.question_no).substring(1)) - 1, Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                    return result;
                                                                }).filter((item, pos, self) => {
                                                                    return self.indexOf(item) === pos;
                                                                }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">LEADER</td>
                                                            <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                                                <div className="flex items-center justify-center">
                                                                    <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1)} height="60px" width="60px" />
                                                                </div>
                                                            </td>
                                                            <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                                { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                                    <Fragment key={index}>
                                                                        { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(4 + index, 4 + index + 1)) < 3 &&
                                                                            <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false, true).substring(4 + index, 4 + index + 1)) < 3 && <span>*</span>}</div>
                                                                        }
                                                                    </Fragment>
                                                                ))}
                                                            </td>
                                                            <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                                { tools.filter(tool => String(tool.question_no).substring(0,1) === "L").map((tool) => {
                                                                    let result = null;
                                                                    Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(4 + Number(String(tool.question_no).substring(1)) - 1, 4 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                    return result;
                                                                }).filter((item, pos, self) => {
                                                                    return self.indexOf(item) === pos;
                                                                }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">REWARDS</td>
                                                            <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                                                <div className="flex items-center justify-center">
                                                                    <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2)} height="60px" width="60px" />
                                                                </div>
                                                            </td>
                                                            <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                                { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                                    <Fragment key={index}>
                                                                        { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(8 + index, 8 + index + 1)) < 3 &&
                                                                            <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false, true).substring(8 + index, 8 + index + 1)) < 3 && <span>*</span>}</div>
                                                                        }
                                                                    </Fragment>
                                                                ))}
                                                            </td>
                                                            <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                                { tools.filter(tool => String(tool.question_no).substring(0,1) === "R").map((tool) => {
                                                                    let result = null;
                                                                    Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(8 + Number(String(tool.question_no).substring(1)) - 1, 8 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                    return result;
                                                                }).filter((item, pos, self) => {
                                                                    return self.indexOf(item) === pos;
                                                                }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-xl px-6 border-2 border-primary-dark-green">COMPANY</td>
                                                            <td className="py-4 border-2 border-primary-dark-green">
                                                                <div className="flex items-center justify-center">
                                                                    <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3)} height="60px" width="60px" />
                                                                </div>
                                                            </td>
                                                            <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                                                { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                                    <Fragment key={index}>
                                                                        { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(12 + index, 12 + index + 1)) < 3 &&
                                                                            <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false, true).substring(12 + index, 12 + index + 1)) < 3 && <span>*</span>}</div>
                                                                        }
                                                                    </Fragment>
                                                                ))}
                                                            </td>
                                                            <td className="py-4 border-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                                { tools.filter(tool => String(tool.question_no).substring(0,1) === "C").map((tool) => {
                                                                    let result = null;
                                                                    Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(12 + Number(String(tool.question_no).substring(1)) - 1, 12 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                    return result;
                                                                }).filter((item, pos, self) => {
                                                                    return self.indexOf(item) === pos;
                                                                }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                        <hr className="border-primary-dark-green" />
                                        <table className="w-full">
                                            <tbody>
                                                <tr>
                                                    <td className="px-2 text-xl text-left">
                                                        {expDate}
                                                    </td>
                                                    <td className="px-2 text-xl text-right">
                                                        Internal: All rights reserved. Distribution within the company, customer and partners.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="py-7">
                        <section id="page-1-3" className="bg-white">
                            <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                                <div className="absolute top-0 left-0 w-full h-full">
                                    <div className="px-8">
                                        <div className="flex flex-row">
                                            <div className="mr-auto">
                                                <div className="mt-[6px]">
                                                    <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                                </div>
                                                <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                    REMS Team Profile
                                                </div>
                                                <div className="text-black text-3xl font-medium">
                                                    Supervisor: {selectedPersonnel?.full_name} 
                                                </div>
                                            </div>
                                            <div className="w-[192px] pt-8">
                                                <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                    <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                    <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                    <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                    <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                    <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                    <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                    <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                    <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                    <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                    <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">HR Comment - Whole Hierarchy</div>
                                        <div className="mt-3 mb-12">
                                            <div className="border-2 border-primary-dark-green">
                                                <textarea rows="24" className="p-1 w-full text-2xl" placeholder="Fluctuation rate:" disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                        <hr className="border-primary-dark-green" />
                                        <table className="w-full">
                                            <tbody>
                                                <tr>
                                                    <td className="px-2 text-xl text-left">
                                                        {expDate}
                                                    </td>
                                                    <td className="px-2 text-xl text-right">
                                                        Internal: All rights reserved. Distribution within the company, customer and partners.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Fragment>
            }

            <Fragment>
                <div className="py-7">
                    <section id="page-2-1" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                                        Overview - Direct Reports
                                    </div>
                                    <div className="w-full py-7 flex flex-row">
                                        <div>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="h-[74px]"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[304px] max-w-[80px]">
                                                            <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                                                <div>Current round:</div>
                                                                <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1)[0]?.round_name}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[304px] max-w-[80px]">
                                                            <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                                                <div>Previous round:</div>
                                                                <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1)[0]?.prev_round_name}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <table className="w-[360px]">
                                                <tbody>
                                                    <tr>
                                                        <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2">
                                                            <div>Employee Engagement level: {ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 0)}%</div>
                                                            <div>Distribution of ratings across all categories</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[300px] border-primary-dark-green border-2">
                                                            <RatingAllCategoryChart height="300px" width="300px"
                                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 0)} 
                                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 1)}
                                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 2)} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[300px] border-primary-dark-green border-2">
                                                            <RatingAllCategoryChart height="300px" width="300px"
                                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 0, true)} 
                                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 1, true)}
                                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 2, true)} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="mt-[5px] border-2 border-primary-dark-green w-[360px] mr-[5px]">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center text-xs">
                                                            <div className="bg-[#d7ecd0] px-2">4 - 5</div>
                                                            <div className="bg-[#fcf6bc] px-2">3</div>                        
                                                            <div className="bg-[#f7b59b] px-2">1 - 2</div>
                                                        </td>
                                                        <td className="h-[52px] flex flex-row text-xs items-center justify-center">
                                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                            </svg>
                                                            <div className="pl-1 pr-2">EE &ge; 70%</div>
                                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="rotate(270 100 100)">
                                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                                </g>
                                                            </svg>
                                                            <div className="pl-1 pr-2">EE &ge; 50%</div>
                                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="rotate(180 100 100)">
                                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                                </g>
                                                            </svg>
                                                            <div className="pl-1 pr-2">EE &lt; 50%</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="w-full">
                                            <div className="flex flex-row justify-between">
                                                <div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2 text-center">
                                                                    <div>Trend</div>
                                                                    <div>All Employees</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false)}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false, true)}/>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2 text-center">
                                                                    <div>Trend</div>
                                                                    <div>Non-key Employees</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key'), false)}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key'), false, true)}/>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center" colSpan="2">
                                                                    <div>Trend</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                                    <div>Key Employees</div>
                                                                </td>
                                                                <td className="bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                                    <div>Supervisor</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), false)}/>
                                                                </td>
                                                                <td className="h-[300px] border-primary-dark-green border-2 bg-[#bddce3]">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), true)}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), false, true)}/>
                                                                </td>
                                                                <td className="h-[300px] border-primary-dark-green border-2 bg-[#bddce3]">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), true, true)}/>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-center justify-center mt-[5px] border-2 border-primary-dark-green h-[56px]">
                                                <TrendArrow trend={2} height="40px" width="40px" />
                                                <div className="pl-1 pr-8">Average &lt; 2.5</div>
                                                <TrendArrow trend={3} height="40px" width="40px" />
                                                <div className="pl-1 pr-8">2.5 &le; Average &lt; 3.5</div>
                                                <TrendArrow trend={5} height="40px" width="40px" />
                                                <div className="pl-1 pr-8">Average &ge; 3.5</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="py-7">
                    <section id="page-2-2" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                                        REMS Profile - Direct Reports
                                    </div>
                                    <div className="w-full flex py-7">
                                        <div className="w-5/12">
                                            <SpiderChart width="640px" supervisor_answer="" personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false)} />
                                        </div>
                                        <div className="w-7/12 pt-9">
                                            <table className="w-full">
                                                <tbody>
                                                    <tr className="text-lg text-primary-white">
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Category</th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">Trend</th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left">Urgent Calls</th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Proposed Tools</th>
                                                    </tr>
                                                    <tr className="text-lg text-primary-white">
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">
                                                            Employee
                                                        </th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4 text-sm">
                                                            *Also, in previous round
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">JOB</td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 0)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(index, index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false, true).substring(index, index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "J").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(Number(String(tool.question_no).substring(1)) - 1, Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">LEADER</td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 1)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(4 + index, 4 + index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false, true).substring(4 + index, 4 + index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "L").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(4 + Number(String(tool.question_no).substring(1)) - 1, 4 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">REWARDS</td>
                                                        <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 2)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(8 + index, 8 + index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false, true).substring(8 + index, 8 + index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "R").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(8 + Number(String(tool.question_no).substring(1)) - 1, 8 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-2 border-primary-dark-green">COMPANY</td>
                                                        <td className="py-4 border-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 3)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(12 + index, 12 + index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false, true).substring(12 + index, 12 + index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "C").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(12 + Number(String(tool.question_no).substring(1)) - 1, 12 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="py-7">
                    <section id="page-2-3" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">HR Comment - Direct Reports</div>
                                    <div className="mt-3 mb-12">
                                        <div className="border-2 border-primary-dark-green">
                                            <textarea rows="24" className="p-1 w-full text-2xl" placeholder="Fluctuation rate:" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>

            <Fragment>
                <div className="py-7">
                    <section id="page-3-1" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                                        Overview - Direct Reports (Key)
                                    </div>
                                    <div className="w-full py-7 flex flex-row">
                                        <div className="pr-5">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="h-[74px]"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[304px]">
                                                            <div className="whitespace-nowrap font-semibold">
                                                                <div>Current round:</div>
                                                                <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key')[0]?.round_name}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[304px]">
                                                            <div className="whitespace-nowrap font-semibold">
                                                                <div>Previous round:</div>
                                                                <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key')[0]?.prev_round_name}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <table className="w-[360px]">
                                                <tbody>
                                                    <tr>
                                                        <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2">
                                                            <div>Employee Engagement level: {ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 0)}%</div>
                                                            <div>Distribution of ratings across all categories</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[300px] border-primary-dark-green border-2">
                                                            <RatingAllCategoryChart height="300px" width="300px"
                                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 0)} 
                                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 1)}
                                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 2)} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[300px] border-primary-dark-green border-2">
                                                            <RatingAllCategoryChart height="300px" width="300px"
                                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 0, true)} 
                                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 1, true)}
                                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 2, true)} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="mt-[5px] border-2 border-primary-dark-green w-[360px] mr-[5px]">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center text-xs">
                                                            <div className="bg-[#d7ecd0] px-2">4 - 5</div>
                                                            <div className="bg-[#fcf6bc] px-2">3</div>                        
                                                            <div className="bg-[#f7b59b] px-2">1 - 2</div>
                                                        </td>
                                                        <td className="h-[52px] flex flex-row text-xs items-center justify-center">
                                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                            </svg>
                                                            <div className="pl-1 pr-2">EE &ge; 70%</div>
                                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="rotate(270 100 100)">
                                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                                </g>
                                                            </svg>
                                                            <div className="pl-1 pr-2">EE &ge; 50%</div>
                                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="rotate(180 100 100)">
                                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                                </g>
                                                            </svg>
                                                            <div className="pl-1 pr-2">EE &lt; 50%</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <div className="flex flex-row justify-between">
                                                <div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center" colSpan="2">
                                                                    <div>Trend</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                                    <div>Key Employees</div>
                                                                </td>
                                                                <td className="bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                                    <div>Supervisor</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), false)}/>
                                                                </td>
                                                                <td className="h-[300px] border-primary-dark-green border-2 bg-[#bddce3]">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), true)}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), false, true)}/>
                                                                </td>
                                                                <td className="h-[300px] border-primary-dark-green border-2 bg-[#bddce3]">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), true, true)}/>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-center justify-center mt-[5px] border-2 border-primary-dark-green h-[56px]">
                                                <TrendArrow trend={2} height="40px" width="40px" />
                                                <div className="pl-1 pr-8">Average &lt; 2.5</div>
                                                <TrendArrow trend={3} height="40px" width="40px" />
                                                <div className="pl-1 pr-8">2.5 &le; Average &lt; 3.5</div>
                                                <TrendArrow trend={5} height="40px" width="40px" />
                                                <div className="pl-1 pr-8">Average &ge; 3.5</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
 
                <div className="py-7">
                    <section id="page-3-2" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">REMS Profile - Direct Reports (Key)</div>
                                    <div className="w-full flex py-7">
                                        <div className="w-5/12">
                                            <SpiderChart width="640px" supervisor_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true)} personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false)} />
                                        </div>
                                        <div className="w-7/12 pt-9">
                                            <table className="w-full">
                                                <tbody>
                                                    <tr className="text-lg text-primary-white">
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Category</th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green" colSpan="2">Trend</th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left">Urgent Calls</th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left">Deviations</th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Proposed Tools</th>
                                                    </tr>
                                                    <tr className="text-lg text-primary-white">
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">
                                                            Employee
                                                        </th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">
                                                            Supervisor
                                                        </th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4 text-sm">
                                                            *Also, in previous round
                                                        </th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4 text-sm">
                                                            *Also, in previous round
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">JOB</td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 0)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 0, true)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(index, index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(index, index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true).substring(index, index + 1)) - 
                                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(index, index + 1))) > 1 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true, true).substring(index, index + 1)) -
                                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(index, index + 1))) > 1 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "J").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(Number(String(tool.question_no).substring(1)) - 1, Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">LEADER</td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 1)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 1, true)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(4 + index, 4 + index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(4 + index, 4 + index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true).substring(4 + index, 4 + index + 1)) -
                                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(4 + index, 4 + index + 1))) > 1 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true, true).substring(4 + index, 4 + index + 1)) -
                                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(4 + index, 4 + index + 1))) > 1 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "L").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(4 + Number(String(tool.question_no).substring(1)) - 1, 4 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">REWARDS</td>
                                                        <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 2)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 2, true)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(8 + index, 8 + index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(8 + index, 8 + index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true).substring(8 + index, 8 + index + 1)) -
                                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(8 + index, 8 + index + 1))) > 1 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true, true).substring(8 + index, 8 + index + 1)) -
                                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(8 + index, 8 + index + 1))) > 1 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "R").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(8 + Number(String(tool.question_no).substring(1)) - 1, 8 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-2 border-primary-dark-green">COMPANY</td>
                                                        <td className="py-4 border-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 3)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 3, true)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(12 + index, 12 + index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(12 + index, 12 + index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true).substring(12 + index, 12 + index + 1)) -
                                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(12 + index, 12 + index + 1))) > 1 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true, true).substring(12 + index, 12 + index + 1)) -
                                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(12 + index, 12 + index + 1))) > 1 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "C").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(12 + Number(String(tool.question_no).substring(1)) - 1, 12 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
{/* remember to enable
                <div className="py-7">
                    <section id="page-3-3" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">Trend Chart - Direct Reports (Key)</div>
                                    <div className="w-full py-7 flex flex-row">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="h-[68px] w-[200px]"></td>
                                                </tr>
                                                <tr>
                                                    <td className="h-[268px] p-2 pl-6">
                                                        <div>Current round:</div>
                                                        <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key')[0]?.round_name}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="h-[268px] p-2 pl-6">
                                                        <div>Previous round:</div>
                                                        <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key')[0]?.prev_round_name}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key').map((emp, index) => ( 
                                            <table className="mr-2" key={index}>
                                                <tbody>
                                                    <tr>
                                                        <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center" colSpan="2">
                                                            <div>Trend</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                            <div>{emp.full_name}</div>
                                                        </td>
                                                        <td className="bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                            <div>{emp.name_of_manager}</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[300px] border-primary-dark-green border-2 p-2">
                                                            <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), false)}/>
                                                        </td>
                                                        <td className="h-[300px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                                            <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), true)}/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[300px] border-primary-dark-green border-2 p-2">
                                                            <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), false, true)}/>
                                                        </td>
                                                        <td className="h-[300px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                                            <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), true, true)}/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        ))}
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
*/}

                <div className="py-7">
                    <section id="page-3-4" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">HR Comment - Direct Reports (Key)</div>
                                    <div className="mt-3 mb-12">
                                        <div className="border-2 border-primary-dark-green">
                                            <textarea rows="24" className="p-1 w-full text-2xl" placeholder="Fluctuation rate:" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>

            <Fragment>
                <div className="py-7">
                    <section id="page-4-1" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                                        Overview - Direct Reports (Non-key)
                                    </div>
                                    <div className="w-full py-7 flex flex-row">
                                        <div className="pr-5">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="h-[74px]"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[304px]">
                                                            <div className="whitespace-nowrap font-semibold">
                                                                <div>Current round:</div>
                                                                <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key')[0]?.round_name}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[304px]">
                                                            <div className="whitespace-nowrap font-semibold">
                                                                <div>Previous round:</div>
                                                                <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key')[0]?.prev_round_name}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <table className="w-[360px]">
                                                <tbody>
                                                    <tr>
                                                        <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2">
                                                            <div>Employee Engagement level: {ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 0)}%</div>
                                                            <div>Distribution of ratings across all categories</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[300px] border-primary-dark-green border-2">
                                                            <RatingAllCategoryChart height="300px" width="300px"
                                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 0)} 
                                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 1)}
                                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 2)} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[300px] border-primary-dark-green border-2">
                                                            <RatingAllCategoryChart height="300px" width="300px"
                                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 0, true)} 
                                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 1, true)}
                                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 2, true)} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="mt-[5px] border-2 border-primary-dark-green w-[360px] mr-[5px]">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center text-xs">
                                                            <div className="bg-[#d7ecd0] px-2">4 - 5</div>
                                                            <div className="bg-[#fcf6bc] px-2">3</div>                        
                                                            <div className="bg-[#f7b59b] px-2">1 - 2</div>
                                                        </td>
                                                        <td className="h-[52px] flex flex-row text-xs items-center justify-center">
                                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                            </svg>
                                                            <div className="pl-1 pr-2">EE &ge; 70%</div>
                                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="rotate(270 100 100)">
                                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                                </g>
                                                            </svg>
                                                            <div className="pl-1 pr-2">EE &ge; 50%</div>
                                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g transform="rotate(180 100 100)">
                                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                                </g>
                                                            </svg>
                                                            <div className="pl-1 pr-2">EE &lt; 50%</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <div className="flex flex-row justify-between">
                                                <div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="h-[70px] w-[570px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center">
                                                                    <div>Trend</div>
                                                                    <div>Non-key Employees</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key'), false)}/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key'), false, true)}/>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-center justify-center mt-[5px] border-2 border-primary-dark-green h-[56px] w-[572px]">
                                                <TrendArrow trend={2} height="40px" width="40px" />
                                                <div className="pl-1 pr-8">Average &lt; 2.5</div>
                                                <TrendArrow trend={3} height="40px" width="40px" />
                                                <div className="pl-1 pr-8">2.5 &le; Average &lt; 3.5</div>
                                                <TrendArrow trend={5} height="40px" width="40px" />
                                                <div className="pl-1 pr-8">Average &ge; 3.5</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="py-7">
                    <section id="page-4-2" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">REMS Profile - Direct Reports (Non-key)</div>
                                    <div className="w-full flex py-7">
                                        <div className="w-5/12">
                                            <SpiderChart width="640px" supervisor_answer="" personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false)} />
                                        </div>
                                        <div className="w-7/12 pt-9">
                                            <table className="w-full">
                                                <tbody>
                                                    <tr className="text-lg text-primary-white">
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Category</th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">Trend</th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left">Urgent Calls</th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Proposed Tools</th>
                                                    </tr>
                                                    <tr className="text-lg text-primary-white">
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">
                                                            Employee
                                                        </th>
                                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4 text-sm">
                                                            *Also, in previous round
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">JOB</td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 0)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(index, index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false, true).substring(index, index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "J").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(Number(String(tool.question_no).substring(1)) - 1, Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">LEADER</td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 1)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(4 + index, 4 + index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false, true).substring(4 + index, 4 + index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "L").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(4 + Number(String(tool.question_no).substring(1)) - 1, 4 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">REWARDS</td>
                                                        <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 2)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(8 + index, 8 + index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false, true).substring(8 + index, 8 + index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "R").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(8 + Number(String(tool.question_no).substring(1)) - 1, 8 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-xl px-6 border-2 border-primary-dark-green">COMPANY</td>
                                                        <td className="py-4 border-2 border-primary-dark-green">
                                                            <div className="flex items-center justify-center">
                                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 3)} height="60px" width="60px" />
                                                            </div>
                                                        </td>
                                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                                <Fragment key={index}>
                                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(12 + index, 12 + index + 1)) < 3 &&
                                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false, true).substring(12 + index, 12 + index + 1)) < 3 && <span>*</span>}</div>
                                                                    }
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "C").map((tool) => {
                                                                let result = null;
                                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(12 + Number(String(tool.question_no).substring(1)) - 1, 12 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                                return result;
                                                            }).filter((item, pos, self) => {
                                                                return self.indexOf(item) === pos;
                                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="py-7">
                    <section id="page-4-3" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">Trend Chart - Direct Reports (Non-key)</div>
                                    <div className="w-full py-7 flex flex-row">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="h-[68px] w-[200px]"></td>
                                                </tr>
                                                <tr>
                                                    <td className="h-[268px] p-2 pl-6">
                                                        <div>Current round:</div>
                                                        <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key')[0]?.round_name}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="h-[268px] p-2 pl-6">
                                                        <div>Previous round:</div>
                                                        <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key')[0]?.prev_round_name}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key').map((emp, index) => ( 
                                            <table className="mr-2" key={index}>
                                                <tbody>
                                                    <tr>
                                                        <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center">
                                                            <div>Trend</div>
                                                            <div>{emp.full_name}</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[300px] border-primary-dark-green border-2 p-2">
                                                            <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key' && item.full_name === emp.full_name), false)}/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="h-[300px] border-primary-dark-green border-2 p-2">
                                                            <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key' && item.full_name === emp.full_name), false, true)}/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        ))}
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="py-7">
                    <section id="page-4-4" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">HR Comment - Direct Reports (Non-key)</div>
                                    <div className="mt-3 mb-12">
                                        <div className="border-2 border-primary-dark-green">
                                            <textarea rows="24" className="p-1 w-full text-2xl" placeholder="Fluctuation rate:" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>

            <Fragment>
                <div className="py-7">
                    <section id="page-5-1" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                                        Special Report 1: Employee Satisfaction per Category
                                    </div>
                                    <div className="flex flex-row py-7">
                                        <table className="w-full h-full">
                                            <tbody>
                                                <tr>
                                                    <td className="w-4/5 bg-primary-dark-green text-primary-white border-2 border-primary-dark-green p-2" colSpan="4">Distribution of employee ratings per category</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                                        <div className="flex flex-row justify-center pt-2">Job<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0)}</span></div>
                                                        <RatingCategoryChart height="380px" width="380px" category="job"
                                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 0)} 
                                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 1)}
                                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 2)} />
                                                    </td>
                                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                                        <div className="flex flex-row justify-center pt-2">Leader<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1)}</span></div>
                                                        <RatingCategoryChart height="380px" width="380px" category="leader"
                                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 0)} 
                                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 1)}
                                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 2)} />
                                                    </td>
                                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                                        <div className="flex flex-row justify-center pt-2">Rewards<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2)}</span></div>
                                                        <RatingCategoryChart height="380px" width="380px" category="rewards"
                                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 0)} 
                                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 1)}
                                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 2)} />
                                                    </td>
                                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                                        <div className="flex flex-row justify-center pt-2">Company<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3)}</span></div>
                                                        <RatingCategoryChart height="380px" width="380px" category="company"
                                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 0)} 
                                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 1)}
                                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 2)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-4/5 bg-primary-dark-green text-primary-white border-2 border-primary-dark-green p-2" colSpan="4">Overall rating (trend) and accumulated number of urgent calls per question</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 0)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 0)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "J1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 0)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 0)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "L1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 0)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 0)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "R1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 0)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 0)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "C1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 1)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 1)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "J2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 1)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 1)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "L2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 1)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 1)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "R2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 1)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 1)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "C2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 2)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 2)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "J3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 2)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 2)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "L3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 2)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 2)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "R3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-x-2 border-primary-dark-green">
                                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 2)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 2)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "C3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 3)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 3)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "J4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                </td>
                                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 3)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 3)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "L4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 3)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 3)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "R4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 3)}</span>
                                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 3)}</span>
                                                            <span>{currentQuestions.filter(question => question.question_no === "C4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="py-7">
                    <section id="page-5-2" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8 h-full">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                                        Special Report 2: Urgent Calls vs Deviations
                                    </div>
                                    <div className="py-7 h-full">
                                        <table className="w-full h-[65%]">
                                            <tbody>
                                                <tr>
                                                    <td className="w-[34px] h-[34px]"></td>
                                                    <td className="px-2 border-r-2 text-sm whitespace-nowrap">Sup/Emp View<br />(% Deviations)</td>
                                                    <td colSpan="7"></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-xs bg-[#ed9883] text-center align-middle max-w-[34px]"><div className="transform origin-center translate-y-[12px] rotate-[-90deg]">EXTREME</div></td>
                                                    <td className="text-sm text-center py-6 px-2 border-2 ">x &gt; 75%</td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-gray-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 75 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-gray-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 75 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 75 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 75 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 75 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-xs bg-gradient-to-t from-[#f4ed9f] to-[#ed9883] text-center align-middle max-w-[34px]" rowSpan="2"><div className="transform origin-center rotate-[-90deg]">HIGH</div></td>
                                                    <td className="text-sm text-center py-6 px-2 border-2">50% &lt; x &le; 75%</td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-gray-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 50 && percentageDeviation(item) <= 75 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-gray-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 50 && percentageDeviation(item) <= 75 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 50 && percentageDeviation(item) <= 75 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 50 && percentageDeviation(item) <= 75 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 50 && percentageDeviation(item) <= 75 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-sm text-center py-6 px-2 border-2">25% &lt; x &le; 50%</td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-gray-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 25 && percentageDeviation(item) <= 50 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-gray-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 25 && percentageDeviation(item) <= 50 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 25 && percentageDeviation(item) <= 50 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 25 && percentageDeviation(item) <= 50 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-300">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 25 && percentageDeviation(item) <= 50 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-xs bg-gradient-to-t from-[#cae1c2] to-[#f4ed9f] text-center align-middle max-w-[34px]"><div className="transform origin-center translate-y-[8px] rotate-[-90deg]">MEDIUM</div></td>
                                                    <td className="text-sm text-center py-6 px-2 border-2">12.5% &lt; x &le; 25%</td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-cyan-50">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 12.5 && percentageDeviation(item) <= 25 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-cyan-50">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 12.5 && percentageDeviation(item) <= 25 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-sky-100">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 12.5 && percentageDeviation(item) <= 25 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-sky-100">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 12.5 && percentageDeviation(item) <= 25 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-sky-100">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) > 12.5 && percentageDeviation(item) <= 25 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-xs bg-[#cae1c2] text-center align-middle max-w-[34px]"><div className="transform origin-center rotate-[-90deg]">LOW</div></td>
                                                    <td className="text-sm text-center py-6 px-2 border-2">x &le; 12.5%</td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-cyan-50">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) <= 12.5 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-cyan-50">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) <= 12.5 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-sky-100">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) <= 12.5 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-sky-100">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) <= 12.5 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2 bg-sky-100">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                                (percentageDeviation(item) <= 12.5 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr className="text-sm">
                                                    <td></td>
                                                    <td></td>
                                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &le; 12.5%</td>
                                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">12.5% &lt; x &le; 25%</td>
                                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">25% &lt; x &le; 50%</td>
                                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">50% &lt; x &le; 75%</td>
                                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &gt; 75%</td>
                                                    <td className="border-t-2 px-2 whitespace-nowrap">Retention Risk<br />(% Urgent Calls)</td>
                                                </tr>
                                                <tr className="text-xs">
                                                    <td></td>
                                                    <td></td>
                                                    <td className="w-1/12 text-center py-2 border-t-2 bg-[#cae1c2]">LOW</td>
                                                    <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#cae1c2] to-[#f4ed9f]">MEDIUM</td>
                                                    <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#f4ed9f] to-[#ed9883]" colSpan="2">HIGH</td>
                                                    <td className="w-1/12 text-center py-2 border-t-2 bg-[#ed9883]">EXTREME</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className="text-center py-6 px-2 text-sm">Non-key</td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Non-key').map((item, index) => (
                                                                (percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Non-key').map((item, index) => (
                                                                (percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Non-key').map((item, index) => (
                                                                (percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Non-key').map((item, index) => (
                                                                (percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && item.key_status === 'Non-key').map((item, index) => (
                                                                (percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="py-7">
                    <section id="page-5-3" className="bg-white">
                        <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="px-8 h-full">
                                    <div className="flex flex-row">
                                        <div className="mr-auto">
                                            <div className="mt-[6px]">
                                                <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                            </div>
                                            <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                REMS Team Profile
                                            </div>
                                            <div className="text-black text-3xl font-medium">
                                                Supervisor: {selectedPersonnel?.full_name} 
                                            </div>
                                        </div>
                                        <div className="w-[192px] pt-8">
                                            <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                                        Special Report 3: Retention Risk by Job Tenure
                                    </div>
                                    <div className="py-7 h-full">
                                        <table className="w-full h-[65%]">
                                            <tbody>
                                                <tr>
                                                    <td className="px-2 border-r-2 text-sm">Job Tenure<br />(Years)</td>
                                                    <td colSpan="7"></td>
                                                </tr>
                                                <tr>
                                                    <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">Year &ge; 5</td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 5 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 5 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 5 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 5 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 5 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="w-1/12"></td>
                                                </tr>
                                                <tr>
                                                    <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">4 &le; year &lt; 5</td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 4 && item.job_tenure < 5 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 4 && item.job_tenure < 5 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 4 && item.job_tenure < 5 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 4 && item.job_tenure < 5 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 4 && item.job_tenure < 5 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">3 &le; year &lt; 4</td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 3 && item.job_tenure < 4 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 3 && item.job_tenure < 4 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 3 && item.job_tenure < 4 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 3 && item.job_tenure < 4 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 3 && item.job_tenure < 4 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">1 &le; year &lt; 3</td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 1 && item.job_tenure < 3 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 1 && item.job_tenure < 3 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 1 && item.job_tenure < 3 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 1 && item.job_tenure < 3 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure >= 1 && item.job_tenure < 3 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">Year &lt; 1</td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure < 1 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure < 1 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure < 1 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure < 1 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border-2 w-2/12 py-6 px-2">
                                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                (item.job_tenure < 1 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td></td>
                                                </tr>
                                                <tr className="text-sm">
                                                    <td></td>
                                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &le; 12.5%</td>
                                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">12.5% &lt; x &le; 25%</td>
                                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">25% &lt; x &le; 50%</td>
                                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">50% &lt; x &le; 75%</td>
                                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &gt; 75%</td>
                                                    <td className="border-t-2 px-2">Retention Risk<br />(% Urgent Calls)</td>
                                                </tr>
                                                <tr className="text-xs">
                                                    <td></td>
                                                    <td className="w-1/12 text-center py-2 border-t-2 bg-[#cae1c2]">LOW</td>
                                                    <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#cae1c2] to-[#f4ed9f]">MEDIUM</td>
                                                    <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#f4ed9f] to-[#ed9883]" colSpan="2">HIGH</td>
                                                    <td className="w-1/12 text-center py-2 border-t-2 bg-[#ed9883]">EXTREME</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                    <hr className="border-primary-dark-green" />
                                    <table className="w-full">
                                        <tbody>
                                            <tr>
                                                <td className="px-2 text-xl text-left">
                                                    {expDate}
                                                </td>
                                                <td className="px-2 text-xl text-right">
                                                    Internal: All rights reserved. Distribution within the company, customer and partners.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && Number(item.fes_level) >= 17).length > 0 &&
                    <div className="py-7">
                        <section id="page-5-4a" className="bg-white">
                            <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                                <div className="absolute top-0 left-0 w-full h-full">
                                    <div className="px-8 h-full">
                                        <div className="flex flex-row">
                                            <div className="mr-auto">
                                                <div className="mt-[6px]">
                                                    <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                                </div>
                                                <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                    REMS Team Profile
                                                </div>
                                                <div className="text-black text-3xl font-medium">
                                                    Supervisor: {selectedPersonnel.full_name} 
                                                </div>
                                            </div>
                                            <div className="w-[192px] pt-8">
                                                <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                    <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                    <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                    <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                    <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                    <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                    <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                    <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                    <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                    <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                    <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                                            Special Report 4: Retention Risk by FES
                                        </div>
                                        <div className="py-7 h-full">
                                            <table className="w-full h-[65%]">
                                                <tbody>
                                                    <tr>
                                                        <td className="px-2 border-r-2 text-sm">FES<br />&nbsp;</td>
                                                        <td colSpan="7"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 22+</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) >= 22 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) >= 22 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) >= 22 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) >= 22 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) >= 22 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="w-1/12"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 21</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 21 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 21 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 21 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 21 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 21 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 20</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 20 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 20 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 20 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 20 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 20 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 19</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 19 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 19 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 19 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 19 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 19 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 18</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 18 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 18 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 18 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 18 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 18 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 17</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 17 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 17 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 17 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 17 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 17 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className="text-sm">
                                                        <td></td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &le; 12.5%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">12.5% &lt; x &le; 25%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">25% &lt; x &le; 50%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">50% &lt; x &le; 75%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &gt; 75%</td>
                                                        <td className="border-t-2 px-2">Retention Risk<br />(% Urgent Calls)</td>
                                                    </tr>
                                                    <tr className="text-xs">
                                                        <td></td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#cae1c2]">LOW</td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#cae1c2] to-[#f4ed9f]">MEDIUM</td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#f4ed9f] to-[#ed9883]" colSpan="2">HIGH</td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#ed9883]">EXTREME</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                        <hr className="border-primary-dark-green" />
                                        <table className="w-full">
                                            <tbody>
                                                <tr>
                                                    <td className="px-2 text-xl text-left">
                                                        {expDate}
                                                    </td>
                                                    <td className="px-2 text-xl text-right">
                                                        Internal: All rights reserved. Distribution within the company, customer and partners.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                }

                { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && Number(item.fes_level) < 17 && Number(item.fes_level) >= 11 ).length > 0 &&
                    <div className="py-7">
                        <section id="page-5-4b" className="bg-white">
                            <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                                <div className="absolute top-0 left-0 w-full h-full">
                                    <div className="px-8 h-full">
                                        <div className="flex flex-row">
                                            <div className="mr-auto">
                                                <div className="mt-[6px]">
                                                    <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                                </div>
                                                <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                    REMS Team Profile
                                                </div>
                                                <div className="text-black text-3xl font-medium">
                                                    Supervisor: {selectedPersonnel.full_name} 
                                                </div>
                                            </div>
                                            <div className="w-[192px] pt-8">
                                                <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                    <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                    <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                    <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                    <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                    <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                    <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                    <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                    <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                    <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                    <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                                            Special Report 4: Retention Risk by FES
                                        </div>

                                        <div className="py-7 h-full">
                                            <table className="w-full h-[65%]">
                                                <tbody>
                                                    <tr>
                                                        <td className="px-2 border-r-2 text-sm">FES<br />&nbsp;</td>
                                                        <td colSpan="7"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 16</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 16 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 16 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 16 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 16 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 16 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="w-1/12"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 15</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 15 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 15 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 15 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 15 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 15 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 14</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 14 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 14 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 14 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 14 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 14 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 13</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 13 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 13 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 13 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 13 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 13 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 12</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 12 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 12 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 12 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 12 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 12 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 11</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 11 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 11 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 11 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 11 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 11 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className="text-sm">
                                                        <td></td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &le; 12.5%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">12.5% &lt; x &le; 25%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">25% &lt; x &le; 50%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">50% &lt; x &le; 75%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &gt; 75%</td>
                                                        <td className="border-t-2 px-2">Retention Risk<br />(% Urgent Calls)</td>
                                                    </tr>
                                                    <tr className="text-xs">
                                                        <td></td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#cae1c2]">LOW</td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#cae1c2] to-[#f4ed9f]">MEDIUM</td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#f4ed9f] to-[#ed9883]" colSpan="2">HIGH</td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#ed9883]">EXTREME</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                        <hr className="border-primary-dark-green" />
                                        <table className="w-full">
                                            <tbody>
                                                <tr>
                                                    <td className="px-2 text-xl text-left">
                                                        {expDate}
                                                    </td>
                                                    <td className="px-2 text-xl text-right">
                                                        Internal: All rights reserved. Distribution within the company, customer and partners.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                }
                { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1 && Number(item.fes_level) < 11 && Number(item.fes_level) >= 5 ).length > 0 &&
                    <div className="py-7">
                        <section id="page-5-4c" className="bg-white">
                            <div className="w-full h-0 flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                                <div className="absolute top-0 left-0 w-full h-full">
                                    <div className="px-8 h-full">
                                        <div className="flex flex-row">
                                            <div className="mr-auto">
                                                <div className="mt-[6px]">
                                                    <span className="bg-black text-xl px-3 py-2 text-primary-white">CONFIDENTIAL</span>
                                                </div>
                                                <div className="text-5xl text-primary-green font-bold pt-10 pb-1">
                                                    REMS Team Profile
                                                </div>
                                                <div className="text-black text-3xl font-medium">
                                                    Supervisor: {selectedPersonnel.full_name} 
                                                </div>
                                            </div>
                                            <div className="w-[192px] pt-8">
                                                <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                                    <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                                    <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                                    <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                                    <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                                    <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                                    <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                                    <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                                    <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                                    <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                                    <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                                            Special Report 4: Retention Risk by FES
                                        </div>

                                        <div className="py-7 h-full">
                                            <table className="w-full h-[65%]">
                                                <tbody>
                                                    <tr>
                                                        <td className="px-2 border-r-2 text-sm">FES<br />&nbsp;</td>
                                                        <td colSpan="7"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 10</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 10 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 10 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 10 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 10 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 10 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="w-1/12"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 9</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 9 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 9 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 9 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 9 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 9 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 8</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 8 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 8 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 8 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 8 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 8 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 7</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 7 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 7 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 7 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 7 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 7 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 6</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 6 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 6 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 6 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 6 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 6 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 5</td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 5 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 5 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 5 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 5 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td className="border-2 w-2/12 py-6 px-2">
                                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1).map((item, index) => (
                                                                    (Number(item.fes_level) === 5 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                                ))
                                                            }
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr className="text-sm">
                                                        <td></td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &le; 12.5%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">12.5% &lt; x &le; 25%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">25% &lt; x &le; 50%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">50% &lt; x &le; 75%</td>
                                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &gt; 75%</td>
                                                        <td className="border-t-2 px-2">Retention Risk<br />(% Urgent Calls)</td>
                                                    </tr>
                                                    <tr className="text-xs">
                                                        <td></td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#cae1c2]">LOW</td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#cae1c2] to-[#f4ed9f]">MEDIUM</td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#f4ed9f] to-[#ed9883]" colSpan="2">HIGH</td>
                                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#ed9883]">EXTREME</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="absolute bottom-0 left-0 w-full px-8 h-16">
                                        <hr className="border-primary-dark-green" />
                                        <table className="w-full">
                                            <tbody>
                                                <tr>
                                                    <td className="px-2 text-xl text-left">
                                                        {expDate}
                                                    </td>
                                                    <td className="px-2 text-xl text-right">
                                                        Internal: All rights reserved. Distribution within the company, customer and partners.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                }
            </Fragment>

            <Fragment>
                <div className="py-7">
                    <section id="cover-1-1" className="bg-white">
                        <div className="w-full h-0 bg-cover1 bg-no-repeat bg-left-top bg-cover flex flex-row relative border-2 min-w-[1745px] min-h-[1237px] max-w-[1745px] max-h-[1237px]">
                            <div className="absolute top-0 left-0 w-full">
                                <div className="flex flex-row">
                                    <div className="mr-auto" />
                                    <div className="w-[224px] pt-8 pr-8">
                                        <svg x="0px" y="0px" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#000000" />
                                            <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#000000" />
                                            <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#000000" />
                                            <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#000000" />
                                            <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#000000" />
                                            <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#000000" />
                                            <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#000000" />
                                            <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#000000" />
                                            <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#000000" />
                                            <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#000000" />
                                            <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#000000" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="pt-[40%] pb-3 pl-24 text-8xl font-bold text-primary-white">
                                    Individual Profile 
                                </div>
                                <div className="px-24 w-full"><hr className="border-primary-green border-2" /></div>
                                <div className="pt-5 pl-24 text-6xl text-primary-white">
                                    <div>CONFIDENTIAL</div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>

            {isLoading && <Loading /> }
        </div>
    );
}

export default SummaryReport;
import { Fragment, useEffect, useState } from 'react';
import api from "../Api";
import { useParams } from 'react-router-dom';
import Loading from "../Loading";
import { BsArrowRight } from "react-icons/bs";
import Dialog from "../Dialog";

function Questionnaire({setNavbar, forceRefresh, setForceRefresh}) {
    const { guid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [personnels, setPersonnels] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [questionnairetype, setQuestionnaireType] = useState('');
    const [questionnairegroup, setQuestionnaireGroup] = useState('');
    const [pageno, setPageno] = useState(1);
    const [enableSaveSubmit, setEnableSaveSubmit] = useState(false);
    const [showDialog, setShowDialog] = useState(false);


    useEffect(() => {
        setNavbar(false);
    },[setNavbar]);

    useEffect(() => {
        const fetchPersonnels = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/question/guid/${guid}?key=${forceRefresh}`);
                setQuestions(response.data);
                const response1 = await api.get(`/questionnaire/${guid}?key=` + {forceRefresh});
                setPersonnels(response1.data);
                const response2 = await api.get(`/template/questionnaire?key=${forceRefresh}`);
                setTemplates(response2.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        fetchPersonnels();
    },[guid, forceRefresh]);

    useEffect(() => {
        if (personnels.find(personnel => personnel.status !== 'Submitted') !== undefined) 
            setEnableSaveSubmit(true);
        else
            setEnableSaveSubmit(false);

        if(personnels.length !== 0) {
            setQuestionnaireType(personnels[0].questionnaire_type);
            if( personnels[0].work_contract === 'Indirect worker' || personnels[0].work_contract === 'Direct worker' )
                setQuestionnaireGroup('Blue');
            else
                setQuestionnaireGroup('White');
        }

    },[personnels])

    const saveSubmittedSupervisor = async (personnels) => {
        try {
            setIsLoading(true);
            const response = await api.put('/questionnaire/supervisor/submit',personnels);
            console.log(response);
            setForceRefresh(Date.now());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const saveSubmittedIndividual = async (personnels) => {
        try {
            setIsLoading(true);
            const response = await api.put('/questionnaire/individual/submit',personnels);
            console.log(response);
            setForceRefresh(Date.now());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const saveAnsweredSupervisor = async (personnels) => {
        try {
            setIsLoading(true);
            const response = await api.put('/questionnaire/supervisor/save',personnels);
            console.log(response);
            setForceRefresh(Date.now());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const saveAnsweredIndividual = async (personnels) => {
        try {
            setIsLoading(true);
            const response = await api.put('/questionnaire/individual/save',personnels);
            console.log(response);
            setForceRefresh(Date.now());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSubmit = () => {
        console.log(personnels.find(personnel => String(personnel.answer).indexOf("0") === -1));
        if (personnels.find(personnel => String(personnel.answer).indexOf("0") !== -1) !== undefined) 
            setShowDialog(true);
        else {
            switch (questionnairetype) {
                case 'Supervisor':
                    saveSubmittedSupervisor(personnels.map(({questionnaire_id, answer, ...personnel}) => { return Object.values({answer, questionnaire_id}) }));
                    break;
                case 'Individual':
                    saveSubmittedIndividual(personnels.map(({questionnaire_id, answer, feedback1, feedback2, feedback3, feedback4, ...personnel}) => { return Object.values({answer, feedback1, feedback2, feedback3, feedback4, questionnaire_id}) }));
                    break;
                default:
            }
    
        }
    }

    const handleSave = () => {
        switch (questionnairetype) {
            case 'Supervisor':
                saveAnsweredSupervisor(personnels.map(({questionnaire_id, answer, ...personnel}) => { return Object.values({answer, questionnaire_id}) }));
                break;
            case 'Individual':
                saveAnsweredIndividual(personnels.map(({questionnaire_id, answer, feedback1, feedback2, feedback3, feedback4, ...personnel}) => { return Object.values({answer, feedback1, feedback2, feedback3, feedback4, questionnaire_id}) }));
                break;
            default:
        }
    }

    const handlePrev = () => {
        setPageno(pageno - 1);
    }

    const handleNext = () => {
        setPageno(pageno + 1);
    }


    const getAnswer = (answer, qno, rno) => {
        return (Number(String(answer).substring(qno, qno+1)) === rno);
    }

    const setAnswer = (personnels, id, answer, qno, rno, value) => {
        if(value)
            setPersonnels(
                personnels.map((personnel) => {
                    return personnel.id === id? {...personnel, answer: String(answer).substring(0,qno) + rno.toString() + String(answer).substring(qno+1)} : personnel;
            }));
    }

    return (
        <div className="w-11/12 py-3">
            <h1 className="text-2xl font-medium text-primary-black">Questionnaire</h1>
            <div>
                <div className="flex flex-row text-primary-black pt-3 pb-12">

                    <button className={`${enableSaveSubmit ? 'bg-primary-green':'bg-primary-grey'} text-primary-white text-lg px-10 py-1 rounded-lg mr-2`}
                        disabled={!enableSaveSubmit} onClick={handleSave}>Save</button>
                    <button className={`${enableSaveSubmit ? 'bg-primary-green':'bg-primary-grey'} text-primary-white text-lg px-10 py-1 rounded-lg mr-12`}
                        disabled={!enableSaveSubmit} onClick={handleSubmit}>Submit</button>
                    <button className={`${pageno === 1 ? 'bg-primary-grey' : 'bg-primary-green'} text-primary-white text-lg px-10 py-1 rounded-lg`} onClick={handlePrev}
                        disabled={pageno === 1}>Prev</button>
                    <span className="text-lg text-center w-16 pt-1">{pageno} / 5</span>
                    <button className={`${pageno === 5 ? 'bg-primary-grey' : 'bg-primary-green'} text-primary-white text-lg px-10 py-1 rounded-lg`} onClick={handleNext}
                        disabled={pageno === 5}>Next</button>
                </div>

                { pageno === 1 &&
                    <div className="flex flex-row">
                        <div className="bg-no-repeat bg-left-top w-[420px] h-[420px] min-w-[420px] min-h-[420px] bg-questionnaire" />
                        <div className="text-lg text-primary-black ml-12 mt-8">
                            {questionnairetype === 'Supervisor' &&
                                <p dangerouslySetInnerHTML={{__html:`${!isLoading ? templates.find(({feature, item}) => feature === 'questionnaire' && item === 'qnairetexts').t_text.replace(/\n/g, '<br />') : ''}` }} />
                            }
                            {questionnairetype === 'Individual' &&
                                <p dangerouslySetInnerHTML={{__html:`${!isLoading ? templates.find(({feature, item}) => feature === 'questionnaire' && item === 'qnairetexti').t_text.replace(/\n/g, '<br />') : ''}` }} />
                            }
                        </div>
                    </div> 
                }
                { pageno === 2 &&
                    <div className="flex flex-row">
                        <div className="bg-no-repeat bg-left-top w-[420px] h-[420px] min-w-[420px] min-h-[420px] bg-job" />
                        <div className="text-lg text-primary-black ml-12 mt-3 w-full">
                            {questions.filter((question) => (questionnairegroup !== '' && String(question.question_no).substring(0,1) === 'J') ? question.work_contract_group === questionnairegroup : false).map((question, indexq) => (
                                <table className="text-primary-black border-primary-grey text-base text-left w-full mb-6" key={indexq}>
                                    <tbody>
                                        <tr className="bg-gray-300">
                                            <th className="border-0 w-7/12 p-2">
                                                {questionnairetype === 'Supervisor' && <Fragment>{question.question_no}. {question.title}<br /><span className="font-normal italic">{question.supervisor_question}</span></Fragment>}
                                                {questionnairetype === 'Individual' && <Fragment>{question.question_no}. {question.title}</Fragment>}
                                            </th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.lowest}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.low === '' ? <BsArrowRight className='w-full'/> : question.low}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.middle === '' ? <BsArrowRight className='w-full'/> : question.middle}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.high === '' ? <BsArrowRight className='w-full'/> : question.high}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.highest}</th>
                                        </tr>
                                        {personnels.map((personnel, index) => (
                                            <tr key={personnel.id} className={index % 2 === 0? `bg-white` : `bg-gray-100`}>
                                                <td className="border-0"><div className={`${questionnairetype === 'Individual' && 'italic '}flex flex-row px-2`}>
                                                    {questionnairetype === 'Supervisor' &&
                                                        <Fragment>{personnel.full_name}</Fragment>
                                                    }
                                                    {questionnairetype === 'Individual' &&
                                                        <Fragment>{question.staff_question}</Fragment>
                                                    }
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq, 1)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq, 1, e.target.value)} 
                                                    disabled={personnel.status === 'Submitted'} />               
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq, 2)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq, 2, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq, 3)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq, 3, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq, 4)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq, 4, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq, 5)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq, 5, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ))}
                            {questionnairetype === 'Individual' && <textarea rows="4" className="p-1 border border-primary-grey w-full mt-12" placeholder="[ Feedback ]" 
                                value={personnels[0].feedback1} onChange={e => setPersonnels(personnels.map((personnel) => {return {...personnel, feedback1: e.target.value} }))} 
                                disabled={personnels[0].status === 'Submitted'} />}
                        </div>
                    </div>
                }
                { pageno === 3 &&
                    <div className="flex flex-row">
                        <div className="bg-no-repeat bg-left-top w-[420px] h-[420px] min-w-[420px] min-h-[420px] bg-leader" />
                        <div className="text-lg text-primary-black ml-12 mt-3 w-full">
                            {questions.filter((question) => (questionnairegroup !== '' && String(question.question_no).substring(0,1) === 'L') ? question.work_contract_group === questionnairegroup : false).map((question, indexq) => (
                                <table className="text-primary-black border-primary-grey text-base text-left w-full mb-6" key={indexq}>
                                    <tbody>
                                        <tr className="bg-gray-300">
                                            <th className="border-0 w-7/12 p-2">                                                
                                                {questionnairetype === 'Supervisor' && <Fragment>{question.question_no}. {question.title}<br /><span className="font-normal italic">{question.supervisor_question}</span></Fragment>}
                                                {questionnairetype === 'Individual' && <Fragment>{question.question_no}. {question.title}</Fragment>}
                                            </th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.lowest}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.low === '' ? <BsArrowRight className='w-full'/> : question.low}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.middle === '' ? <BsArrowRight className='w-full'/> : question.middle}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.high === '' ? <BsArrowRight className='w-full'/> : question.high}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.highest}</th>
                                        </tr>
                                        {personnels.map((personnel, index) => (
                                            <tr key={personnel.id} className={index % 2 === 0? `bg-white` : `bg-gray-100`}>
                                                <td className="border-0"><div className={`${questionnairetype === 'Individual' && 'italic '}flex flex-row px-2`}>
                                                    {questionnairetype === 'Supervisor' &&
                                                        <Fragment>{personnel.full_name}</Fragment>
                                                    }
                                                    {questionnairetype === 'Individual' &&
                                                        <Fragment>{question.staff_question}</Fragment>
                                                    }
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 4, 1)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 4, 1, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />                                                
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 4, 2)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 4, 2, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 4, 3)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 4, 3, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 4, 4)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 4, 4, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 4, 5)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 4, 5, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ))}
                            {questionnairetype === 'Individual' && <textarea rows="4" className="p-1 border border-primary-grey w-full mt-12" placeholder="[ Feedback ]" 
                                value={personnels[0].feedback2} onChange={e => setPersonnels(personnels.map((personnel) => {return {...personnel, feedback2: e.target.value} }))} 
                                disabled={personnels[0].status === 'Submitted'} />}
                        </div>

                    </div>
                }
                { pageno === 4 &&
                    <div className="flex flex-row">
                        <div className="bg-no-repeat bg-left-top w-[420px] h-[420px] min-w-[420px] min-h-[420px] bg-rewards" />
                        <div className="text-lg text-primary-black ml-12 mt-3 w-full">
                            {questions.filter((question) => (questionnairegroup !== '' && String(question.question_no).substring(0,1) === 'R') ? question.work_contract_group === questionnairegroup : false).map((question, indexq) => (
                                <table className="text-primary-black border-primary-grey text-base text-left w-full mb-6" key={indexq}>
                                    <tbody>
                                        <tr className="bg-gray-300">
                                            <th className="border-0 w-7/12 p-2">
                                                {questionnairetype === 'Supervisor' && <Fragment>{question.question_no}. {question.title}<br /><span className="font-normal italic">{question.supervisor_question}</span></Fragment>}
                                                {questionnairetype === 'Individual' && <Fragment>{question.question_no}. {question.title}</Fragment>}
                                            </th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.lowest}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.low === '' ? <BsArrowRight className='w-full'/> : question.low}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.middle === '' ? <BsArrowRight className='w-full'/> : question.middle}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.high === '' ? <BsArrowRight className='w-full'/> : question.high}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.highest}</th>
                                        </tr>
                                        {personnels.map((personnel, index) => (
                                            <tr key={personnel.id} className={index % 2 === 0? `bg-white` : `bg-gray-100`}>
                                                <td className="border-0"><div className={`${questionnairetype === 'Individual' && 'italic '}flex flex-row px-2`}>
                                                    {questionnairetype === 'Supervisor' &&
                                                        <Fragment>{personnel.full_name}</Fragment>
                                                    }
                                                    {questionnairetype === 'Individual' &&
                                                        <Fragment>{question.staff_question}</Fragment>
                                                    }
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 8, 1)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 8, 1, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />                                                
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 8, 2)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 8, 2, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 8, 3)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 8, 3, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 8, 4)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 8, 4, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 8, 5)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 8, 5, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ))}
                            {questionnairetype === 'Individual' && <textarea rows="4" className="p-1 border border-primary-grey w-full mt-12" placeholder="[ Feedback ]" 
                                value={personnels[0].feedback3} onChange={e => setPersonnels(personnels.map((personnel) => {return {...personnel, feedback3: e.target.value} }))} 
                                disabled={personnels[0].status === 'Submitted'}/>}
                        </div>

                    </div>
                }
                { pageno === 5 &&
                    <div className="flex flex-row">
                        <div className="bg-no-repeat bg-left-top w-[420px] h-[420px] min-w-[420px] min-h-[420px] bg-company" />
                        <div className="text-lg text-primary-black ml-12 mt-3 w-full">
                            {questions.filter((question) => (questionnairegroup !== '' && String(question.question_no).substring(0,1) === 'C') ? question.work_contract_group === questionnairegroup : false).map((question, indexq) => (
                                <table className="text-primary-black border-primary-grey text-base text-left w-full mb-6" key={indexq}>
                                    <tbody>
                                        <tr className="bg-gray-300">
                                            <th className="border-0 w-7/12 p-2">
                                                {questionnairetype === 'Supervisor' && <Fragment>{question.question_no}. {question.title}<br /><span className="font-normal italic">{question.supervisor_question}</span></Fragment>}
                                                {questionnairetype === 'Individual' && <Fragment>{question.question_no}. {question.title}</Fragment>}
                                            </th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.lowest}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.low === '' ? <BsArrowRight className='w-full'/> : question.low}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.middle === '' ? <BsArrowRight className='w-full'/> : question.middle}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.high === '' ? <BsArrowRight className='w-full'/> : question.high}</th>
                                            <th className="border-0 w-1/12 px-2 text-center">{question.highest}</th>
                                        </tr>
                                        {personnels.map((personnel, index) => (
                                            <tr key={personnel.id} className={index % 2 === 0? `bg-white` : `bg-gray-100`}>
                                                <td className="border-0"><div className={`${questionnairetype === 'Individual' && 'italic '}flex flex-row px-2`}>
                                                    {questionnairetype === 'Supervisor' &&
                                                        <Fragment>{personnel.full_name}</Fragment>
                                                    }
                                                    {questionnairetype === 'Individual' &&
                                                        <Fragment>{question.staff_question}</Fragment>
                                                    }
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 12, 1)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 12, 1, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />                                                
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 12, 2)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 12, 2, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 12, 3)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 12, 3, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 12, 4)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 12, 4, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                                <td className="border-0"><div className="flex flex-row align-middle justify-center">
                                                    <input type="radio" name={`${'answer' + pageno + '-' + indexq + '-' + index}`}
                                                    checked={getAnswer(personnel.answer, indexq + 12, 5)} 
                                                    onChange={(e) => setAnswer(personnels, personnel.id, personnel.answer, indexq + 12, 5, e.target.value)}
                                                    disabled={personnel.status === 'Submitted'} />
                                                </div></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ))}
                            {questionnairetype === 'Individual' && <textarea rows="4" className="p-1 border border-primary-grey w-full mt-12" placeholder="[ Feedback ]" 
                                value={personnels[0].feedback4} onChange={e => setPersonnels(personnels.map((personnel) => {return {...personnel, feedback4: e.target.value} }))} 
                                disabled={personnels[0].status === 'Submitted'} />}
                        </div>

                    </div>
                }
            </div>
            {isLoading && <Loading /> }
            {showDialog && <Dialog title="Submit" setShowDialog={setShowDialog} caption="Please answer all the questions before submitting. Or you can click save to save your answer temporarily." /> }
        </div>
    );
}

export default Questionnaire;
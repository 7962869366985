import { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import api from '../Api';
import Loading from "../Loading";

function QuestionForm ({ language, setForceRefresh, currentQTemplate }) {
    const { questionId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [question, setQuestion] = useState({id: '', template_id: null, work_contract_group: '', question_no: '', title: '', phrase: null,  staff_question: '', supervisor_question: '', lowest: '', low: '', middle: '', high: '', highest: '', is_global: null});
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuestion = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/question/${questionId}`);
                setQuestion(response.data[0]);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        if (questionId !== 'new') { 
            fetchQuestion();
        } else {
            setIsNew(true);
            setIsLoading(false);
        }
    },[questionId]);

    const postQuestion = async () => {
        try {
            setIsLoading(true);
            const response = await api.post('/question',{...question, template_id: currentQTemplate.id});
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/question`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const putQuestion = async () => {
        try {
            setIsLoading(true);
            const response = await api.put('/question',question);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/question`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSave = () => {
        if (!isEdit && !isNew) setIsEdit(true);
//        if (isNew) postQuestion();
        if (isEdit) {
            if (currentQTemplate.is_global === 1 || question.is_global === 0) {
                putQuestion();
            } else {
//                setQuestion({...question, template_id: currentQTemplate.id});
                postQuestion();
            } 
        }
    }

    const handleCancel = () => {
        setForceRefresh(crypto.randomUUID());
        navigate(`/${language}/question`);
    }

    return (
        <Fragment>
            {isLoading && <Loading />}
            {!isLoading &&
                <div className="modal bg-gray-300 bg-opacity-50">
                    <div className="w-[880px] bg-primary-white drop-shadow-md border border-primary-green flex flex-col items-center text-base">
                        <div className="bg-primary-green text-primary-white text-xl font-medium px-4 py-2 items-start mr-auto w-full">{(isNew ? `New` : (isEdit? `Edit` : `View`)) + ` - Question`}</div>
                        <form className="text-primary-black w-5/6 py-8">
                            <label htmlFor="category" className="float-left w-3/12 pt-2">Question No</label>
                            <select className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-black disabled:opacity-100" value={question.question_no} disabled
                                onChange={e => setQuestion({ ...question, question_no: e.target.value })} >
                                <option value=""></option>
                                <option value="J1">J1</option>
                                <option value="J2">J2</option>
                                <option value="J3">J3</option>
                                <option value="J4">J4</option>
                                <option value="L1">L1</option>
                                <option value="L2">L2</option>
                                <option value="L3">L3</option>
                                <option value="L4">L4</option>
                                <option value="R1">R1</option>
                                <option value="R2">R2</option>
                                <option value="R3">R3</option>
                                <option value="R4">R4</option>
                                <option value="C1">C1</option>
                                <option value="C2">C2</option>
                                <option value="C3">C3</option>
                                <option value="C4">C4</option>
                            </select>

                            <label htmlFor="recipient" className="float-left w-3/12 pt-2">Group</label>
                            <select className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-black disabled:opacity-100" value={question.work_contract_group} disabled
                                onChange={e => setQuestion({ ...question, work_contract_group: e.target.value })} >
                                <option value=""></option>
                                <option value="White">White</option>
                                <option value="Blue">Blue</option>
                            </select>

                            <fieldset disabled={!(isEdit || isNew)}>

                                <label htmlFor="title" className="float-left w-3/12 pt-2">Topic</label>
                                <input type="text" list="titles" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={question.title} onChange={e => setQuestion({ ...question, title: e.target.value })} />
                                <datalist id="titles">
                                    { question.question_no.substring(0,1) === 'J' &&
                                        <Fragment>
                                            <option>Job Development</option>
                                            <option>Career Perspectives</option>
                                            <option>Training and Qualification</option>
                                            <option>Job Match</option>
                                        </Fragment>
                                    }
                                    { question.question_no.substring(0,1) === 'L' &&
                                        <Fragment>
                                            <option>Supervisor Feedback</option>
                                            <option>Career Ambition</option>
                                            <option>Work Relation</option>
                                            <option>Exposure/Visibility</option>
                                        </Fragment>
                                    }
                                    { question.question_no.substring(0,1) === 'R' &&
                                        <Fragment>
                                            <option>Compared to Market</option>
                                            <option>Variability</option>
                                            <option>Benefits</option>
                                            <option>Merit Increase</option>
                                        </Fragment>
                                    }
                                    { question.question_no.substring(0,1) === 'C' &&
                                        <Fragment>
                                            <option>Company Goals</option>
                                            <option>Team</option>
                                            <option>Work Life Balance</option>
                                            <option>Corporate Culture and Values</option>
                                        </Fragment>
                                    }
                                </datalist>

                                <label htmlFor="title" className="float-left w-3/12 pt-2">Employee question</label>
                                <textarea rows="4" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={question.staff_question} onChange={e => setQuestion({ ...question, staff_question: e.target.value })} />

                                <label htmlFor="title" className="float-left w-3/12 pt-2">Supervisor question</label>
                                <textarea rows="4" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={question.supervisor_question} onChange={e => setQuestion({ ...question, supervisor_question: e.target.value })} />

                                <label htmlFor="lowest" className="float-left w-3/12 pt-2">Lowest Answer</label>
                                <textarea rows="1" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={question.lowest} onChange={e => setQuestion({ ...question, lowest: e.target.value })} />

                                <label htmlFor="lowest" className="float-left w-3/12 pt-2">Low Answer</label>
                                <textarea rows="1" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={question.low} onChange={e => setQuestion({ ...question, low: e.target.value })} />

                                <label htmlFor="middle" className="float-left w-3/12 pt-2">Middle Answer</label>
                                <textarea rows="1" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={question.middle} onChange={e => setQuestion({ ...question, middle: e.target.value })} />

                                <label htmlFor="highest" className="float-left w-3/12 pt-2">High Answer</label>
                                <textarea rows="1" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={question.high} onChange={e => setQuestion({ ...question, high: e.target.value })} />

                                <label htmlFor="highest" className="float-left w-3/12 pt-2">Highest Answer</label>
                                <textarea rows="1" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={question.highest} onChange={e => setQuestion({ ...question, highest: e.target.value })} />

                            </fieldset>
                        </form>
                        <div className=" text-primary-white font-medium w-full flex justify-end pb-8 text-lg">
                            <button className="bg-primary-green rounded-lg h-10 w-40 mr-6" onClick={handleSave}>{isEdit || isNew ? `Save` : `Edit`}</button>
                            <button className="bg-primary-grey rounded-lg h-10 w-40 mr-6" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default QuestionForm;

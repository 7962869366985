import { Fragment, useEffect, useState, useRef } from "react";

function SpiderChart ({ width, height, supervisor_answer, personnel_answer, questions=[] }) {
    const [path1, setPath1] = useState([]);
    const [path2, setPath2] = useState([]);
    const [answer1, setAnswer1] = useState([]);
    const [answer2, setAnswer2] = useState([]);
    const tt = useRef({});

    useEffect(() => {
        const a1 = [], a2 = [];
        let p = '', c = [];

        if (supervisor_answer !== '' && supervisor_answer !== "0000000000000000" && supervisor_answer !== undefined) {
            for(let i=0; i < String(supervisor_answer).length; i++) {
                a1.push(Number(String(supervisor_answer).substring(i, i+1)));
                if (i !== 0 && i % 4 === 0) {
                    if (p !== '') {
                        p = "M" + p; 
                        if (a1[i] !== 0) p += rotate2D (210 - (a1[i] * 30), 250, 250, 250, 0 + i*22.5);
                    } 
                    c.push(p);
                    p = '';
                }
                if (a1[i] !== 0) p +=  rotate2D (210 - (a1[i] * 30), 250, 250, 250, 0 + i*22.5);
                if (i === 15) {
                    if (p !== '') {
                        p = "M" + p; 
                        if (a1[0] !== 0) p += rotate2D (210 - (a1[0] * 30), 250, 250, 250, 0 + 0*22.5);
                    } 
                    c.push(p);
                }
            }
        }
        setPath1(c);
        setAnswer1(a1);

        if (personnel_answer !== '') {
            p = ''; c = [];
            for(let i=0; i < String(personnel_answer).length; i++) {
                a2.push(Number(String(personnel_answer).substring(i, i+1)));
                if (i !== 0 && i % 4 === 0) {
                    if (p !== '') {
                        p = "M" + p; 
                        if (a2[i] !== 0) p += rotate2D (210 - (a2[i] * 30), 250, 250, 250, 0 + i*22.5);
                    } 
                    c.push(p);
                    p = '';
                }
                if (a2[i] !== 0) p +=  rotate2D (210 - (a2[i] * 30), 250, 250, 250, 0 + i*22.5);
                if (i === 15) {
                    if (p !== '') {
                        p = "M" + p; 
                        if (a2[0] !== 0) p += rotate2D (210 - (a2[0] * 30), 250, 250, 250, 0 + 0*22.5);
                    } 
                    c.push(p);
                }
            }
        }
        setPath2(c);
        setAnswer2(a2);

    },[supervisor_answer, personnel_answer]);

    const rotate2D = (x0, y0, cx, cy, deg) => {
        const cos = Math.cos(deg * (Math.PI / 180));
        const sin = Math.sin(deg * (Math.PI / 180));
        let x =  cos * (x0 - cx) - sin * (y0 - cy) + cx;
        let y =  cos * (y0 - cy) + sin * (x0 - cx) + cy;
        return parseFloat(isNaN(x) ? 0 : Math.round((x + Number.EPSILON) * 1000) / 1000 ) + " " + parseFloat(isNaN(y) ? 0 : Math.round((y + Number.EPSILON) * 1000) / 1000) + " ";        
    }

    return (
        <svg width={width} height={height} className="block m-auto" viewBox="0 0 500 590" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <marker
                    id="arrow"
                    viewBox="0 0 10 10"
                    refX="5"
                    refY="5"
                    markerWidth="4"
                    markerHeight="4"
                    orient="auto-start-reverse"
                    fill="crimson">
                    <path d="M 0 0 L 10 5 L 0 10 z" />
                </marker>
            </defs>

            { questions !== [] && questions !== undefined && questions.length !== 0 && questions.map((q, index) => (
                <foreignObject x="1" y="-250" width="150" height="150" key={index}>
                    <div ref={(element) => tt.current[index] = element} className="border-[1px] bg-[#ffffca] text-xs p-1">
                        {q.staff_question}
                    </div>
                </foreignObject>    
            ))}

            <circle cx="250" cy="250" r="190" fill="#d7ecd0"/>
            <circle cx="250" cy="250" r="190" stroke="#cae1c2" strokeWidth="2"/>
            <circle cx="250" cy="250" r="160" stroke="#cae1c2" strokeWidth="2"/>
            <circle cx="250" cy="250" r="130" fill="#fcf6bc"/>
            <circle cx="250" cy="250" r="130" stroke="#f4ed9f" strokeWidth="2"/>
            <circle cx="250" cy="250" r="100" fill="#f7b59b"/>
            <circle cx="250" cy="250" r="100" stroke="#ed9883" strokeWidth="2"/>
            <circle cx="250" cy="250" r="70" stroke="#ed9883" strokeWidth="2"/>


            <rect x="30" y="500" width="42" height="22" fill="#f7b59b" stroke="#ed9883" />
            <text x="51" y="516" fill="black" textAnchor="middle" style={{font: "14px sans-serif"}}>1 - 2</text>
            <text x="85" y="516" fill="black" textAnchor="start" style={{font: "14px sans-serif"}}>Urgent call for action</text>

            <rect x="30" y="530" width="42" height="22" fill="#fcf6bc" stroke="#f4ed9f" />
            <text x="51" y="546" fill="black" textAnchor="middle" style={{font: "14px sans-serif"}}>3</text>
            <text x="85" y="546" fill="black" textAnchor="start" style={{font: "14px sans-serif"}}>Observer and act in time</text>

            <rect x="30" y="560" width="42" height="22" fill="#d7ecd0" stroke="#cae1c2" />
            <text x="51" y="576" fill="black" textAnchor="middle" style={{font: "14px sans-serif"}}>4 - 5</text>
            <text x="85" y="576" fill="black" textAnchor="start" style={{font: "14px sans-serif"}}>No action required</text>

            <circle cx="339" cy="510" r="6" fill="crimson" />
            <text x="373" y="516" fill="black" textAnchor="start" style={{font: "14px sans-serif"}}>Urgent calls</text>

            { answer1.length !== 0 &&
                <Fragment>
                    <line x1="323" y1="530" x2="355" y2="530" stroke="crimson" strokeWidth="3" markerStart="url(#arrow)" markerEnd="url(#arrow)" />
                    <text x="373" y="536" fill="black" textAnchor="start" style={{font: "14px sans-serif"}}>Deviation</text>
                </Fragment>
            }

            <line x1="318" y1="550" x2="360" y2="550" stroke="lightslategray" strokeWidth="3" />
            <text x="373" y="556" fill="black" textAnchor="start" style={{font: "14px sans-serif"}}>Employee</text>

            { answer1.length !== 0 && 
                <Fragment>
                    <line x1="318" y1="570" x2="360" y2="570" stroke="black" strokeWidth="3" strokeDasharray="4 4" />
                    <text x="373" y="576" fill="black" textAnchor="start" style={{font: "14px sans-serif"}}>Supervisor</text>
                </Fragment>
            }
            
            <path d="M250 60 V440 Z" stroke="#888e95" />
            <g transform="rotate(22.5 250 250)"><path d="M250 60 V440 Z" stroke="#888e95" /></g>
            <g transform="rotate(45 250 250)"><path d="M250 60 V440 Z" stroke="#888e95" /></g>
            <g transform="rotate(67.5 250 250)"><path d="M250 60 V440 Z" stroke="#888e95" /></g>
            <path d="M60 250 H440 Z" stroke="#888e95" />
            <g transform="rotate(22.5 250 250)"><path d="M60 250 H440 Z" stroke="#888e95" /></g>
            <g transform="rotate(45 250 250)"><path d="M60 250 H440 Z" stroke="#888e95" /></g>
            <g transform="rotate(67.5 250 250)"><path d="M60 250 H440 Z" stroke="#888e95" /></g>

            <circle cx="250" cy="250" r="40" fill="#ffffff" />
            <circle cx="250" cy="250" r="40" stroke="#0097ac" strokeWidth="2" />

            <g transform="translate(-67.4606 -17.332) translate(250 250)">
                <path d="M54.0706 12.5743 H65.0238 V7.47664 H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499 V22.5996 H53.8967 V34.664 H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967 V12.5743 H54.0706Z" fill="#1E2728" />
            </g>

            {path2.map((path, index) => (
                <path key={index} stroke="lightslategray" strokeWidth="3" d={path} />
            ))}

            {path1.map((path, index) => (
                <path key={index} stroke="black" strokeWidth="3" strokeDasharray="4 4" d={path} />
            ))}

            <circle cx="250" cy="60" r="10" fill="#d7ecd0" stroke="#cae1c2" strokeWidth="2" />
            <text x="250" y="66" fill="#888e95" textAnchor="middle">5</text>
            <circle cx="250" cy="90" r="10" fill="#d7ecd0" stroke="#cae1c2" strokeWidth="2" />
            <text x="250" y="96" fill="#888e95" textAnchor="middle">4</text>
            <circle cx="250" cy="120" r="10" fill="#fcf6bc" stroke="#f4ed9f" strokeWidth="2" />
            <text x="250" y="126" fill="#888e95" textAnchor="middle">3</text>
            <circle cx="250" cy="150" r="10" fill="#f7b59b" stroke="#ed9883" strokeWidth="2" />
            <text x="250" y="156" fill="#888e95" textAnchor="middle">2</text>
            <circle cx="250" cy="180" r="10" fill="#f7b59b" stroke="#ed9883" strokeWidth="2" />
            <text x="250" y="186" fill="#888e95" textAnchor="middle">1</text>
            
            {answer2.map((answer, index) => (
                <Fragment key={index}>{answer !== 0 && answer < 3 && <g transform={`rotate(${index * 22.5} 250 250)`}>
                    <circle cx={210 - (answer * 30)} cy="250" r="6" fill="crimson" />
                </g>}</Fragment>
            ))}

            {answer1.map((answer, index) => (
                (answer !== 0 && answer2[index] !== 0) &&
                <Fragment key={index}>{Math.abs(answer - answer2[index]) > 1 && <g transform={`rotate(${index * 22.5} 250 250)`}>
                    <line x1={answer > answer2[index]? 225 - (answer * 30) : 225 - (answer2[index] * 30)} y1="250" 
                        x2={answer < answer2[index]? 195 - (answer * 30) : 195 - (answer2[index] * 30)} y2="250" stroke="crimson" strokeWidth="3" 
                        markerStart="url(#arrow)" markerEnd="url(#arrow)" />
                </g>}</Fragment>
            ))}

            <text x="250" y="40" fill="black" textAnchor="middle">L1</text>
            <text x="250" y="472" fill="black" textAnchor="middle">C1</text>
            <g transform="rotate(22.5 250 250)">
                <g transform="rotate(-22.5 250 34)"><text x="250" y="40" fill="black" textAnchor="middle">L2</text></g>
                <g transform="rotate(-22.5 250 466)"><text x="250" y="472" fill="black" textAnchor="middle">C2</text></g>
            </g>
            <g transform="rotate(45 250 250)">
                <g transform="rotate(-45 250 34)"><text x="250" y="40" fill="black" textAnchor="middle">L3</text></g>
                <g transform="rotate(-45 250 466)"><text x="250" y="472" fill="black" textAnchor="middle">C3</text></g>
            </g>
            <g transform="rotate(67.5 250 250)">
                <g transform="rotate(-67.5 250 34)"><text x="250" y="40" fill="black" textAnchor="middle">L4</text></g>
                <g transform="rotate(-67.5 250 466)"><text x="250" y="472" fill="black" textAnchor="middle">C4</text></g>
            </g>
            <g transform="rotate(90 250 250)">
                <g transform="rotate(-90 250 34)"><text x="250" y="40" fill="black" textAnchor="middle">R1</text></g>
                <g transform="rotate(-90 250 466)"><text x="250" y="472" fill="black" textAnchor="middle">J1</text></g>
            </g>
            <g transform="rotate(112.5 250 250)">
                <g transform="rotate(-112.5 250 34)"><text x="250" y="40" fill="black" textAnchor="middle">R2</text></g>
                <g transform="rotate(-112.5 250 466)"><text x="250" y="472" fill="black" textAnchor="middle">J2</text></g>
            </g>
            <g transform="rotate(135 250 250)">
                <g transform="rotate(-135 250 34)"><text x="250" y="40" fill="black" textAnchor="middle">R3</text></g>
                <g transform="rotate(-135 250 466)"><text x="250" y="472" fill="black" textAnchor="middle">J3</text></g>
            </g>
            <g transform="rotate(157.5 250 250)">
                <g transform="rotate(-157.5 250 34)"><text x="250" y="40" fill="black" textAnchor="middle">R4</text></g>
                <g transform="rotate(-157.5 250 466)"><text x="250" y="472" fill="black" textAnchor="middle">J4</text></g>
            </g>
            { questions !== [] && questions !== undefined && questions.length !== 0 &&
                <g className="cursor-pointer">
                    <g className="group">
                        <rect x="240" y="24" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                        <line x1={240} y1={24+20} x2={240-19} y2={24+20+20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                        <foreignObject x={240-20} y={24+20+20} width="150" height={isNaN(tt?.current[4]?.clientHeight) ? 0 : tt?.current[4]?.clientHeight+2} className="hidden group-hover:block">
                            <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                {questions.find(q => q.question_no === "L1").staff_question}
                            </div>
                        </foreignObject>
                    </g>
                    <g className="group">
                        <rect x="240" y="456" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                        <line x1={240} y1={456} x2={240-20} y2={456-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                        <foreignObject x={240-20} y={isNaN(tt?.current[12]?.clientHeight) ? 0 : 456-20-tt?.current[12]?.clientHeight-1} width="150" height={isNaN(tt?.current[12]?.clientHeight) ? 0 : tt?.current[12]?.clientHeight+2} className="hidden group-hover:block">
                            <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                {questions.find(q => q.question_no === "C1").staff_question}
                            </div>
                        </foreignObject>
                    </g>
                    <g transform="rotate(22.5 250 250)">
                        <g transform="rotate(-22.5 250 34)">
                            <g className="group">
                                <rect x="240" y="24" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240} y1={24+20} x2={240-19} y2={24+20+20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={240-20} y={24+20+20} width="150" height={isNaN(tt?.current[5]?.clientHeight) ? 0 : tt?.current[5]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "L2").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                        <g transform="rotate(-22.5 250 466)">
                            <g className="group">
                                <rect x="240" y="456" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240} y1={456} x2={240-20} y2={456-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={240-20} y={isNaN(tt?.current[13]?.clientHeight) ? 0 : 456-20-tt?.current[13]?.clientHeight-1} width="150" height={isNaN(tt?.current[13]?.clientHeight) ? 0 : tt?.current[13]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "C2").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                    </g>
                    <g transform="rotate(45 250 250)">
                        <g transform="rotate(-45 250 34)">
                            <g className="group">
                                <rect x="240" y="24" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240+20} y1={24+20} x2={240+20+20} y2={24+20+20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={isNaN(tt?.current[6]?.clientWidth) ? 0 : 240+20+19-tt?.current[6]?.clientWidth} y={24+20+20} width="150" height={isNaN(tt?.current[6]?.clientHeight) ? 0 : tt?.current[6]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "L3").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                        <g transform="rotate(-45 250 466)">
                            <g className="group">
                                <rect x="240" y="456" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240} y1={456} x2={240-20} y2={456-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={240-20} y={isNaN(tt?.current[14]?.clientHeight) ? 0 : 456-20-tt?.current[14]?.clientHeight-1} width="150" height={isNaN(tt?.current[14]?.clientHeight) ? 0 : tt?.current[14]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "C3").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                    </g>
                    <g transform="rotate(67.5 250 250)">
                        <g transform="rotate(-67.5 250 34)">
                            <g className="group">
                                <rect x="240" y="24" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240} y1={24} x2={240-20} y2={24-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={isNaN(tt?.current[7]?.clientWidth) ? 0 : 240-21-tt?.current[7]?.clientWidth} y={24-20} width="150" height={isNaN(tt?.current[7]?.clientHeight) ? 0 : tt?.current[7]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "L4").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                        <g transform="rotate(-67.5 250 466)">
                            <g className="group">
                                <rect x="240" y="456" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240+20} y1={456} x2={240+20+20} y2={456-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={240+20+20} y={456-20} width="150" height={isNaN(tt?.current[15]?.clientHeight) ? 0 : tt?.current[15]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "C4").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                    </g>
                    <g transform="rotate(90 250 250)">
                        <g transform="rotate(-90 250 34)">
                            <g className="group">
                                <rect x="240" y="24" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240} y1={24} x2={240-20} y2={24-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={isNaN(tt?.current[8]?.clientWidth) ? 0 : 240-21-tt?.current[8]?.clientWidth} y={24-20} width="150" height={isNaN(tt?.current[8]?.clientHeight) ? 0 : tt?.current[8]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "R1").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                        <g transform="rotate(-90 250 466)">
                            <g className="group">
                                <rect x="240" y="456" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240+20} y1={456} x2={240+20+20} y2={456-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={240+20+20} y={456-20} width="150" height={isNaN(tt?.current[0]?.clientHeight) ? 0 : tt?.current[0]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "J1").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                    </g>
                    <g transform="rotate(112.5 250 250)">
                        <g transform="rotate(-112.5 250 34)">
                            <g className="group">
                                <rect x="240" y="24" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240} y1={24} x2={240-20} y2={24-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={isNaN(tt?.current[9]?.clientWidth) ? 0 : 240-21-tt?.current[9]?.clientWidth} y={24-20} width="150" height={isNaN(tt?.current[9]?.clientHeight) ? 0 : tt?.current[9]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "R2").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                        <g transform="rotate(-112.5 250 466)">
                            <g className="group">
                                <rect x="240" y="456" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240+20} y1={456} x2={240+20+20} y2={456-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={240+20+20} y={456-20} width="150" height={isNaN(tt?.current[1]?.clientHeight) ? 0 : tt?.current[1]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "J2").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                    </g>
                    <g transform="rotate(135 250 250)">
                        <g transform="rotate(-135 250 34)">
                            <g className="group">
                                <rect x="240" y="24" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240+20} y1={24} x2={240+20+20} y2={24-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={isNaN(tt?.current[10]?.clientWidth) ? 0 : 240+20+19-tt?.current[10]?.clientWidth} y={isNaN(tt?.current[10]?.clientHeight) ? 0 : 24-21-tt?.current[10]?.clientHeight} width="150" height={isNaN(tt?.current[10]?.clientHeight) ? 0 : tt?.current[10]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "R3").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                        <g transform="rotate(-135 250 466)">
                            <g className="group">
                                <rect x="240" y="456" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240+20} y1={456} x2={240+20+20} y2={456-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={240+20+20} y={456-20} width="150" height={isNaN(tt?.current[2]?.clientHeight) ? 0 : tt?.current[2]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "J3").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                    </g>
                    <g transform="rotate(157.5 250 250)">
                        <g transform="rotate(-157.5 250 34)">
                            <g className="group">
                                <rect x="240" y="24" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240+20} y1={24} x2={240+20+20} y2={24-20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={isNaN(tt?.current[11]?.clientWidth) ? 0 : 240+20+19-tt?.current[11]?.clientWidth} y={isNaN(tt?.current[11]?.clientHeight) ? 0 : 24-21-tt?.current[11]?.clientHeight} width="150" height={isNaN(tt?.current[11]?.clientHeight) ? 0 : tt?.current[11]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "R4").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                        <g transform="rotate(-157.5 250 466)">
                            <g className="group">
                                <rect x="240" y="456" width="20" height="20" fill="#ffffff" fillOpacity="0" />
                                <line x1={240} y1={456+20} x2={240-20} y2={456+20+20} stroke="lightslategray" strokeWidth="1" className="hidden group-hover:block" />
                                <foreignObject x={240-20} y={456+20+20} width="150" height={isNaN(tt?.current[3]?.clientHeight) ? 0 : tt?.current[3]?.clientHeight+2} className="hidden group-hover:block">
                                    <div className="border-[1px] border-[lightslategray] bg-[#ffffca] text-xs p-1">
                                        {questions.find(q => q.question_no === "J4").staff_question}
                                    </div>
                                </foreignObject>
                            </g>
                        </g>
                    </g>

                </g>
            }
        </svg>
    );
}

export default SpiderChart;
import { Fragment, useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { BsCardText } from 'react-icons/bs';
import SpiderChart from '../components/SpiderChart';
import TrendChart from '../components/TrendChart';
import Loading from "../Loading";
import api from "../Api";
import TrendArrow from '../components/TrendArrow';

function IndividualReport({ language, setNavbar, setActiveMenu, forceRefresh, setForceRefresh, currentRound, setCurrentRound, selectedforReport, currentQuestions }) {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPersonnel, setSelectedPersonnel] = useState([]); //useState({id: 0, full_name: '', key_status:'', supervisor_answer: '0000000000000000', personnel_answer: '0000000000000000', prev_supervisor_answer: '0000000000000000', prev_personnel_answer: '0000000000000000'});
//    const qno = ['J1', 'J2', 'J3', 'J4', 'L1', 'L2', 'L3', 'L4', 'R1', 'R2', 'R3', 'R4', 'C1', 'C2', 'C3', 'C4'];
    const [recTools, setRecTools] = useState([]);
    const [selTools, setSelTools] = useState([]); //useState({id: 0, personnel_id: 0, toolbox_id: 0, retention_individual: false, retention_department: false, retention_company: false, selected: false});
    const [tools, setTools] = useState([]);
    const [hrcomment, setHrcomment] = useState([]); //useState({personnel_id: 0, whole_hierarchy: '', direct_report: '', direct_report_key: '', direct_report_nonkey: '', individual: ''});
    const [saveStatus, setSaveStatus] = useState('');

    useEffect(() => {
        setActiveMenu(4);
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    useEffect(() => {
        const fetchSelTool = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/selected-tools/individual/${selectedPersonnel?.id}?key=${forceRefresh}`);
                setSelTools(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        
        fetchSelTool();
    },[selectedPersonnel, forceRefresh])
    
    useEffect(() => {
        const fetchComment = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/hrcomment/${selectedPersonnel?.id}?key=${forceRefresh}`);
                setHrcomment(response.data[0]);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        
        fetchComment();
    },[selectedPersonnel, forceRefresh])

    useEffect(() => {
        const fetchTool = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/recommended-tool/round/${selectedPersonnel?.round_id}/personnel?key=${forceRefresh}`);
                setRecTools(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchTool();
    },[selectedPersonnel, forceRefresh])

    useEffect(() => {
        const answer = (a, qno) => {
            let pos = 0
            switch (qno.substring(0,1)) {
                case 'J' : pos = 0 + Number(qno.substring(1)) - 1; break;
                case 'L' : pos = 4 + Number(qno.substring(1)) - 1; break;
                case 'R' : pos = 8 + Number(qno.substring(1)) - 1; break;
                case 'C' : pos = 12 + Number(qno.substring(1)) - 1; break;
                default: 
            }
            return Number(a?.substring(pos, pos + 1));
        }

        setTools(recTools.filter((tool) => {
                if (answer(selectedPersonnel.personnel_answer, tool.question_no) < 3) return true; 
                return false;
            }).filter((item, pos, self) => {
                return self.map(tool => tool.question_no.substring(0,1) + '-' + tool.id).indexOf(item.question_no.substring(0,1) + '-' + item.id) === pos;
            }).map(tool => ({...tool, selected: selTools.find(stool => stool.toolbox_id === tool.id && (stool.selected === true || stool.selected === 1 )) === undefined ? false : true}))
        );
    }, [recTools, selTools, selectedPersonnel])

    useEffect(() => {
        setSelectedPersonnel(selectedforReport?.find(({selected}) => selected === true));
        setIsLoading(false);
    },[selectedforReport]) 

    const handlePersonnel = (e) => {        
        const personnelId = e.target.options[e.target.options.selectedIndex].getAttribute('data-key');
//        if (personnelId !== '0')
            setSelectedPersonnel(selectedforReport.find(({ id }) => id === Number(personnelId)));
//        else
//            setSelectedPersonnel({id: 0, full_name: '', key_status:'', supervisor_answer: '0000000000000000', personnel_answer: '0000000000000000', prev_supervisor_answer: '0000000000000000', prev_personnel_answer: '0000000000000000'});
        setForceRefresh(crypto.randomUUID());
    }

    const handleCheck = (e) => {
        if (!selTools.find(stool => stool.toolbox_id === Number(e.target.getAttribute('data-key'))))
            selTools.push({id: 0, personnel_id:selectedPersonnel?.id, toolbox_id: Number(e.target.getAttribute('data-key')), retention_individual: 1, retention_department: 0, retention_company: 0, selected: 0});
        setSelTools(selTools.map(item => item.toolbox_id === Number(e.target.getAttribute('data-key')) ? { ...item, selected: e.target.checked} : item));
    }

/*
    const checkSel = (id) => {
        console.log('checkSel>>', selTools);
        let check = selTools.find(stool => stool.toolbox_id === id && (stool.selected === true || stool.selected === 1 ));
        let result = true;
        if (check === undefined) result = false;
        return result;
    }
*/

    const postReport = async (mode) => {
        try {
            setIsLoading(true);
            const response = await api.post(`/report/individual/${selectedPersonnel.id}`, {selected_tools: selTools.filter(tool => tool.selected !== false), hr_comment: hrcomment === undefined ? { ...hrcomment, individual: '', individual_status_id: mode === 1 ? 11 : 12} : { ...hrcomment, individual_status_id: mode === 1 ? 11 : 12}});
            console.log(response);
            setForceRefresh(crypto.randomUUID());
            setIsLoading(false);
            setSaveStatus(mode === 1 ? " - Saved" : " - Completed");
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSave = (mode) => {
        postReport(mode);
    }
/*
    const currUrgentcallCount = (personnel_answer = '0000000000000000') => {
        let count = 0;
        for(let i = 0; i < 16; i ++) {
            let a = Number(String(personnel_answer).substring(i, i+1)); 
            if ( a > 0 && a < 3) count++;
        }
        return (count);
    } 

    const currDeviationCount = (personnel_answer = '0000000000000000', supervisor_answer = '0000000000000000') => {
        let count = 0;
        for(let i = 0; i < 16; i ++) {
            let pa = Number(String(personnel_answer).substring(i, i+1));
            let sa = Number(String(supervisor_answer).substring(i, i+1));
            if ( Math.abs( pa - sa ) > 1 && sa !== 0 && pa !== 0 ) count++;
        }
        return (count);
    }

    const currUrgentcall = (personnel_answer = '0000000000000000') => {
        let urgentCall = '';
        for(let i = 0; i < 16; i ++) {
            let a = Number(String(personnel_answer).substring(i, i+1)); 
            if ( a > 0 && a < 3) urgentCall+= urgentCall === '' ? qno[i] : ', ' + qno[i];
        }
        return (urgentCall);
    } 

    const currDeviation = (personnel_answer = '0000000000000000', supervisor_answer = '0000000000000000') => {
        let deviation = '';
        for(let i = 0; i < 16; i ++) {
            let pa = Number(String(personnel_answer).substring(i, i+1));
            let sa = Number(String(supervisor_answer).substring(i, i+1));
            if ( Math.abs( pa - sa ) > 1 && sa !== 0 && pa !== 0 ) deviation+= deviation === '' ? qno[i] : ', ' + qno[i];
        }
        return (deviation);
    }
*/
    const trendByCategory = (item, category, supervisor = false) => {
        let total = 0;
        for(let i=0; i < 4; i++) {
            if (supervisor) {
                total+= Number(String(item?.supervisor_answer).substring((category * 4)+i, (category * 4) +i+1));
            } else {
                total+= Number(String(item?.personnel_answer).substring((category * 4)+i, (category * 4) +i+1));
            }
        }
        let trend = Math.round(((total / 4) + Number.EPSILON) * 10) / 10;
        return isNaN(trend) ? 0 : trend;
    }

    return (
        <div className="w-11/12 py-3">
            <div className="flex flex-row">
                <h1 className="text-2xl font-medium text-primary-black">Individual Profile: </h1>
                <select id="personnel" className="text-2xl font-medium ml-3 border rounded-sm disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={selectedPersonnel?.full_name}
                    onChange={(e) => handlePersonnel(e)} >
                    <option data-key='0' value=''></option>
                    {selectedforReport.filter((personnel) => personnel.selected === true).map((personnel, index) => (
                        <option key={index} data-key={personnel.id} value={personnel.full_name}>{personnel.full_name} ({personnel.key_status})</option>
                    ))}
                </select>
                <span className="ml-2 text-2xl font-light">{`- Supervisor: ${selectedPersonnel?.name_of_manager}`}</span>
                <span className="ml-2 text-2xl font-medium text-green-500">{saveStatus}</span>
            </div>
            <div className="flex flex-row text-primary-black py-3">
                <button className="bg-primary-green text-primary-white text-lg font-medium px-10 py-1 rounded-lg mr-2" onClick={() => handleSave(1)}>Save</button>
                <button className="bg-primary-green text-primary-white text-lg font-medium px-10 py-1 rounded-lg mr-8" onClick={() => handleSave(2)}>Save & Mark as Complete</button>
            </div>
            
            
            <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">REMS Profile</div>
            <div className="w-full flex py-7">
                <div className="w-5/12 flex items-center justify-center px-9">
                    <SpiderChart width="640px" supervisor_answer={selectedPersonnel?.supervisor_answer} personnel_answer={selectedPersonnel?.personnel_answer} questions={currentQuestions} />
                </div>

                <div className="w-7/12 pt-9">
                    <table className="w-full">
                        <tbody>
                            <tr className="text-lg text-primary-white">
                                <th className="border-x-2 border-primary-dark-green bg-primary-dark-green align-top text-left px-4" rowSpan="2">Category</th>
                                <th className={`border-x-2 border-primary-dark-green bg-primary-dark-green w-2/12${selectedPersonnel?.key_status === "Key" ? "" : " text-left pl-4"}`} colSpan={selectedPersonnel?.key_status === "Key" ? "2" : "1"}>Trend</th>
                                <th className={`border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left${selectedPersonnel?.key_status === "Key" ? " w-1/4" : " w-1/3" }`}>Urgent Calls</th>
                                { selectedPersonnel?.key_status === "Key" &&
                                    <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left w-1/4">Deviations</th>
                                }
                                <th className={`border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4${selectedPersonnel?.key_status === "Key" ? " w-1/4" : " w-1/3" }`}>Proposed</th>
                            </tr>

                            <tr className="text-primary-white">
                                <th className={`border-x-2 border-primary-dark-green bg-primary-dark-green ${selectedPersonnel?.key_status === "Key" ? "text-sm" : "text-lg pl-4 text-left"}`}>
                                    Employee
                                </th>
                                { selectedPersonnel?.key_status === "Key" &&
                                    <Fragment>
                                        <th className="text-sm border-x-2 border-primary-dark-green bg-primary-dark-green">
                                            Supervisor
                                        </th>
                                        <th className="text-sm border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4">
                                            *Also, in previous round
                                        </th>
                                    </Fragment>
                                }
                                <th className="text-sm border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4">
                                    *Also, in previous round
                                </th>
                                <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left text-lg pl-4">Tools</th>
                            </tr>

                            <tr>
                                <td className="px-4 border-t-2 border-x-2 border-primary-dark-green">JOB</td>

                                <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                    <div className="flex items-center justify-center px-2">
                                        <TrendArrow trend={trendByCategory(selectedPersonnel, 0)} height="60px" width="60px" />
                                    </div>
                                </td>

                                { selectedPersonnel?.key_status === "Key" &&
                                    <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                        <div className="flex items-center justify-center px-2">
                                            <TrendArrow trend={trendByCategory(selectedPersonnel, 0, true)} height="60px" width="60px" />
                                        </div>
                                    </td>
                                }

                                <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                    { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                        <Fragment key={index}>
                                            { Number(selectedPersonnel?.personnel_answer?.substring(index, index + 1)) < 3 &&
                                                <div className="flex flex-row"><div className="font-bold">{question.question_no + '.'}</div><div className="ml-1">{question.title}{ selectedPersonnel?.prev_personnel_answer !== null && Number(selectedPersonnel?.prev_personnel_answer?.substring(index, index + 1)) < 3 && <span>*</span>}</div></div>
                                            }
                                        </Fragment>
                                    ))}
                                </td>

                                { selectedPersonnel?.key_status === "Key" &&
                                    <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                        { selectedPersonnel?.key_status === 'Key' && currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                            <Fragment key={index}>
                                                { Math.abs(Number(selectedPersonnel?.supervisor_answer?.substring(index, index + 1)) - 
                                                    Number(selectedPersonnel?.personnel_answer.substring(index, index + 1))) > 1 &&
                                                    <div className="flex flex-row"><div className="font-bold">{question.question_no + '.'}</div><div className="ml-1">{question.title}{ selectedPersonnel?.prev_personnel_answer !== null && selectedPersonnel?.prev_supervisor_answer != null &&
                                                    Math.abs(Number(selectedPersonnel?.prev_supervisor_answer?.substring(index, index + 1)) -
                                                    Number(selectedPersonnel?.prev_personnel_answer?.substring(index, index + 1))) > 1 && <span>*</span>}</div></div>
                                                }
                                            </Fragment>
                                        ))}
                                    </td>
                                }

                                <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                    { tools.filter(tool => String(tool.question_no).substring(0,1) === "J").map((tool, index) => (
                                        <div key={index} className="flex flex-row">
                                            <div className="mt-[2px]"><input data-key={tool.id} id={"toolsjob" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                            <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/individual/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                        </div>
                                    ))}
{/*
                                    { recTools.filter(tool => String(tool.question_no).substring(0,1) === "J").filter((tool) => {
                                        if (Number(selectedPersonnel?.personnel_answer?.substring(Number(String(tool.question_no).substring(1)) - 1, Number(String(tool.question_no).substring(1)))) < 3) return true;
                                        return false;
                                    }).filter((item, pos, self) => {
                                        return self.map(tool => tool.id).indexOf(item.id) === pos;
                                    }).map((tool, index) => (
                                        <div key={index} className="flex flex-row">
                                            <div className="mt-[2px]"><input data-key={tool?.id} id={"toolsjob" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={selTools.find(stool => stool.toolbox_id === tool.id && (stool.selected === true || stool.selected === 1 )) === undefined ? false : true} onChange={(e) => handleCheck(e)} /></div>
                                            <div className="mx-2"><label htmlFor={"toolsjob" + index}>{tool?.title}</label></div>
                                        </div>
                                    ))}
*/}
                                </td>                                
                            </tr>

                            <tr>
                                <td className="px-4 border-t-2 border-x-2 border-primary-dark-green">LEADER</td>

                                <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                    <div className="flex items-center justify-center px-2">
                                        <TrendArrow trend={trendByCategory(selectedPersonnel, 1)} height="60px" width="60px" />
                                    </div>
                                </td>
                                
                                { selectedPersonnel?.key_status === "Key" &&
                                    <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                        <div className="flex items-center justify-center px-2">
                                            <TrendArrow trend={trendByCategory(selectedPersonnel, 1, true)} height="60px" width="60px" />
                                        </div>
                                    </td>
                                }

                                <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                    { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                        <Fragment key={index}>
                                            { Number(selectedPersonnel?.personnel_answer?.substring(4 + index, 4 + index + 1)) < 3 &&
                                                <div className="flex flex-row"><div className="font-bold">{question.question_no + '.'}</div><div className="ml-1">{question.title}{ selectedPersonnel?.prev_personnel_answer !== null && Number(selectedPersonnel?.prev_personnel_answer?.substring(4 + index, 4 + index + 1)) < 3 && <span>*</span>}</div></div>
                                            }
                                        </Fragment>
                                    ))}
                                </td>

                                { selectedPersonnel?.key_status === "Key" &&
                                    <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                        { selectedPersonnel?.key_status === 'Key' && currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                            <Fragment key={index}>
                                                { Math.abs(Number(selectedPersonnel?.supervisor_answer?.substring(4 + index, 4 + index + 1)) -
                                                    Number(selectedPersonnel?.personnel_answer?.substring(4 + index, 4 + index + 1))) > 1 &&
                                                    <div className="flex flex-row"><div className="font-bold">{question.question_no + '.'}</div><div className="ml-1">{question.title}{ selectedPersonnel?.prev_personnel_answer !== null && selectedPersonnel?.prev_supervisor_answer != null &&
                                                    Math.abs(Number(selectedPersonnel?.prev_supervisor_answer?.substring(4 + index, 4 + index + 1)) -
                                                    Number(selectedPersonnel?.prev_personnel_answer?.substring(4 + index, 4 + index + 1))) > 1 && <span>*</span>}</div></div>
                                                }
                                            </Fragment>
                                        ))}
                                    </td>
                                }

                                <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                    { tools.filter(tool => String(tool.question_no).substring(0,1) === "L").map((tool, index) => (
                                        <div key={index} className="flex flex-row">
                                            <div className="mt-[2px]"><input data-key={tool.id} id={"toolsleader" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                            <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/individual/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                        </div>
                                    ))}
{/*
                                    { recTools.filter(tool => String(tool.question_no).substring(0,1) === "L").filter((tool) => {
                                        if (Number(selectedPersonnel?.personnel_answer.substring(4 + Number(String(tool.question_no).substring(1)) - 1, 4 + Number(String(tool.question_no).substring(1)))) < 3) return true;
                                        return false;
                                    }).filter((item, pos, self) => {
                                        return self.map(tool => tool.id).indexOf(item.id) === pos;
                                    }).map((tool, index) => (
                                        <div key={index} className="flex flex-row">
                                            <div className="mt-[2px]"><input data-key={tool?.id} id={"toolsleader" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSel(tool.id)} onChange={(e) => handleCheck(e)} /></div>
                                            <div className="mx-2"><label htmlFor={"toolsleader" + index}>{tool?.title}</label></div>
                                        </div>
                                    ))}
*/}
                                </td>
                            </tr>

                            <tr>
                                <td className="px-4 border-t-2 border-x-2 border-primary-dark-green">REWARDS</td>

                                <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                    <div className="flex items-center justify-center px-2">
                                        <TrendArrow trend={trendByCategory(selectedPersonnel, 2)} height="60px" width="60px" />
                                    </div>
                                </td>

                                { selectedPersonnel?.key_status === "Key" &&
                                    <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                        <div className="flex items-center justify-center px-2">
                                            <TrendArrow trend={trendByCategory(selectedPersonnel, 2, true)} height="60px" width="60px" />
                                        </div>
                                    </td>
                                }

                                <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                    { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                        <Fragment key={index}>
                                            { Number(selectedPersonnel?.personnel_answer?.substring(8 + index, 8 + index + 1)) < 3 &&
                                                <div className="flex flex-row"><div className="font-bold">{question.question_no + '.'}</div><div className="ml-1">{question.title}{ selectedPersonnel?.prev_personnel_answer !== null && Number(selectedPersonnel?.prev_personnel_answer?.substring(8 + index, 8 + index + 1)) < 3 && <span>*</span>}</div></div>
                                            }
                                        </Fragment>
                                    ))}
                                </td>

                                { selectedPersonnel?.key_status === "Key" &&
                                    <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                        { selectedPersonnel?.key_status === 'Key' && currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                            <Fragment key={index}>
                                                { Math.abs(Number(selectedPersonnel?.supervisor_answer?.substring(8 + index, 8 + index + 1)) -
                                                    Number(selectedPersonnel?.personnel_answer?.substring(8 + index, 8 + index + 1))) > 1 &&
                                                    <div className="flex flex-row"><div className="font-bold">{question.question_no + '.'}</div><div className="ml-1">{question.title}{ selectedPersonnel?.prev_personnel_answer !== null && selectedPersonnel?.prev_supervisor_answer != null &&
                                                    Math.abs(Number(selectedPersonnel?.prev_supervisor_answer?.substring(8 + index, 8 + index + 1)) -
                                                    Number(selectedPersonnel?.prev_personnel_answer?.substring(8 + index, 8 + index + 1))) > 1 && <span>*</span>}</div></div>
                                                }
                                            </Fragment>
                                        ))}
                                    </td>
                                }

                                <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                    { tools.filter(tool => String(tool.question_no).substring(0,1) === "R").map((tool, index) => (
                                        <div key={index} className="flex flex-row">
                                            <div className="mt-[2px]"><input data-key={tool.id} id={"toolsreward" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                            <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/individual/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                        </div>
                                    ))}
{/*
                                    { recTools.filter(tool => String(tool.question_no).substring(0,1) === "R").filter((tool) => {
                                        if (Number(selectedPersonnel?.personnel_answer?.substring(8 + Number(String(tool.question_no).substring(1)) - 1, 8 + Number(String(tool.question_no).substring(1)))) < 3) return true;
                                        return false;
                                    }).filter((item, pos, self) => {
                                        return self.map(tool => tool.id).indexOf(item.id) === pos;
                                    }).map((tool, index) => (
                                        <div key={index} className="flex flex-row">
                                            <div className="mt-[2px]"><input data-key={tool?.id} id={"toolsreward" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSel(tool.id)} onChange={(e) => handleCheck(e)} /></div>
                                            <div className="mx-2"><label htmlFor={"toolsreward" + index}>{tool?.title}</label></div>
                                        </div>
                                    ))}
*/}
                                </td>
                            </tr>

                            <tr>
                                <td className="px-4 border-2 border-primary-dark-green">COMPANY</td>

                                <td className="py-4 border-2 border-primary-dark-green">
                                    <div className="flex items-center justify-center px-2">
                                        <TrendArrow trend={trendByCategory(selectedPersonnel, 3)} height="60px" width="60px" />
                                    </div>
                                </td>

                                { selectedPersonnel?.key_status === "Key" &&
                                    <td className="py-4 border-2 border-primary-dark-green">
                                        <div className="flex items-center justify-center px-2">
                                            <TrendArrow trend={trendByCategory(selectedPersonnel, 3, true)} height="60px" width="60px" />
                                        </div>
                                    </td>
                                }

                                <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                    { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                        <Fragment key={index}>
                                            { Number(selectedPersonnel?.personnel_answer?.substring(12 + index, 12 + index + 1)) < 3 &&
                                                <div className="flex flex-row"><div className="font-bold">{question.question_no + '.'}</div><div className="ml-1">{question.title}{ selectedPersonnel?.prev_personnel_answer !== null && Number(selectedPersonnel?.prev_personnel_answer?.substring(12 + index, 12 + index + 1)) < 3 && <span>*</span>}</div></div>
                                            }
                                        </Fragment>
                                    ))}
                                </td>

                                { selectedPersonnel?.key_status === "Key" &&
                                    <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                        { selectedPersonnel?.key_status === 'Key' && currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                            <Fragment key={index}>
                                                { Math.abs(Number(selectedPersonnel?.supervisor_answer?.substring(12 + index, 12 + index + 1)) -
                                                    Number(selectedPersonnel?.personnel_answer?.substring(12 + index, 12 + index + 1))) > 1 &&
                                                    <div className="flex flex-row"><div className="font-bold">{question.question_no + '.'}</div><div className="ml-1">{question.title}{ selectedPersonnel?.prev_personnel_answer !== null && selectedPersonnel?.prev_supervisor_answer != null &&
                                                    Math.abs(Number(selectedPersonnel?.prev_supervisor_answer.substring(12 + index, 12 + index + 1)) -
                                                    Number(selectedPersonnel?.prev_personnel_answer.substring(12 + index, 12 + index + 1))) > 1 && <span>*</span>}</div></div>
                                                }
                                            </Fragment>
                                        ))}
                                    </td>
                                }

                                <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                    { tools.filter(tool => String(tool.question_no).substring(0,1) === "C").map((tool, index) => (
                                        <div key={index} className="flex flex-row">
                                            <div className="mt-[2px]"><input data-key={tool.id} id={"toolscompany" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                            <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/individual/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                        </div>
                                    ))}
{/*
                                    { recTools.filter(tool => String(tool.question_no).substring(0,1) === "C").filter((tool) => {
                                        if (Number(selectedPersonnel?.personnel_answer.substring(12 + Number(String(tool.question_no).substring(1)) - 1, 12 + Number(String(tool.question_no).substring(1)))) < 3) return true;
                                        return false;
                                    }).filter((item, pos, self) => {
                                        return self.map(tool => tool.id).indexOf(item.id) === pos;
                                    }).map((tool, index) => (
                                        <div key={index} className="flex flex-row">
                                            <div className="mt-[2px]"><input data-key={tool?.id} id={"toolscompany" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSel(tool.id)} onChange={(e) => handleCheck(e)} /></div>
                                            <div className="mx-2"><label htmlFor={"toolscompany" + index}>{tool?.title}</label></div>
                                        </div>
                                    ))}
*/}
                                </td>
                            </tr>             
                        </tbody>
                    </table>
                </div>
            </div>
{/*
            <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                Results
            </div>
            <div className="flex flex-row mt-3">
                <div>
                    <SpiderChart width="520px" supervisor_answer={selectedPersonnel.supervisor_answer} personnel_answer={selectedPersonnel.personnel_answer} />
                </div>
                <table className="text-primary-black border-primary-grey text-base text-left w-full">
                    <tbody>
                        <tr>
                            <th className="w-2/12" />
                            <th className="w-3/12 text-center" />
                            <th className="border-x-2 w-3/12 text-center" />
                            <th className="w-4/12 px-2 text-center" colSpan="2">Trend Chart</th>
                        </tr>
                        <tr>
                            <th className="h-10" />
                            <th className="" />
                            <th className="border-x-2" />
                            <th className="text-center">Employee</th>
                            <th className="text-center">Supervisor</th>
                        </tr>
                        <tr>
                            <td />
                            <td className="border-y-2 pl-5">Current Round:<br />{selectedPersonnel.round_name}</td>
                            <td className="border-2 px-4">
                                { currUrgentcallCount(selectedPersonnel.personnel_answer) !== 0 &&
                                    <Fragment>
                                        <span className="text-primary-red">{currUrgentcallCount(selectedPersonnel.personnel_answer)}</span> Urgent Calls:<br />
                                        {currUrgentcall(selectedPersonnel.personnel_answer)}<br />
                                        <br />
                                    </Fragment>
                                }
                                { currDeviationCount(selectedPersonnel.personnel_answer, selectedPersonnel.supervisor_answer) !== 0 &&
                                    <Fragment>
                                        <span className="text-primary-red">{currDeviationCount(selectedPersonnel.personnel_answer, selectedPersonnel.supervisor_answer)}</span> Deviations:<br />
                                        {currDeviation(selectedPersonnel.personnel_answer, selectedPersonnel.supervisor_answer)}
                                    </Fragment>
                                }
                            </td>
                            <td className="border-y-2">
                                <TrendChart width="320px" height="320px" answer={selectedPersonnel.personnel_answer} />
                            </td>
                            <td className="border-y-2">
                                <TrendChart width="320px" height="320px" answer={selectedPersonnel.supervisor_answer} />
                            </td>
                        </tr>
                        <tr>
                            <td />
                            <td className="pl-5">Previous Round:<br />{selectedPersonnel.prev_round_name}</td>
                            <td className="border-x-2 px-4">
                                { currUrgentcallCount(selectedPersonnel.prev_personnel_answer) !== 0 &&
                                    <Fragment>
                                        <span className="text-primary-red">{currUrgentcallCount(selectedPersonnel.prev_personnel_answer)}</span> Urgent Calls:<br />
                                        {currUrgentcall(selectedPersonnel.prev_personnel_answer)}<br />
                                        <br />
                                    </Fragment>
                                }
                                { currDeviationCount(selectedPersonnel.prev_personnel_answer, selectedPersonnel.prev_supervisor_answer) !== 0 &&
                                    <Fragment>
                                        <span className="text-primary-red">{currDeviationCount(selectedPersonnel.prev_personnel_answer, selectedPersonnel.prev_supervisor_answer)}</span> Deviations:<br />
                                        {currDeviation(selectedPersonnel.prev_personnel_answer, selectedPersonnel.prev_supervisor_answer)}
                                    </Fragment>
                                }
                            </td>
                            <td>
                                <TrendChart width="220px" height="220px" answer={selectedPersonnel.prev_personnel_answer} />
                            </td>
                            <td>
                                <TrendChart width="220px" height="220px" answer={selectedPersonnel.prev_supervisor_answer} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
*/}


            <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                Trend Chart
            </div>
            <div className="w-full py-7 flex flex-row items-center justify-center">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-[74px]"></td>
                        </tr>
                        <tr>
                            <td className="h-[304px] max-w-[80px]">
                                <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                    <div>Current round:</div>
                                    <div>{selectedPersonnel?.round_name}</div>
                                </div>
                            </td>
                        </tr>
                        { selectedPersonnel?.prev_round_name !== null && selectedPersonnel?.prev_personnel_answer && selectedPersonnel?.prev_supervisor_answer &&
                            <tr>
                                <td className="h-[304px] max-w-[80px]">
                                    <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                        <div>Previous round:</div>
                                        <div>{selectedPersonnel?.prev_round_name}</div>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                <table className="mr-2">
                    <tbody>
                        <tr>
                            <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center" colSpan={selectedPersonnel?.key_status === "Key" ? "2" : "1"}>
                                <div>Trend</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                <div>{selectedPersonnel?.full_name}</div>
                            </td>
                            { selectedPersonnel?.key_status === "Key" &&
                                <td className="bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                    <div>{selectedPersonnel?.name_of_manager}</div>
                                </td>
                            }
                        </tr>
                        <tr>
                            <td className="h-[300px] border-primary-dark-green border-2 p-2">
                                <TrendChart width="300px" height="300px" answer={selectedPersonnel?.personnel_answer} />
                            </td>
                            { selectedPersonnel?.key_status === "Key" &&
                                <td className="h-[300px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                    <TrendChart width="300px" height="300px" answer={selectedPersonnel?.supervisor_answer}/>
                                </td>
                            }
                        </tr>
                        { selectedPersonnel?.prev_round_name !== null && selectedPersonnel?.prev_personnel_answer && selectedPersonnel?.prev_supervisor_answer &&
                            <tr>
                                <td className="h-[300px] border-primary-dark-green border-2 p-2">
                                    <TrendChart width="300px" height="300px" answer={selectedPersonnel?.prev_personnel_answer}/>
                                </td>
                                { selectedPersonnel?.key_status === "Key" &&
                                    <td className="h-[300px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                        <TrendChart width="300px" height="300px" answer={selectedPersonnel?.prev_supervisor_answer}/>
                                    </td>
                                }
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                Open Feedback of Employee
            </div>
            <div className="mt-3">
                <table className="w-full">
                    <tbody>
                        <tr>
                            <td className="w-1/2">
                                <div className="border-2 border-primary-grey m-4 ml-0">
                                    <div className="flex flex-row">
                                        <div className="flex flex-col items-center mx-6 my-10 w-[110px] min-w-[110px]">
                                            <span className="text-primary-green font-medium text-2xl">Job</span>
                                            <img className="w-[80px] h-[80px]" src={require('../assets/cjob.png')} alt="" />
                                        </div>
                                        <div className="text-base text-primary-black mr-6 my-16">
                                            {selectedPersonnel?.feedback1}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-1/2">
                                <div className="border-2 border-primary-grey m-4 mr-0">
                                    <div className="flex flex-row">
                                        <div className="flex flex-col items-center mx-6 my-10 w-[110px] min-w-[110px]">
                                            <span className="text-primary-green font-medium text-2xl">Leader</span>
                                            <img className="w-[80px] h-[80px]" src={require('../assets/cleader.png')} alt="" />
                                        </div>
                                        <div className="text-base text-primary-black mr-6 my-16">
                                            {selectedPersonnel?.feedback2}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="w-1/2">
                                <div className="border-2 border-primary-grey m-4 ml-0">
                                    <div className="flex flex-row">
                                        <div className="flex flex-col items-center mx-6 my-10 w-[110px] min-w-[110px]">
                                            <span className="text-primary-green font-medium text-2xl">Company</span>
                                            <img className="w-[80px] h-[80px]" src={require('../assets/ccompany.png')} alt="" />
                                        </div>
                                        <div className="text-base text-primary-black mr-6 my-16">
                                            {selectedPersonnel?.feedback4}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-1/2">
                                <div className="border-2 border-primary-grey m-4 mr-0">
                                    <div className="flex flex-row">
                                        <div className="flex flex-col items-center mx-6 my-10 w-[110px] min-w-[110px]">
                                            <span className="text-primary-green font-medium text-2xl">Rewards</span>
                                            <img className="w-[80px] h-[80px]" src={require('../assets/crewards.png')} alt="" />
                                        </div>
                                        <div className="text-base text-primary-black mr-6 my-16">
                                            {selectedPersonnel?.feedback3}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
{/*
            <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                Proposed Tools
            </div>
            <div className="mt-3 mb-12">
                <div className="border-2 border-primary-grey m-4">
                    <table className="text-primary-black border-primary-grey text-base text-left w-full">
                        <tbody>
                            <tr>
                                <td className="border-b-2 border-r-2 pl-8 py-5 w-1/5">JOB</td>
                                <td className="border-b-2 pl-8 py-5 w-4/5">
                                    {tools?.filter(tool => tool.question_category === 'J' && tool.retention_individual === 1).map((tool,index) => 
                                        <Fragment key={index}>{tool.title}<br /></Fragment>)}
                                </td>
                            </tr>
                            <tr>
                                <td className="border-b-2 border-r-2 pl-8 py-5">LEADER</td>
                                <td className="border-b-2 pl-8 py-5 w-4/5">
                                    {tools?.filter(tool => tool.question_category === 'L' && tool.retention_individual === 1).map((tool,index) => 
                                        <Fragment key={index}>{tool.title}<br /></Fragment>)}
                                </td>
                            </tr>
                            <tr>
                                <td className="border-b-2 border-r-2 pl-8 py-5">REWARD</td>
                                <td className="border-b-2 pl-8 py-5 w-4/5">
                                    {tools?.filter(tool => tool.question_category === 'R' && tool.retention_individual === 1).map((tool,index) => 
                                        <Fragment key={index}>{tool.title}<br /></Fragment>)}
                                </td>
                            </tr>
                            <tr>
                                <td className="border-r-2 pl-8 py-5">COMPANY</td>
                                <td className="pl-8 py-5 w-4/5">
                                    {tools?.filter(tool => tool.question_category === 'C' && tool.retention_individual === 1).map((tool,index) => 
                                        <Fragment key={index}>{tool.title}<br /></Fragment>)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
*/}
            <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                HR's comment
            </div>
            <div className="mt-3 mb-12">
                <div className="border-2 border-primary-grey my-4">
                    <textarea id="comment" rows="8" className="p-1 w-full" placeholder="Fluctuation rate:" value={hrcomment?.individual} onChange={e => hrcomment?.id === undefined ?  setHrcomment({ ...hrcomment, id: 0, personnel_id: selectedPersonnel?.id, individual: e.target.value }) : setHrcomment({ ...hrcomment, individual: e.target.value })} />
                </div>
            </div>
            <Outlet />
            {isLoading && <Loading /> }
        </div>
    );
}

export default IndividualReport;
import { useEffect, useState } from 'react';
import api from '../Api';
import { Link, Outlet } from 'react-router-dom';
import Loading from '../Loading';
import { MultiSelect } from "react-multi-select-component";

function Question({ language, setNavbar, setActiveMenu, forceRefresh, currentQTemplate, setCurrentQTemplate}) {
    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [questions, setQuestions] = useState([]);

    const [work_contract_group, setWork_contract_group] = useState([]);
    const [question_group, setQuestion_group] = useState([]);

    const question_group_options = [
        { label: "Job", value: "J" },
        { label: "Leader", value: "L" },
        { label: "Reward", value: "R" },
        { label: "Company", value: "C" },
      ];

    useEffect(() => {
        setActiveMenu(6)
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    useEffect(() => {
        const fetchRounds = async () => {
            try {
                setIsLoading1(true);
                const response = await api.get(`/question-template?key=${forceRefresh}`);
                setTemplates(response.data);
                setIsLoading1(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchRounds();
    },[forceRefresh]);


    useEffect(() => {
        const fetchToolboxes = async () => {
            try {
                const response = await api.get(`/question/template/${currentQTemplate.id}?key=${forceRefresh}`);
                setQuestions(response.data);
                setIsLoading2(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        fetchToolboxes();
    },[currentQTemplate, forceRefresh]);

    const handleTemplate = (e) => {
        const templateId = e.target.options[e.target.options.selectedIndex].getAttribute('data-key');
        if (templateId !== '0')
            setCurrentQTemplate(templates.find(({ id }) => id === Number(templateId)));
        else
            setCurrentQTemplate({id: 0, name: ''});
    }

    const filterLogic = (question) => {
        if (work_contract_group.length !== 0 && !work_contract_group.find(({ label }) => label === question.work_contract_group)) return false;
        if (question_group.length !== 0 && !question_group.find(({ value }) => value === String(question.question_no).substring(0,1))) return false;
        return true;
    }

    const customValueRenderer = (selected, _options) => {
        return selected.map(({ label }) => label).join(", ");
    };

    const handleClear = () => {
        setWork_contract_group([]);
        setQuestion_group([]);
    }

    return (
        <div className="w-11/12 py-3">
            <div className="flex flex-row">
                <h1 className="text-2xl font-medium text-primary-black">Question - </h1>
                <select className="ml-3 p-1 border rounded-sm w-2/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={currentQTemplate.name}
                    onChange={(e) => handleTemplate(e)} >
                    <option data-key='0' value=''></option>
                    {templates.map((template, index) => (
                        <option key={index} data-key={template.id} value={template.name}>{template.name}</option>
                    ))}
                </select>
            </div>
            <div>
                <div className="flex flex-row text-primary-black py-3">
                    <Link to={`/${language}/question/template/new`}>
                        <button className="bg-primary-green text-primary-white text-lg font-medium px-10 py-1 rounded-lg mr-2">New Template</button>
                    </Link>
                    <Link to={`/${language}/question/template/${currentQTemplate.id}`}>
                        <button className={`${currentQTemplate.id !== 0 ? 'bg-primary-green' : 'bg-primary-grey' } text-primary-white text-lg font-medium px-10 py-1 rounded-lg mr-8`} disabled={currentQTemplate.id === 0}>View Template</button>
                    </Link>
                </div>
                <label htmlFor="Filter" className="pt-2">Filter:</label>
                <div className="my-2">
                    <div className="flex justify-between">
                        <MultiSelect className="ms mb-3 w-1/2 pr-4"
                            options={questions.sort((a,b) => (a.work_contract_group > b.work_contract_group) ? 1 : ((b.work_contract_group > a.work_contract_group) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.work_contract_group !== ary[pos - 1].work_contract_group;})
                                .map(({work_contract_group: label, work_contract_group: value}) => ({ label, value }))}
                            value={work_contract_group}
                            onChange={setWork_contract_group}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Work Group ]"}}
                        />
                        <MultiSelect className="ms mb-3 w-1/2 pr-4"
                            options={question_group_options}
                            value={question_group}
                            onChange={setQuestion_group}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Category ]"}}
                        />
                        <div><button className="bg-primary-green text-primary-white text-lg px-10 py-1 rounded-lg m-0 whitespace-nowrap" onClick={handleClear}>Clear all</button></div>
                    </div>
                </div>
                <table className="text-primary-black border-primary-grey text-base text-left w-full">
                    <tbody>
                        <tr className="bg-gray-300">
                            <th className="border-2 w-1/12 px-2">Group</th>
                            <th className="border-2 w-1/12 px-2">No</th>
                            <th className="border-2 w-9/12 px-2">Topic</th>
                            <th className="border-2 w-1/12 px-2">Global / Local</th>
                        </tr>
                        {questions.filter((question) => filterLogic(question)).map((question, index) => (
                            <tr  key={question.id} className={index % 2 === 0? `bg-white` : `bg-gray-100`}>                                
                                <td className="border-2"><Link to={`/${language}/question/`+ question.id}><div className="flex flex-row px-2">{question.work_contract_group}</div></Link></td>
                                <td className="border-2"><Link to={`/${language}/question/`+ question.id}><div className="flex flex-row px-2">{question.question_no}</div></Link></td>                            
                                <td className="border-2"><Link to={`/${language}/question/`+ question.id}><div className="flex flex-row px-2">{question.title}</div></Link></td>
                                <td className="border-2"><Link to={`/${language}/question/`+ question.id}><div className="flex flex-row px-2">{question.is_global ? 'Global' : 'Local'}</div></Link></td> 
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Outlet />
            {(isLoading1 || isLoading2) && <Loading /> }
        </div>
    );
}

export default Question;
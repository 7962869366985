import { useEffect, useState, useRef } from 'react';
import api from "../Api";
import { Link, Outlet } from 'react-router-dom';
import Loading from "../Loading";
import * as XLSX from "xlsx/xlsx.mjs";
import { MultiSelect } from "react-multi-select-component";

function Personnel({ language, setNavbar, setActiveMenu, forceRefresh, setForceRefresh, currentRound, setCurrentRound }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);

    const [rounds, setRounds] = useState([]);
    const [personnels, setPersonnels] = useState([]);

    const [personnel_area, setPersonnel_area] = useState([]);
    const [organization_unit, setOrganization_unit] = useState([]);
    const [fes_level, setFes_level] = useState([]);
    const [name_of_manager, setName_of_manager] = useState([]);
    
    const inputFile = useRef(null);

    useEffect(() => {
        setActiveMenu(1);
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    useEffect(() => {
        const fetchRounds = async () => {
            try {
                setIsLoading1(true);
                const response = await api.get(`/round_name?key=${forceRefresh}`);
                setRounds(response.data);
                setIsLoading1(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchRounds();
    },[forceRefresh]);

    useEffect(() => {
        const fetchPersonnels = async () => {
            try {
                setIsLoading2(true);
                const response = await api.get(`/personnel/round/${currentRound.id}?key=${forceRefresh}`);
                setPersonnels(response.data);
                setIsLoading2(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchPersonnels();
    },[currentRound, forceRefresh]);

    const filterLogic = (personnel) => {
        if (personnel_area.length !== 0 && !personnel_area.find(({ label }) => label === personnel.personnel_area)) return false;
        if (organization_unit.length !== 0 && !organization_unit.find(({ label }) => label === personnel.organization_unit)) return false;
        if (fes_level.length !== 0 && !fes_level.find(({ label }) => label === personnel.fes_level)) return false;
        if (name_of_manager.length !== 0 && !name_of_manager.find(({ label }) => label === personnel.name_of_manager)) return false;
        return true;
    }
    
    const handleDownload = () => {
        const xlsHeader = Array([{
            A: "Pers.No.",
            B: "Personnel Number",
            C: "Name",
            D: "Cost Ctr",
            E: "Position",
            F: "Start Date",
            G: "End Date",
            H: "Work Contract",
            I: "Entrydate",
            J: "Position (Short Text)",
            K: "Personnel Area",
            L: "Short Text of Organizational U",
            M: "Gender Key",
            N: "Birth date",
            O: "Stellenkurztext",
            P: "Name of Manager (OM)",
            Q: "Email of Manager",
            R: "Last name",
            S: "First name",
            T: "E-mail",
            U: "Communication Type"
        }]);
        var xlsData = Array([]);
        personnels.map((personnel) => { const { id, fes_level, ...newdata } = personnel; xlsData[0].push(newdata); return newdata;} );

        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(xlsHeader[0], { skipHeader: true });
        XLSX.utils.sheet_add_json(ws, xlsData[0], { skipHeader: true, origin: "A2" });
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `Personnel-${currentRound.id === 0 ? rounds[0].name : currentRound.name}.xlsx`);
    }

    const postUpload = async (personnels) => {
        try {
            setIsLoading(true);
            const response = await api.post(`/personnel/upload/${currentRound.id}?key=${forceRefresh}`,personnels);
            console.log(response);
            setForceRefresh(crypto.randomUUID());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleUpload = () => {
        inputFile.current.click();
    }

    const handleFile = async (e) => {
        const jsonHeader = Array([{
            A: "pers_no",
            B: "personnel_number",
            C: "full_name",
            D: "cost_ctr",
            E: "position_detail",
            F: "start_date",
            G: "end_date",
            H: "work_contract",
            I: "entry_date",
            J: "position",
            K: "personnel_area",
            L: "organization_unit",
            M: "gender_key",
            N: "birth_date",
            O: "stellenkurz_text",
            P: "name_of_manager",
            Q: "email_of_manager",
            R: "last_name",
            S: "first_name",
            T: "email",
            U: "communication_type"
        }]);
        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const wb = XLSX.read(data);
        const ws = wb.Sheets[wb.SheetNames[0]];
        XLSX.utils.sheet_add_json(ws, jsonHeader[0], { skipHeader: true, origin: "A1" });
        const jsonData = XLSX.utils.sheet_to_json(ws,{ defval: '', blankrows: false });
        postUpload(jsonData.map(({personnel_number, ...personnel}) => {
            const fes_level = personnel.stellenkurz_text;
            return Object.values({...personnel, fes_level});
        }));
        e.target.value = '';
    }

    const customValueRenderer = (selected, _options) => {
        return selected.map(({ label }) => label).join(", ");
    };

    const handleClear = () => {
        setPersonnel_area([]);
        setOrganization_unit([]);
        setFes_level([]);
        setName_of_manager([]);
    }

    const handleRound = (e) => {
        const roundId = e.target.options[e.target.options.selectedIndex].getAttribute('data-key');
        if (roundId !== '0')
            setCurrentRound(rounds.find(({ id }) => id === Number(roundId)));
        else
            setCurrentRound({id: 0, name: '', status:''});
    }

    return (
        <div className="w-11/12 py-3">
            <div className="flex flex-row">
                <h1 className="text-2xl font-medium text-primary-black">Employee Information</h1>
                <select className="ml-3 p-1 border rounded-sm w-2/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={currentRound.name}
                    onChange={(e) => handleRound(e)} >
                    <option data-key='0' value=''></option>
                    {rounds.map((round, index) => (
                        <option key={index} data-key={round.id} value={round.name}>{round.name}</option>
                    ))}
                </select>
            </div>
            <div>
                <div className="flex flex-row text-primary-black py-3">
                    <Link to={`/${language}/personnel/new`} className="mr-6">
                        <button className={`${currentRound.status === 'Active' ? 'bg-primary-green' : 'bg-primary-grey'} text-primary-white text-lg px-10 py-1 rounded-lg`}
                            disabled={currentRound.status !== 'Active'} >New</button>
                    </Link>
                    <input ref={inputFile} type="file" className="w-0" onChange={(e) => handleFile(e)} />
                    <button className={`${currentRound.status === 'Active' ? 'bg-primary-green' : 'bg-primary-grey'} text-primary-white text-lg px-10 py-1 rounded-lg mr-2`} 
                        disabled={currentRound.status !== 'Active'} onClick={handleUpload}>Import</button>
                    <button className={`${currentRound.status !== '' ? 'bg-primary-green' : 'bg-primary-grey'} text-primary-white text-lg px-10 py-1 rounded-lg mr-auto`} 
                        disabled={currentRound.status === ''} onClick={handleDownload}>Export</button>
                </div>
                <label htmlFor="Filter" className="pt-2">Filter:</label>
                <div className="my-2">
                    <div className="flex justify-between">
                        <MultiSelect className="ms mb-3 w-1/5"
                            options={personnels.sort((a,b) => (a.personnel_area > b.personnel_area) ? 1 : ((b.personnel_area > a.personnel_area) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.personnel_area !== ary[pos - 1].personnel_area;})
                                .map(({personnel_area: label, personnel_area: value}) => ({ label, value }))}
                            value={personnel_area}
                            onChange={setPersonnel_area}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Personnel Area ]"}}
                        />
                        <MultiSelect className="ms mb-3 w-1/5"
                            options={personnels.sort((a,b) => (a.organization_unit > b.organization_unit) ? 1 : ((b.organization_unit > a.organization_unit) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.organization_unit !== ary[pos - 1].organization_unit;})
                                .map(({organization_unit: label, organization_unit: value}) => ({ label, value }))}
                            value={organization_unit}
                            onChange={setOrganization_unit}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Organization Unit ]"}}
                        />
                        <MultiSelect className="ms mb-3 w-1/5"
                            options={personnels.sort((a,b) => (a.fes_level > b.fes_level) ? 1 : ((b.fes_level > a.fes_level) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.fes_level !== ary[pos - 1].fes_level;})
                                .map(({fes_level: label, fes_level: value}) => ({ label, value }))}
                            value={fes_level}
                            onChange={setFes_level}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ FES Level ]"}}
                        />
                        <MultiSelect className="ms mb-3 w-1/5"
                            options={personnels.sort((a,b) => (a.name_of_manager > b.name_of_manager) ? 1 : ((b.name_of_manager > a.name_of_manager) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.name_of_manager !== ary[pos - 1].name_of_manager;})
                                .map(({name_of_manager: label, name_of_manager: value}) => ({ label, value }))}
                            value={name_of_manager}
                            onChange={setName_of_manager}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Manager ]"}}
                        />
                        <div><button className="bg-primary-green text-primary-white text-lg px-10 py-1 rounded-lg m-0 whitespace-nowrap" onClick={handleClear}>Clear all</button></div>
                    </div>
                </div>
                <table className="text-primary-black border-primary-grey text-base text-left w-full">
                    <tbody>
                        <tr className="bg-gray-300">
                            <th className="border-2 w-1/12 px-2">Pers No</th>
                            <th className="border-2 w-2/12 px-2">Name</th>
                            <th className="border-2 w-2/12 px-2">Email</th>
                            <th className="border-2 w-1/12 px-2">Start Date</th>
                            <th className="border-2 w-2/12 px-2">Personnel Area</th>
                            <th className="border-2 w-1/12 px-2">Org Unit</th>
                            <th className="border-2 w-1/12 px-2">FES Level</th>
                            <th className="border-2 w-2/12 px-2">Manager</th>
                        </tr>
                        {personnels.sort((a,b) => (a.full_name > b.full_name) ? 1 : ((b.full_name > a.full_name) ? -1 : 0)).filter((personnel) => filterLogic(personnel)).map((personnel, index) => (
                            <tr key={personnel.id} className={index % 2 === 0? `bg-white` : `bg-gray-100`}>
                                <td className="border-2">
                                    {(currentRound.status === 'Active') && <Link to={`/${language}/personnel/${personnel.id}`}><div className="flex flex-row px-2">{personnel.pers_no}</div></Link>}
                                    {(currentRound.status !== 'Active') && <div className="flex flex-row px-2 cursor-default">{personnel.pers_no}</div>}
                                </td>
                                <td className="border-2">
                                    {(currentRound.status === 'Active') && <Link to={`/${language}/personnel/${personnel.id}`}><div className="flex flex-row px-2">{personnel.full_name}</div></Link>}
                                    {(currentRound.status !== 'Active') && <div className="flex flex-row px-2 cursor-default">{personnel.full_name}</div>}
                                </td>
                                <td className="border-2">
                                    {(currentRound.status === 'Active') && <Link to={`/${language}/personnel/${personnel.id}`}><div className="flex flex-row px-2">{personnel.email}</div></Link>}
                                    {(currentRound.status !== 'Active') && <div className="flex flex-row px-2 cursor-default">{personnel.email}</div>}
                                </td>
                                <td className="border-2">
                                    {(currentRound.status === 'Active') && <Link to={`/${language}/personnel/${personnel.id}`}><div className="flex flex-row px-2">{personnel.start_date}</div></Link>}
                                    {(currentRound.status !== 'Active') && <div className="flex flex-row px-2 cursor-default">{personnel.start_date}</div>}
                                </td>
                                <td className="border-2">
                                    {(currentRound.status === 'Active') && <Link to={`/${language}/personnel/${personnel.id}`}><div className="flex flex-row px-2">{personnel.personnel_area}</div></Link>}
                                    {(currentRound.status !== 'Active') && <div className="flex flex-row px-2 cursor-default">{personnel.personnel_area}</div>}
                                </td>
                                <td className="border-2">
                                    {(currentRound.status === 'Active') && <Link to={`/${language}/personnel/${personnel.id}`}><div className="flex flex-row px-2">{personnel.organization_unit}</div></Link>}
                                    {(currentRound.status !== 'Active') && <div className="flex flex-row px-2 cursor-default">{personnel.organization_unit}</div>}
                                </td>
                                <td className="border-2">
                                    {(currentRound.status === 'Active') && <Link to={`/${language}/personnel/${personnel.id}`}><div className="flex flex-row px-2 justify-end">{personnel.fes_level}</div></Link>}
                                    {(currentRound.status !== 'Active') && <div className="flex flex-row px-2 justify-end cursor-default">{personnel.fes_level}</div>}
                                </td>
                                <td className="border-2">
                                    {(currentRound.status === 'Active') && <Link to={`/${language}/personnel/${personnel.id}`}><div className="flex flex-row px-2">{personnel.name_of_manager}</div></Link>}
                                    {(currentRound.status !== 'Active') && <div className="flex flex-row px-2 cursor-default">{personnel.name_of_manager}</div>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Outlet />
            {(isLoading || isLoading1 || isLoading2) && <Loading /> }
        </div>
    );
}

export default Personnel;
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Main from './Main';
import About from './pages/About';
import Round from './pages/Round';
import RoundForm from './forms/RoundForm';
import Personnel from './pages/Personnel';
import PersonnelForm from './forms/PersonnelForm';
import SendAssessment from './pages/SendAssessment';
import Assessment from './pages/Assessment';
import SendQuestionnaire from './pages/SendQuestionnaire';
import Questionnaire from './pages/Questionnaire';
import AssessmentTemplate from './pages/AssessmentTemplate';
import QuestionnaireTemplate from './pages/QuestionnaireTemplate';
import Question from './pages/Question';
import QuestionForm from './forms/QuestionForm';
import IndividualProfile from './pages/IndividualProfile';
import IndividualReport from './pages/IndividualReport';
import TeamProfile from './pages/TeamProfile';
import TeamReport from './pages/TeamReport';
import SummaryProfile from './pages/SummaryProfile';
import SummaryReport from './pages/SummaryReport';
import Toolbox from './pages/Toolbox';
import ToolboxForm from './forms/ToolboxForm';
import ToolboxTemplateForm from './forms/ToolboxTemplateForm';
import QuestionTemplateForm from './forms/QuestionTemplateForm';
import FollowUp from './pages/FollowUp';
import PreviewToolboxForm from './forms/PreviewToolboxForm';
import AssignedToolForm from './forms/AssignedToolForm';

function App() {
    const [translation, setTranslation] = useState([]);
    const [language, setLanguage] = useState('');
    const [activeMenu, setActiveMenu] = useState(0);
    const [presets, setPresets] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(crypto.randomUUID());
    const [currentRound, setCurrentRound] = useState({id:0, name:'', status:''});
    const [currentQTemplate, setCurrentQTemplate] = useState({id:0, name:''});
    const [currentQuestions, setCurrentQuestions] = useState([]);
    const [currentReportType, setCurrentReportType] = useState('');
    const [navbar, setNavbar] = useState(false);
    const [selectedforReport, setSelectedforReport] = useState([]);

    return (
        <Router>
            <Routes>
                <Route path='/'>
                    <Route index element={<Navigate to='en' replace={true} />} />
                    <Route path=':languageCode' element={<Main  language={language} 
                                                                translation={translation} 
                                                                setLanguage={setLanguage} 
                                                                setTranslation={setTranslation}
                                                                navbar={navbar}
                                                                setPresets={setPresets}
                                                                activeMenu={activeMenu} />}>
                        <Route index element={<About    language={language} 
                                                        translation={translation}
                                                        setNavbar={setNavbar} 
                                                        setActiveMenu={setActiveMenu} />} />
                        <Route path='round' element={<Round language={language} 
                                                            translation={translation} 
                                                            setNavbar={setNavbar} 
                                                            setActiveMenu={setActiveMenu}
                                                            forceRefresh={forceRefresh}
                                                            setCurrentRound={setCurrentRound} />}>
                            <Route path=':roundId' element={<RoundForm  language={language} 
                                                                        translation={translation}
                                                                        presets={presets}
                                                                        forceRefresh={forceRefresh}
                                                                        setForceRefresh={setForceRefresh} />} />
                        </Route>
                        <Route path='personnel' element={<Personnel language={language} 
                                                                    translation={translation} 
                                                                    setNavbar={setNavbar} 
                                                                    setActiveMenu={setActiveMenu}
                                                                    forceRefresh={forceRefresh}
                                                                    setForceRefresh={setForceRefresh}
                                                                    currentRound={currentRound}
                                                                    setCurrentRound={setCurrentRound} />}>
                            <Route path=':personnelId' element={<PersonnelForm  language={language} 
                                                                                translation={translation}
                                                                                currentRound={currentRound}
                                                                                setForceRefresh={setForceRefresh} />} />
                        </Route>
                        <Route path='send-assessment' element={<SendAssessment  language={language}
                                                                                translation={translation}
                                                                                setNavbar={setNavbar} 
                                                                                setActiveMenu={setActiveMenu}
                                                                                forceRefresh={forceRefresh}
                                                                                setForceRefresh={setForceRefresh} 
                                                                                currentRound={currentRound} 
                                                                                setCurrentRound={setCurrentRound} />} />
                        <Route path='assessment/:guid' element={<Assessment     language={language}
                                                                                translation={translation}
                                                                                setNavbar={setNavbar} 
                                                                                forceRefresh={forceRefresh}
                                                                                setForceRefresh={setForceRefresh}/>} /> 
                        <Route path='questionnaire/:guid' element={<Questionnaire   language={language}
                                                                                    translation={translation}
                                                                                    setNavbar={setNavbar} 
                                                                                    forceRefresh={forceRefresh}
                                                                                    setForceRefresh={setForceRefresh}/>} /> 
                        <Route path='send-questionnaire' element={<SendQuestionnaire    language={language}
                                                                                        translation={translation}
                                                                                        setNavbar={setNavbar} 
                                                                                        setActiveMenu={setActiveMenu}
                                                                                        forceRefresh={forceRefresh}
                                                                                        setForceRefresh={setForceRefresh} 
                                                                                        currentRound={currentRound} 
                                                                                        setCurrentRound={setCurrentRound} />} />
                        <Route path='assessment-template' element={<AssessmentTemplate  language={language}
                                                                                        translation={translation}
                                                                                        setNavbar={setNavbar} 
                                                                                        setActiveMenu={setActiveMenu}
                                                                                        forceRefresh={forceRefresh}
                                                                                        setForceRefresh={setForceRefresh} />} />
                        <Route path='questionnaire-template' element={<QuestionnaireTemplate    language={language}
                                                                                                translation={translation}
                                                                                                setNavbar={setNavbar} 
                                                                                                setActiveMenu={setActiveMenu}
                                                                                                forceRefresh={forceRefresh}
                                                                                                setForceRefresh={setForceRefresh} />} />
                        <Route path='profile'>
                            <Route path='individual' element={<IndividualProfile    language={language}
                                                                                    translation={translation}
                                                                                    setNavbar={setNavbar} 
                                                                                    setActiveMenu={setActiveMenu}
                                                                                    forceRefresh={forceRefresh}
                                                                                    setForceRefresh={setForceRefresh} 
                                                                                    currentRound={currentRound} 
                                                                                    setCurrentQuestions={setCurrentQuestions}
                                                                                    setCurrentRound={setCurrentRound}
                                                                                    setSelectedforReport={setSelectedforReport} />} />
                            <Route path='individual/report' element={<IndividualReport language={language}
                                                                            translation={translation}
                                                                            setNavbar={setNavbar} 
                                                                            setActiveMenu={setActiveMenu}
                                                                            forceRefresh={forceRefresh}
                                                                            setForceRefresh={setForceRefresh} 
                                                                            currentRound={currentRound} 
                                                                            currentQuestions={currentQuestions}
                                                                            setCurrentRound={setCurrentRound}
                                                                            selectedforReport={selectedforReport} />}>
                                <Route path='tool/:toolboxId' element={<PreviewToolboxForm language={language}
                                                                            translation={translation}
                                                                            setNavbar={setNavbar} 
                                                                            setActiveMenu={setActiveMenu}
                                                                            forceRefresh={forceRefresh}
                                                                            setForceRefresh={setForceRefresh} 
                                                                            currentRound={currentRound} 
                                                                            currentQuestions={currentQuestions}
                                                                            setCurrentRound={setCurrentRound}
                                                                            selectedforReport={selectedforReport} />} />
                            </Route>
                            <Route path='team' element={<TeamProfile    language={language}
                                                                        translation={translation}
                                                                        setNavbar={setNavbar} 
                                                                        setActiveMenu={setActiveMenu}
                                                                        forceRefresh={forceRefresh}
                                                                        setForceRefresh={setForceRefresh} 
                                                                        currentRound={currentRound} 
                                                                        setCurrentRound={setCurrentRound}
                                                                        setCurrentQuestions={setCurrentQuestions}
                                                                        setSelectedforReport={setSelectedforReport} />} />
                            <Route path='team/report' element={<TeamReport  language={language}
                                                                            translation={translation}
                                                                            setNavbar={setNavbar} 
                                                                            setActiveMenu={setActiveMenu}
                                                                            forceRefresh={forceRefresh}
                                                                            setForceRefresh={setForceRefresh} 
                                                                            currentRound={currentRound} 
                                                                            setCurrentRound={setCurrentRound}
                                                                            currentQuestions={currentQuestions}
                                                                            selectedforReport={selectedforReport} />}>
                                <Route path='tool/:toolboxId' element={<PreviewToolboxForm language={language}
                                                                            translation={translation}
                                                                            setNavbar={setNavbar} 
                                                                            setActiveMenu={setActiveMenu}
                                                                            forceRefresh={forceRefresh}
                                                                            setForceRefresh={setForceRefresh} 
                                                                            currentRound={currentRound} 
                                                                            currentQuestions={currentQuestions}
                                                                            setCurrentRound={setCurrentRound}
                                                                            selectedforReport={selectedforReport} />} />
                            </Route>
                            <Route path='summary' element={<SummaryProfile    language={language}
                                                                        translation={translation}
                                                                        setNavbar={setNavbar} 
                                                                        setActiveMenu={setActiveMenu}
                                                                        forceRefresh={forceRefresh}
                                                                        setForceRefresh={setForceRefresh} 
                                                                        currentRound={currentRound} 
                                                                        setCurrentRound={setCurrentRound}
                                                                        setCurrentQuestions={setCurrentQuestions}
                                                                        setSelectedforReport={setSelectedforReport}
                                                                        setCurrentReportType={setCurrentReportType} />} />
                            <Route path='summary/report' element={<SummaryReport  language={language}
                                                                            translation={translation}
                                                                            setNavbar={setNavbar} 
                                                                            setActiveMenu={setActiveMenu}
                                                                            forceRefresh={forceRefresh}
                                                                            setForceRefresh={setForceRefresh} 
                                                                            currentRound={currentRound} 
                                                                            setCurrentRound={setCurrentRound}
                                                                            currentQuestions={currentQuestions}
                                                                            selectedforReport={selectedforReport} 
                                                                            currentReportType={currentReportType} />} />
                        </Route>
                        <Route path='toolbox' element={<Toolbox language={language}
                                                                setNavbar={setNavbar} 
                                                                setActiveMenu={setActiveMenu}
                                                                forceRefresh={forceRefresh}
                                                                currentQTemplate={currentQTemplate}
                                                                setCurrentQTemplate={setCurrentQTemplate} />}>
                            <Route path=':toolboxId' element={<ToolboxForm  language={language} 
                                                                            translation={translation} 
                                                                            currentQTemplate={currentQTemplate}
                                                                            setForceRefresh={setForceRefresh} />} /> 
                            <Route path='template/:templateId' element={<ToolboxTemplateForm    language={language} 
                                                                                                translation={translation} 
                                                                                                setForceRefresh={setForceRefresh} />} />
                        </Route>
                        <Route path='question' element={<Question   language={language} 
                                                                    translation={translation}
                                                                    setNavbar={setNavbar} 
                                                                    setActiveMenu={setActiveMenu} 
                                                                    forceRefresh={forceRefresh}
                                                                    currentQTemplate={currentQTemplate}
                                                                    setCurrentQTemplate={setCurrentQTemplate} />}>    
                            <Route path=':questionId' element={<QuestionForm    language={language} 
                                                                                translation={translation} 
                                                                                currentQTemplate={currentQTemplate}
                                                                                setForceRefresh={setForceRefresh} />} /> 
                            <Route path='template/:templateId' element={<QuestionTemplateForm    language={language} 
                                                                                                translation={translation} 
                                                                                                setForceRefresh={setForceRefresh} />} />
                        </Route>
                        <Route path='follow-up' element={<FollowUp  language={language}
                                                                    translation={translation}
                                                                    setNavbar={setNavbar} 
                                                                    setActiveMenu={setActiveMenu}
                                                                    forceRefresh={forceRefresh}
                                                                    setForceRefresh={setForceRefresh} 
                                                                    currentRound={currentRound} 
                                                                    setCurrentRound={setCurrentRound}
                                                                    setCurrentQuestions={setCurrentQuestions}
                                                                    setSelectedforReport={setSelectedforReport} />}>
                            <Route path='tool/:toolboxId' element={<PreviewToolboxForm language={language}
                                            translation={translation}
                                            setNavbar={setNavbar} 
                                            setActiveMenu={setActiveMenu}
                                            forceRefresh={forceRefresh}
                                            setForceRefresh={setForceRefresh} 
                                            currentRound={currentRound} 
                                            currentQuestions={currentQuestions}
                                            setCurrentRound={setCurrentRound}
                                            selectedforReport={selectedforReport} />} />
                            <Route path='assigned-tool/:assignedToolId' element={<AssignedToolForm language={language}
                                            translation={translation}
                                            setNavbar={setNavbar} 
                                            setActiveMenu={setActiveMenu}
                                            forceRefresh={forceRefresh}
                                            presets={presets}
                                            setForceRefresh={setForceRefresh} 
                                            currentRound={currentRound} 
                                            currentQuestions={currentQuestions}
                                            setCurrentRound={setCurrentRound}
                                            selectedforReport={selectedforReport} />} />
                        </Route>
                    </Route>
                </Route>
           </Routes>    
        </Router>
    );
}

export default App;

import { getLabel } from './Utils';
import { useNavigate } from 'react-router-dom';

function Logo ({ translation, language }) {

    const navigate = useNavigate();
    
    const handleLogo = () => {
        navigate(`/${language}`);
    }

    return (
        <div className="w-full h-[133px] text-gray-300 text-sm font-medium font-sans pl-6 pt-[26px]">
            <div className="flex flex-row">
                <div className="mr-auto">
                    <button className="h-[56px] w-[222px] cursor-pointer" onClick={handleLogo}>
                        <svg width="222px" height="56px" viewBox="0 0 222 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <text x="-4" y="54" fill="white" textAnchor="start" style={{font: '72px Helvetica'}}>RE</text>
                            <text x="104" y="54" fill="#0097ac" textAnchor="start" style={{font: '72px Helvetica'}}>MS</text>
                        </svg>
                    </button>
                    <svg width="450px" height="20px" viewBox="0 0 450 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <text x="-1" y="15" fill="white" textAnchor="start" style={{font: 'expanded 18px Helvetica Neue LT Pro'}}>Retention & Engagement Management System</text>
                    </svg>
                </div>
                <div className="w-[192px] pt-1">
                    <svg width="190" height="72" viewBox="0 0 145 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M54.0706 12.5743H65.0238V7.47664H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499V22.5996H53.8967V34.664H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967V12.5743H54.0706ZM19.2985 48.2576H22.7757C23.4712 48.2576 23.9927 48.4275 24.3405 48.5975C24.862 48.9373 25.2098 49.617 25.2098 50.4666C25.2098 51.3162 24.862 51.8259 24.3405 52.1658C23.9927 52.3357 23.4712 52.3357 22.7757 52.3357H19.2985V48.2576ZM29.2086 60.6618L25.2098 54.3748C26.4268 54.0349 27.2961 53.3552 27.8177 52.3357C28.1654 51.4861 28.3392 50.8064 28.3392 50.1267C28.3392 49.4471 28.1654 48.7674 27.9915 48.4275C27.8177 48.0877 27.4699 47.5779 27.2961 47.2381C26.9484 46.7283 26.4268 46.3885 25.9052 46.2186C25.3836 46.0486 24.5143 45.7088 23.4712 45.7088H15.9952V60.6618H19.2985V54.8845H22.0803L25.5575 60.6618H29.2086ZM0 60.4919H6.6067C11.3009 60.4919 13.5611 57.2634 13.5611 52.8455C13.5611 47.9178 10.6055 45.369 6.6067 45.369H0V60.4919ZM3.30335 48.4275H5.73739C9.04074 48.4275 10.0839 50.2967 10.0839 53.3552C10.0839 56.7536 8.17144 57.7732 6.25898 57.7732H3.30335V48.4275Z" fill="#ffffff" />
                        <path d="M57.0281 45.5389L53.8986 50.2967L50.9429 45.5389H46.9442L51.9861 52.6756L46.5964 60.492H50.2475L53.7247 55.3943L57.0281 60.492H61.2007L55.811 52.6756L60.6791 45.5389H57.0281Z" fill="#ffffff" />
                        <path d="M62.9358 45.5389V60.492H73.7151V57.7732H66.2391V45.5389H62.9358Z" fill="#ffffff" />
                        <path d="M87.8018 45.5389L84.4984 55.9041H84.3246L80.8473 45.5389H75.9792V60.492H79.2826V50.1268L82.9337 60.492H85.5416L89.3665 49.9569V60.492H92.496V45.5389H87.8018Z" fill="#ffffff" />
                        <path d="M101.882 49.2772L103.795 54.7147H99.7962L101.882 49.2772ZM100.144 45.5389L94.4065 60.492H97.8837L99.1007 57.0935H104.838L106.055 60.492H109.532L103.795 45.5389H100.144Z" fill="#ffffff" />
                        <path d="M111.086 45.5381H114.39V60.4907H111.086V45.5381Z" fill="#ffffff" />
                        <path d="M117.354 45.5387V60.4917H129.003V57.773H120.831V54.2047H128.133V51.4859H120.831V48.4273H128.829V45.5387H117.354Z" fill="#ffffff" />
                        <path d="M37.7275 49.277L39.6399 54.7144H35.6411L37.7275 49.277ZM35.9889 45.5387L30.2515 60.4917H33.7287L34.9457 57.0933H40.6831L41.9001 60.4917H45.3773L39.6399 45.5387H35.9889Z" fill="#ffffff" />
                        <path d="M33.5545 48.0875H31.8159V45.5387H34.5977L33.5545 48.0875Z" fill="#ffffff" />
                        <path d="M41.8986 48.0875H43.8111V45.5387H41.0293L41.8986 48.0875Z" fill="#ffffff" />
                        <path d="M135.091 48.2576H138.569C139.264 48.2576 139.786 48.4275 140.133 48.5974C140.655 48.9372 141.003 49.6169 141.003 50.4665C141.003 51.3161 140.655 51.8259 140.133 52.1657C139.786 52.3357 139.264 52.3356 138.569 52.3356H135.091V48.2576ZM145.001 60.6618L141.177 54.3747C142.394 54.0349 143.263 53.3552 143.784 52.3356C144.132 51.486 144.306 50.8064 144.306 50.1267C144.306 49.447 144.132 48.7673 143.958 48.4275C143.784 48.0876 143.437 47.5779 143.263 47.238C142.915 46.7283 142.394 46.3884 141.872 46.2185C141.35 46.0486 140.481 45.7087 139.438 45.7087H131.962V60.6618H135.265V54.8845H138.047L141.524 60.6618H145.001Z" fill="#ffffff" />
                    </svg>
                </div>
            </div>
            <div className="text-primary-white">
                {getLabel (translation, 'version_text')}
            </div>
        </div>
    );
}

export default Logo;
import { Fragment, useEffect, useState } from 'react';
import { BsChevronRight, BsChevronDown } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom'
import api from "../Api";
import Loading from "../Loading";
import Dialog from '../Dialog';
import { MultiSelect } from "react-multi-select-component";

function TeamProfile({ language, setNavbar, setActiveMenu, forceRefresh, setForceRefresh, currentRound, setCurrentRound, setSelectedforReport, setCurrentQuestions }) {
    const [isLoading, setIsLoading] = useState(true);
    const [rounds, setRounds] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [personnels, setPersonnels] = useState([]);
    const [organization_unit, setOrganization_unit] = useState([]);
    const [fes_level, setFes_level] = useState([]);
    const [name_of_manager, setName_of_manager] = useState([]);
    const [key_status, setKey_status] = useState([]);
    const [group_id, setGroup_id] = useState([]);
    const [filter_mode, setFilter_mode] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setActiveMenu(4);
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    useEffect(() => {
        const fetchRounds = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/round_name?key=${forceRefresh}`);
                setRounds(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchRounds();
    },[forceRefresh]);

    useEffect(() => {
        const hierarchized = (personnels, name_of_manager, level, group_id) => {
            const result = [];
            personnels.filter((personnel) => personnel.name_of_manager === name_of_manager)
                .sort((a,b) => (a.full_name > b.full_name) ? 1 : ((b.full_name > a.full_name) ? -1 : 0))
                .map((personnel, index) => {
                    const hierarchy = hierarchized(personnels, personnel.full_name, level + 1, group_id === '' ? index + '' : (group_id + '.' + index));
                    result.push({...personnel, level: level, group_id: group_id === '' ? index + '' : (group_id + '.' + index), has_child: hierarchy.length > 0, collapse: false, selected: false});
                    hierarchy.map((personnel) => result.push(personnel));
                    return personnel;
                });
            return result;
        };

        const fetchPersonnels = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/overview/round/${currentRound.id}?key=${forceRefresh}`);
                setPersonnels(hierarchized(response.data, '', 0, ''));
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        };

        fetchPersonnels();
    },[currentRound, forceRefresh]);

    useEffect(() => {
        const fetchToolboxes = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/question/template/${currentRound.question_template_id}?key=${forceRefresh}`);
                setQuestions(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        fetchToolboxes();
    },[currentRound, forceRefresh]);

    useEffect(() => {
        if (organization_unit.length === 0 && fes_level.length === 0 && name_of_manager.length === 0 && key_status.length === 0)
            setFilter_mode(false)
        else {
            setFilter_mode(true);
        }
    },[organization_unit, fes_level, name_of_manager, key_status]);

    const filterLogic = (personnel) => {
        if (organization_unit.length !== 0 && !organization_unit.find(({ label }) => label === personnel.organization_unit)) return false;
        if (fes_level.length !== 0 && !fes_level.find(({ label }) => label === personnel.fes_level)) return false;
        if (name_of_manager.length !== 0 && !name_of_manager.find(({ label }) => label === personnel.name_of_manager)) return false;
        if (key_status.length !==0 && !key_status.find(({ label }) => label === personnel.key_status)) return false;
        if (!filter_mode && group_id.length !==0 && group_id.find(({ label }) => String(personnel.group_id).startsWith(label) && personnel.group_id !== label)) return false;
        return true;
    }
    
    const customValueRenderer = (selected, _options) => {
        return selected.map(({ label }) => label).join(", ");
    };

    const handleClear = () => {
        setOrganization_unit([]);
        setFes_level([]);
        setName_of_manager([]);
        setKey_status([]);
    }

    const checkSelectAll = (personnels) => {
        let selectAll = personnels.length !== 0 ? true : false;
        let countTrue = 0;
        personnels.forEach(item => {
            if (filterLogic(item) && item.has_child &&  item.selected === false) selectAll = false;
            if (filterLogic(item) && item.has_child &&  item.selected === true) countTrue++;
        });
        return countTrue > 0 ? selectAll : false;
    }

    const handleSelectAll = (e) => {
        setPersonnels(
            personnels.map(item => filterLogic(item) && item.has_child ? { ...item, selected: e.target.checked } : item)
        );
    }

    const handleSelect = (e, id) => {
        setPersonnels(personnels.map(item => item.id === id ? { ...item, selected: e.target.checked } : item));
    }

    const handleRound = (e) => {
        const roundId = e.target.options[e.target.options.selectedIndex].getAttribute('data-key');
        if (roundId !== '0')
            setCurrentRound(rounds.find(({ id }) => id === Number(roundId)));
        else
            setCurrentRound({id: 0, name: '', status:''});
    }

    const handleCollapse = (id) => {
        const newpersonnel = personnels.map((personnel) => {
            if (personnel.id === id) {
                if (personnel.collapse)
                    setGroup_id(group_id.filter(group_id => group_id.label !== personnel.group_id))
                else
                    setGroup_id([...group_id, {label: personnel.group_id}]);
                return {...personnel, collapse: !personnel.collapse};
            } else
                return personnel;
        });
        setPersonnels(newpersonnel);
    }

    const handleShowFullProfile = () => {
        if(personnels.find((personnel) => personnel.selected === true) === undefined)
            setShowDialog(true)
        else {
//            setSelectedforReport(personnels.filter((personnel) => personnel.selected === true));
            setCurrentQuestions(questions.filter(question => question.work_contract_group === currentRound.work_contract_group));
            setSelectedforReport(personnels);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/profile/team/report`);
        }
    }

    return (
        <div className="w-11/12 py-3">
            <div className="flex flex-row">
                <h1 className="text-2xl font-medium text-primary-black">Team Profile</h1>
                <select id="round" className="ml-3 p-1 border rounded-sm w-2/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={currentRound.name}
                    onChange={(e) => handleRound(e)} >
                    <option data-key='0' value=''></option>
                    {rounds.map((round, index) => (
                        <option key={index} data-key={round.id} value={round.name}>{round.name}</option>
                    ))}
                </select>
            </div>
            <div>
                <div className="flex flex-row text-primary-black py-3">
                    <button className="bg-primary-green text-primary-white text-lg px-10 py-1 rounded-lg mr-2" onClick={handleShowFullProfile}>Show R&E Profile</button>
                </div>
                <div className="pt-2">Filter:</div>
                <div className="my-2">
                    <div className="flex justify-between">
                        <MultiSelect className="ms mb-3 w-1/5"
                            options={personnels.sort((a,b) => (a.organization_unit > b.organization_unit) ? 1 : ((b.organization_unit > a.organization_unit) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.organization_unit !== ary[pos - 1].organization_unit;})
                                .map(({organization_unit: label, organization_unit: value}) => ({ label, value }))}
                            value={organization_unit}
                            onChange={setOrganization_unit}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Organization Unit ]"}}
                        />
                        <MultiSelect className="ms mb-3 w-1/5"
                            options={personnels.sort((a,b) => (a.fes_level > b.fes_level) ? 1 : ((b.fes_level > a.fes_level) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.fes_level !== ary[pos - 1].fes_level;})
                                .map(({fes_level: label, fes_level: value}) => ({ label, value }))}
                            value={fes_level}
                            onChange={setFes_level}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ FES Level ]"}}
                        />
                        <MultiSelect className="ms mb-3 w-1/5"
                            options={personnels.sort((a,b) => (a.name_of_manager > b.name_of_manager) ? 1 : ((b.name_of_manager > a.name_of_manager) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.name_of_manager !== ary[pos - 1].name_of_manager;})
                                .map(({name_of_manager: label, name_of_manager: value}) => ({ label, value }))}
                            value={name_of_manager}
                            onChange={setName_of_manager}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Manager ]"}}
                        />
                        <MultiSelect className="ms mb-3 w-1/5"
                            options={personnels.sort((a,b) => (a.key_status > b.key_status) ? 1 : ((b.key_status > a.key_status) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.key_status !== ary[pos - 1].key_status;})
                                .map(({key_status: label, key_status: value}) => ({ label, value }))}
                            value={key_status}
                            onChange={setKey_status}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Key Status ]"}}
                        />

                        <div><button className="bg-primary-green text-primary-white text-lg px-10 py-1 rounded-lg m-0 whitespace-nowrap" onClick={handleClear}>Clear all</button></div>
                    </div>
                </div>
                <table className="text-primary-black border-primary-grey text-base text-left w-full">
                    <tbody>
                        <tr className="bg-gray-300">
                            <th className="border-2 px-2">Name</th>
                            <th className="border-2 w-1/12 px-2">Org Unit</th>
                            <th className="border-2 w-1/12 px-2">FES Level</th>
                            <th className="border-2 w-2/12 px-2">Key Status</th>
                            <th className="border-2 w-2/12 px-2">Team Profile Status</th>
                            <th className="border-2 w-12">
                                <div className="flex flex-row items-center justify-center">
                                    <input id="selectall" type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSelectAll(personnels)} onChange={handleSelectAll} />
                                </div>
                            </th>
                        </tr>
                        {personnels.sort((a,b) => (a.group_id > b.group_id) ? 1 : ((b.group_id > a.group_id) ? -1 : 0)).filter((personnel) => filterLogic(personnel)).map((personnel, index) => (
                            <tr key={personnel.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                                <td className="border-2">
                                    <div className="flex flex-row pr-2 cursor-default">
                                        {!filter_mode &&
                                            <Fragment> 
                                                {String('\u00A0').repeat(personnel.level * 5)}
                                                <div className="w-6">
                                                    {personnel.has_child && <button className="text-primary-black px-1 h-6 border-0 m-0" onClick={() => handleCollapse(personnel.id)}>{personnel.collapse ? <BsChevronRight /> : <BsChevronDown />}</button>}
                                                </div>
                                            </Fragment>
                                        }
                                        {personnel.full_name}
                                    </div>
                                </td>
                                <td className="border-2"><div className="flex flex-row px-2 cursor-default">{personnel.organization_unit}</div></td>
                                <td className="border-2"><div className="flex flex-row px-2 cursor-default justify-end">{personnel.fes_level}</div></td>
                                <td className="border-2"><div className="flex flex-row px-2 cursor-default">{personnel.key_status}</div></td>
                                <td className="border-2">
                                    <div className={`flex flex-row px-2 cursor-default ${personnel.team_status === 'Completed'? ' text-green-500' : ''}${personnel.team_status === 'Saved'? ' text-primary-red' : ''}`}>
                                        {personnel.team_status}
                                    </div>
                                </td>
                                <td className="border-2"><div className="flex flex-row items-center justify-center">
                                    {personnel.has_child && <input id={"select" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={personnel.selected } onChange={(e) => handleSelect(e, personnel.id)} />}
                                </div></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {isLoading && <Loading /> }
            {showDialog && <Dialog title="Show Profile" setShowDialog={setShowDialog} caption="Please select any employee." /> }
        </div>
    );
}

export default TeamProfile;
import { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import api from '../Api';
import Loading from "../Loading";

function PersonnelForm ({ language, currentRound, setForceRefresh }) {
    const { personnelId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [personnel, setPersonnel] = useState({id: '', pers_no: '', personnel_number: '', full_name: '', cost_ctr: '', 
                                            position_detail: '', start_date: '', end_date: '', work_contract: '', entry_date: '',
                                            position: '', personnel_area: '', organization_unit: '', gender_key: '', birth_date: '', 
                                            stellenkurz_text: '', fes_level: '', name_of_manager: '', email_of_manager: '', last_name: '', 
                                            first_name: '', email: '', communication_type: ''});
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPersonnel = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/personnel/${personnelId}`);
                setPersonnel(response.data[0]);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        if (personnelId !== 'new') { 
            fetchPersonnel();
        } else {
            setIsNew(true);
            setIsLoading(false);
        }
    },[personnelId]);

    const postPersonnel = async () => {
        try {
            setIsLoading(true);
            const response = await api.post(`/personnel/${currentRound.id}`,personnel);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/personnel`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const putPersonnel = async () => {
        try {
            setIsLoading(true);
            const response = await api.put('/personnel',personnel);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/personnel`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const delPersonnel = async () => {
        try {
            setIsLoading(true);
            const response = await api.delete(`/personnel/${personnel.id}`);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/personnel`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSave = () => {
        if (!isEdit && !isNew) setIsEdit(true);
        if (isNew) postPersonnel();
        if (isEdit) putPersonnel();
    }

    const handleCancel = () => {
        setForceRefresh(crypto.randomUUID());
        navigate(`/${language}/personnel`);
    }

    const handleDelete = () => {
        delPersonnel();
    }

    return (
        <Fragment>
            {isLoading && <Loading />}
            {!isLoading &&
                <div className="modal bg-gray-300 bg-opacity-50">
                    <div className="w-[1100px] bg-primary-white drop-shadow-md border border-primary-green flex flex-col items-center text-base">
                        <div className="bg-primary-green text-primary-white text-xl font-medium px-4 py-2 items-start mr-auto w-full">{(isNew ? `New` : (isEdit? `Edit` : `View`)) + ` - Personnel`}</div>
                        <form className="text-primary-grey w-11/12 py-8">
                            <fieldset disabled={!(isEdit || isNew)} className="flex flex-row justify-between">
                                <div className="w-full">
                                    <label htmlFor="firstname" className="float-left w-3/12 pt-2">First name</label>
                                    <input id="firstname" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={personnel.first_name} onChange={e => setPersonnel({ ...personnel, first_name: e.target.value })} />

                                    <label htmlFor="lastname" className="float-left w-3/12 pt-2">Last name</label>
                                    <input id="lastname" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={personnel.last_name} onChange={e => setPersonnel({ ...personnel, last_name: e.target.value })} />

                                    <label htmlFor="email" className="float-left w-3/12 pt-2">Email</label>
                                    <input id="email" autoComplete="off" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={personnel.email} onChange={e => setPersonnel({ ...personnel, email: e.target.value })} />

                                    <label htmlFor="gender" className="float-left w-3/12 pt-2">Gender</label>
                                    <select id="gender" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={personnel.gender_key} 
                                        onChange={(e) => setPersonnel({ ...personnel, gender_key: e.target.value })} >
                                        <option value=""></option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </select>

                                    <label htmlFor="birthdate" className="float-left w-3/12 pt-2">Birth date</label>
                                    <input id="birthdate" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12"  value={personnel.birth_date} onChange={e => setPersonnel({ ...personnel, birth_date: e.target.value})} />

                                    <label htmlFor="entrydate" className="float-left w-3/12 pt-2">Entry date</label>
                                    <input id="entrydate" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12"  value={personnel.entry_date} onChange={e => setPersonnel({ ...personnel, entry_date: e.target.value })} />

                                    <label htmlFor="startdate" className="float-left w-3/12 pt-2">Start date</label>
                                    <input id="startdate" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12"  value={personnel.start_date} onChange={e => setPersonnel({ ...personnel, start_date: e.target.value })} />

                                    <label htmlFor="enddate" className="float-left w-3/12 pt-2">End date</label>
                                    <input id="enddate" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12"  value={personnel.end_date} onChange={e => setPersonnel({ ...personnel, end_date: e.target.value })} />

                                    <label htmlFor="communicationtype" className="float-left w-3/12 pt-2">Comm type</label>
                                    <input id="communicationtype" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12"  value={personnel.communication_type} onChange={e => setPersonnel({ ...personnel, communication_type: e.target.value })} />

                                </div>
                                <div className="w-1/12"></div>
                                <div className="w-full">
                                    <label htmlFor="persno" className="float-left w-3/12 pt-2">Pers. No.</label>
                                    <input id="persno" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={personnel.pers_no} onChange={e => setPersonnel({ ...personnel, pers_no: e.target.value })} />

                                    <label htmlFor="costctr" className="float-left w-3/12 pt-2">Cost center</label>
                                    <input id="costctr" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={personnel.cost_ctr} onChange={e => setPersonnel({ ...personnel, cost_ctr: e.target.value })} />

                                    <label htmlFor="position" className="float-left w-3/12 pt-2">Position (short)</label>
                                    <select id="position" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={personnel.position} 
                                        onChange={e => setPersonnel({ ...personnel, position: e.target.value })} >
                                        <option value=""></option>
                                        <option value="Employee">Employee</option>
                                        <option value="Head of">Head of</option>
                                    </select>

                                    <label htmlFor="positiondetail" className="float-left w-3/12 pt-2">Position</label>
                                    <input id="positiondetail" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={personnel.position_detail} onChange={e => setPersonnel({ ...personnel, position_detail: e.target.value })} />

                                    <label htmlFor="workcontract" className="float-left w-3/12 pt-2">Work contract</label>
                                    <select id="workcontract" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={personnel.work_contract} 
                                        onChange={e => setPersonnel({ ...personnel, work_contract: e.target.value })} >
                                        <option value=""></option>
                                        <option value="Salary staff">Salary staff</option>
                                        <option value="AT level staff">AT level staff</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Direct worker">Direct worker</option>
                                        <option value="Indirect worker">Indirect worker</option>
                                        <option value="Intern/ Apprentice">Intern/ Apprentice</option>
                                    </select>

                                    <label htmlFor="personnelarea" className="float-left w-3/12 pt-2">Personnel area</label>
                                    <input id="personnelarea" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={personnel.personnel_area} onChange={e => setPersonnel({ ...personnel, personnel_area: e.target.value })} />

                                    <label htmlFor="organizationunit" className="float-left w-3/12 pt-2">Org unit</label>
                                    <input id="organizationunit" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={personnel.organization_unit} onChange={e => setPersonnel({ ...personnel, organization_unit: e.target.value })} />

                                    <label htmlFor="stellenkurztext" className="float-left w-3/12 pt-2">Stellenkurztext</label>
                                    <input id="stellenkurztext" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={personnel.stellenkurz_text} onChange={e => setPersonnel({ ...personnel, stellenkurz_text: e.target.value })} />

                                    <label htmlFor="nameofmanager" className="float-left w-3/12 pt-2">Manager</label>
                                    <input id="nameofmanager" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={personnel.name_of_manager} onChange={e => setPersonnel({ ...personnel, name_of_manager: e.target.value })} />

                                    <label htmlFor="supemail" className="float-left w-3/12 pt-2">Email</label>
                                    <input id="supemail" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={personnel.email_of_manager} onChange={e => setPersonnel({ ...personnel, email_of_manager: e.target.value })} />

                                </div>
                            </fieldset>
                        </form>
                        <div className=" text-primary-white font-medium w-full flex justify-end pb-8 text-lg">
                            { !isNew  &&
                                <button className="bg-primary-red rounded-lg h-10 w-40 mr-12" onClick={handleDelete}>Delete</button>
                            }
                            <button className="bg-primary-green rounded-lg h-10 w-40 mr-6" onClick={handleSave}>{isEdit || isNew ? `Save` : `Edit`}</button>
                            <button className="bg-primary-grey rounded-lg h-10 w-40 mr-6" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default PersonnelForm;

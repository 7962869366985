import { useEffect, useState } from 'react';
import api from "../Api";
import Loading from "../Loading";
import { MultiSelect } from "react-multi-select-component";

function SendQuestionnaire({ language, setNavbar, setActiveMenu, forceRefresh, setForceRefresh, currentRound, setCurrentRound }) {
    const [isLoading, setIsLoading] = useState(true);
    const [rounds, setRounds] = useState([]);
    const [personnels, setPersonnels] = useState([]);
    const [personnel_area, setPersonnel_area] = useState([]);
    const [organization_unit, setOrganization_unit] = useState([]);
    const [fes_level, setFes_level] = useState([]);
    const [name_of_manager, setName_of_manager] = useState([]);
    const [key_status, setKey_status] = useState([]);
    const [questionnaire_status, setQuestionnaire_status] = useState([{label:null}]);
    const [tabs, setTabs] = useState('Questionnaire');
    const [templates, setTemplates] = useState([]);
    const [supguids, setSupGuids] = useState([]);
    const [supervisor_answered, setSupervisor_answered] = useState(0);
    const [individual_answered, setIndividual_answered] = useState(0);

    useEffect(() => {
        setActiveMenu(3);
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    useEffect(() => {
        const fetchRounds = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/round_name?key=${forceRefresh}`);
                setRounds(response.data);
                const response2 = await api.get(`/template/questionnaire?key=${forceRefresh}`);
                setTemplates(response2.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchRounds();
    },[forceRefresh]);

    useEffect(() => {
        const fetchPersonnels = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/send-questionnaire/round/${currentRound.id}?key=${forceRefresh}`);
                setPersonnels(response.data);
                const response2 = await api.get(`/send-questionnaire/supervisor_guid/round/${currentRound.id}?key=${forceRefresh}`);
                setSupGuids(response2.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchPersonnels();
    },[currentRound, forceRefresh]);

    useEffect(() => {
        let supervisor_counts = 0, supervisor_answered = 0;
        let individual_counts = 0, individual_answered = 0;
        personnels.forEach((personnel) => {
            if (personnel.supervisor_questionnaire_status !== null && personnel.key_status !== 'Non-key') supervisor_counts++;
            if (personnel.personnel_questionnaire_status !== null) individual_counts++;
            if (personnel.supervisor_questionnaire_status === 'Submitted' && personnel.key_status !== 'Non-key') supervisor_answered++;
            if (personnel.personnel_questionnaire_status === 'Submitted') individual_answered++;
        });
        if (supervisor_counts > 0 ) {
            setSupervisor_answered( Math.round((((supervisor_answered / supervisor_counts) * 100) + Number.EPSILON) * 10) / 10 );
            setIndividual_answered( Math.round((((individual_answered / individual_counts) * 100) + Number.EPSILON) * 10) / 10 );
        } else {
            setSupervisor_answered(-1);
            setIndividual_answered(-1);
        }
    },[personnels]);

    const filterLogic = (personnel, assessment_only = false) => {
        if (!assessment_only) {
            if (personnel_area.length !== 0 && !personnel_area.find(({ label }) => label === personnel.personnel_area)) return false;
            if (organization_unit.length !== 0 && !organization_unit.find(({ label }) => label === personnel.organization_unit)) return false;
            if (fes_level.length !== 0 && !fes_level.find(({ label }) => label === personnel.fes_level)) return false;
            if (name_of_manager.length !== 0 && !name_of_manager.find(({ label }) => label === personnel.name_of_manager)) return false;
            if (key_status.length !==0 && !key_status.find(({ label }) => label === personnel.key_status)) return false;
        }
        if (questionnaire_status.length !==0 && !questionnaire_status.find(({ label }) => label === personnel.supervisor_questionnaire_status)) return false;
        return true;
    }
    
    const saveSelected = async (personnels) => {
        try {
            setIsLoading(true);
            const response = await api.post('/send-questionnaire',personnels);
            console.log(response);
            setForceRefresh(crypto.randomUUID());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const updateSelected = async (personnels, target) => {
        try {
            setIsLoading(true);
            const response = await api.put(`/send-questionnaire/reminder/${target}`,personnels);
            console.log(response);
            setForceRefresh(crypto.randomUUID());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const sendSelected = async (personnels) => {
        try {
            setIsLoading(true);
            const response = api.post('/sendmail',personnels);
            console.log(response);
            setForceRefresh(crypto.randomUUID());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }


    const handleSend = () => {
        const recipients= [];
        if (tabs === 'Questionnaire') {
            const selectedPersonnel =  personnels.filter((personnel) => filterLogic(personnel) && personnel.selected)
                .map(personnel => ({ ...personnel, supervisor_guid: supguids.find(({ round_id, name_of_manager, email_of_manager }) => round_id === personnel.round_id && name_of_manager === personnel.name_of_manager && email_of_manager === personnel.email_of_manager).guid,
                     personnel_guid: crypto.randomUUID()}));

            saveSelected(selectedPersonnel.map((personnel) => {
                return Object.values({id: personnel.id, supervisor_guid: personnel.supervisor_guid, personnel_guid: personnel.personnel_guid});
            }));

            selectedPersonnel.sort((a,b) => (a.name_of_manager > b.name_of_manager) ? 1 : ((b.name_of_manager > a.name_of_manager) ? -1 : 0))
                .filter((item, pos, ary) => {return !pos || item.name_of_manager !== ary[pos - 1].name_of_manager;})
                .map(({ supervisor_guid, name_of_manager, email_of_manager, pers_no, full_name, email, personnel_area, organization_unit, fes_level }) => {
                    recipients.push({
                        name: name_of_manager, 
                        email: email_of_manager,
                        subject: templates.find(({feature, item}) => feature === 'questionnaire' && item === 'qnairesub').t_text,
                        email_html: templates.find(({feature, item}) => feature === 'questionnaire' && item === 'qnairehtml').t_text
                            .replace('{{link}}','https://rems.virtuosity.sg/' + language + '/questionnaire/'+supervisor_guid)
                            .replace('{{Pers.No.}}', pers_no)
                            .replace('{{Name}}', full_name)
                            .replace('{{E-mail}}', email)
                            .replace('{{Personnel Area}}', personnel_area)
                            .replace('{{Short Text of Organizational U}}', organization_unit)
                            .replace('{{Stellenkurztext}}', fes_level)
                            .replace('{{Name of Manager (OM)}}', name_of_manager)
                            .replace('{{Email of Manager}}', email_of_manager)
                        });
                    return recipients;
                });

            selectedPersonnel.map(({ personnel_guid, full_name, email, pers_no, personnel_area, organization_unit, fes_level, name_of_manager, email_of_manager }) => {
                recipients.push({
                    name: full_name, 
                    email: email,
                    subject: templates.find(({feature, item}) => feature === 'questionnaire' && item === 'qnairesub').t_text,
                    email_html: templates.find(({feature, item}) => feature === 'questionnaire' && item === 'qnairehtml').t_text
                        .replace('{{link}}','https://rems.virtuosity.sg/' + language + '/questionnaire/'+personnel_guid)
                        .replace('{{Pers.No.}}', pers_no)
                        .replace('{{Name}}', full_name)
                        .replace('{{E-mail}}', email)
                        .replace('{{Personnel Area}}', personnel_area)
                        .replace('{{Short Text of Organizational U}}', organization_unit)
                        .replace('{{Stellenkurztext}}', fes_level)
                        .replace('{{Name of Manager (OM)}}', name_of_manager)
                        .replace('{{Email of Manager}}', email_of_manager)
                });
                return recipients;
            });
            
            sendSelected(recipients);

        } else {
            switch (tabs) {
                case 'Reminder':
                    updateSelected(personnels.filter((personnel) => filterLogic(personnel) && personnel.selected).map(({ questionnaire_id }) => { return Object.values({questionnaire_id}) }), 'supervisor');
                    personnels.filter((personnel) => filterLogic(personnel) && personnel.selected && personnel.key_status === 'Key')
                    .sort((a,b) => (a.name_of_manager > b.name_of_manager) ? 1 : ((b.name_of_manager > a.name_of_manager) ? -1 : 0))
                    .filter((item, pos, ary) => {return !pos || item.name_of_manager !== ary[pos - 1].name_of_manager;})
                    .map(({ supervisor_guid, name_of_manager, email_of_manager, pers_no, full_name, email, personnel_area, organization_unit, fes_level }) => {
                        recipients.push({
                            name: name_of_manager, 
                            email: email_of_manager,
                            subject: templates.find(({feature, item}) => feature === 'questionnaire' && item === 'remindsub').t_text,
                            email_html: templates.find(({feature, item}) => feature === 'questionnaire' && item === 'remindhtml').t_text
                                .replace('{{link}}','https://rems.virtuosity.sg/' + language + '/questionnaire/'+supervisor_guid)
                                .replace('{{Pers.No.}}', pers_no)
                                .replace('{{Name}}', full_name)
                                .replace('{{E-mail}}', email)
                                .replace('{{Personnel Area}}', personnel_area)
                                .replace('{{Short Text of Organizational U}}', organization_unit)
                                .replace('{{Stellenkurztext}}', fes_level)
                                .replace('{{Name of Manager (OM)}}', name_of_manager)
                                .replace('{{Email of Manager}}', email_of_manager)    
                            });
                        return recipients;
                    });
                    break;
                case 'Reminder2':
                    updateSelected(personnels.filter((personnel) => filterLogic(personnel) && personnel.selected).map(({ questionnaire_id }) => { return Object.values({questionnaire_id}) }), 'personnel');
                    personnels.filter((personnel) => filterLogic(personnel) && personnel.selected)
                    .map(({ personnel_guid, full_name, email, pers_no, personnel_area, organization_unit, fes_level, name_of_manager, email_of_manager }) => {
                        recipients.push({
                            name: full_name, 
                            email: email,
                            subject: templates.find(({feature, item}) => feature === 'questionnaire' && item === 'remindsub').t_text,
                            email_html: templates.find(({feature, item}) => feature === 'questionnaire' && item === 'remindhtml').t_text
                                .replace('{{link}}','https://rems.virtuosity.sg/' + language + '/questionnaire/'+personnel_guid)
                                .replace('{{Pers.No.}}', pers_no)
                                .replace('{{Name}}', full_name)
                                .replace('{{E-mail}}', email)
                                .replace('{{Personnel Area}}', personnel_area)
                                .replace('{{Short Text of Organizational U}}', organization_unit)
                                .replace('{{Stellenkurztext}}', fes_level)
                                .replace('{{Name of Manager (OM)}}', name_of_manager)
                                .replace('{{Email of Manager}}', email_of_manager)
                            });
                        return recipients;
                    });
                    break;
                default:
            }

            sendSelected(recipients);    
        }
    }

    const customValueRenderer = (selected, _options) => {
        return selected.map(({ label }) => label).join(", ");
    };

    const handleClear = () => {
        setPersonnel_area([]);
        setOrganization_unit([]);
        setFes_level([]);
        setName_of_manager([]);
        setKey_status([]);
    }

    const checkSelectAll = (personnels) => {
        let selectAll = personnels.length !== 0 ? true : false;
        let countTrue = 0;
        personnels.forEach(item => {
            if (filterLogic(item) && ((tabs === 'Questionnaire' && item.name_of_manager !=='') ||
            (tabs === 'Reminder' && item.name_of_manager !=='' && item.supervisor_questionnaire_status !== 'Submitted' && item.key_status !== 'Non-key') || 
            (tabs === 'Reminder2' && item.name_of_manager !=='' && item.personnel_questionnaire_status !== 'Submitted')) &&  item.selected === false) selectAll = false;
            if (filterLogic(item) && ((tabs === 'Questionnaire' && item.name_of_manager !=='') ||
            (tabs === 'Reminder' && item.name_of_manager !=='' && item.supervisor_questionnaire_status !== 'Submitted' && item.key_status !== 'Non-key') || 
            (tabs === 'Reminder2' && item.name_of_manager !=='' && item.personnel_questionnaire_status !== 'Submitted')) &&  item.selected === true) countTrue++;
        });
        return countTrue > 0 ? selectAll : false;
    }

    const handleSelectAll = (e) => {
        setPersonnels(
            personnels.map(item => filterLogic(item) &&
            ((tabs === 'Questionnaire' && item.name_of_manager !=='') ||
            (tabs === 'Reminder' && item.name_of_manager !=='' && item.supervisor_questionnaire_status !== 'Submitted' && item.key_status !== 'Non-key') || 
            (tabs === 'Reminder2' && item.name_of_manager !=='' && item.personnel_questionnaire_status !== 'Submitted')) ? { ...item, selected: e.target.checked } : item)
        );
    }

    const handleRound = (e) => {
        const roundId = e.target.options[e.target.options.selectedIndex].getAttribute('data-key');
        if (roundId !== '0')
            setCurrentRound(rounds.find(({ id }) => id === Number(roundId)));
        else
            setCurrentRound({id: 0, name: '', status:''});
    }

    const handleTab = (tabname) => {
        setTabs(tabname);
        handleClear();
        switch(tabname) {
            case 'Questionnaire': setQuestionnaire_status([{label: null}]);
                break;
            case 'Reminder':
            case 'Reminder2':
                setQuestionnaire_status([{label: 'Open'}, {label: 'Saved'}, {label: 'Submitted'}]);
                break;
            default:
        }
    }
    
    return (
        <div className="w-11/12 py-3">
            <div className="flex flex-row">
                <h1 className="text-2xl font-medium text-primary-black">Send Questionnaire</h1>
                <select className="ml-3 p-1 border rounded-sm w-2/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={currentRound.name}
                    onChange={(e) => handleRound(e)} >
                    <option data-key='0' value=''></option>
                    {rounds.map((round, index) => (
                        <option key={index} data-key={round.id} value={round.name}>{round.name}</option>
                    ))}
                </select>
            </div>
            <div>
                <div className="flex flex-row text-primary-black py-3">
                    <button className={`${currentRound.status === 'Active' ? 'bg-primary-green' : 'bg-primary-grey'} text-primary-white text-lg px-10 py-1 rounded-lg mr-2`} 
                        disabled={currentRound.status !== 'Active'} onClick={handleSend}>Send</button>
                </div>
                <label htmlFor="Filter" className="pt-2">Filter:</label>
                <div className="my-2">
                    <div className="flex justify-between">
                        <MultiSelect className="ms mb-3 w-1/6"
                            options={personnels.filter((personnel) => filterLogic(personnel, true))
                                .sort((a,b) => (a.personnel_area > b.personnel_area) ? 1 : ((b.personnel_area > a.personnel_area) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.personnel_area !== ary[pos - 1].personnel_area;})
                                .map(({personnel_area: label, personnel_area: value}) => ({ label, value }))}
                            value={personnel_area}
                            onChange={setPersonnel_area}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Personnel Area ]"}}
                        />
                        <MultiSelect className="ms mb-3 w-1/6"
                            options={personnels.filter((personnel) => filterLogic(personnel, true))
                                .sort((a,b) => (a.organization_unit > b.organization_unit) ? 1 : ((b.organization_unit > a.organization_unit) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.organization_unit !== ary[pos - 1].organization_unit;})
                                .map(({organization_unit: label, organization_unit: value}) => ({ label, value }))}
                            value={organization_unit}
                            onChange={setOrganization_unit}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Organization Unit ]"}}
                        />
                        <MultiSelect className="ms mb-3 w-1/6"
                            options={personnels.filter((personnel) => filterLogic(personnel, true))
                                .sort((a,b) => (a.fes_level > b.fes_level) ? 1 : ((b.fes_level > a.fes_level) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.fes_level !== ary[pos - 1].fes_level;})
                                .map(({fes_level: label, fes_level: value}) => ({ label, value }))}
                            value={fes_level}
                            onChange={setFes_level}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ FES Level ]"}}
                        />
                        <MultiSelect className="ms mb-3 w-1/6"
                            options={personnels.filter((personnel) => filterLogic(personnel, true))
                                .sort((a,b) => (a.name_of_manager > b.name_of_manager) ? 1 : ((b.name_of_manager > a.name_of_manager) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.name_of_manager !== ary[pos - 1].name_of_manager;})
                                .map(({name_of_manager: label, name_of_manager: value}) => ({ label, value }))}
                            value={name_of_manager}
                            onChange={setName_of_manager}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Manager ]"}}
                        />
                        <MultiSelect className="ms mb-3 w-1/6"
                            options={personnels.filter((personnel) => filterLogic(personnel, true))
                                .sort((a,b) => (a.key_status > b.key_status) ? 1 : ((b.key_status > a.key_status) ? -1 : 0))
                                .filter((item, pos, ary) => {return !pos || item.key_status !== ary[pos - 1].key_status;})
                                .map(({key_status: label, key_status: value}) => ({ label, value }))}
                            value={key_status}
                            onChange={setKey_status}
                            hasSelectAll={false}
                            disableSearch={true}
                            valueRenderer={customValueRenderer}
                            overrideStrings={{"selectSomeItems" : "[ Key Status ]"}}
                        />


                        <div><button className="bg-primary-green text-primary-white text-lg px-10 py-1 rounded-lg m-0" onClick={handleClear}>Clear all</button></div>
                    </div>
                </div>
                <div className="w-full flex my-2 items-end pb-2 border-primary-grey">
                    <div className="w-2 border-b-2">&nbsp;</div>
                    <button className={`text-primary-black text-lg px-6 py-1 rounded-t-lg border-t-2 border-l-2 border-r whitespace-nowrap ${tabs === 'Questionnaire' ? 'border-b-0 h-[42px] font-semibold' : 'border-b-2 h-[38px]' } `} onClick={() => handleTab('Questionnaire')} >Questionnaire</button>
                    <button className={`text-primary-black text-lg px-6 py-1 rounded-t-lg border-t-2 border-l border-r-2 whitespace-nowrap ${tabs === 'Reminder' ? 'border-b-0 h-[42px] font-semibold' : 'border-b-2 h-[38px]' } `} onClick={() => handleTab('Reminder')} >
                        Reminder - Supervisor {supervisor_answered >= 0 && <span className={`${supervisor_answered < 50 ? 'text-primary-red' : (supervisor_answered < 80 ? 'text-yellow-500' : 'text-green-500')} text-sm font-normal`}>({supervisor_answered}% Answered)</span>}
                    </button>
                    <button className={`text-primary-black text-lg px-6 py-1 rounded-t-lg border-t-2 border-l border-r-2 whitespace-nowrap ${tabs === 'Reminder2' ? 'border-b-0 h-[42px] font-semibold' : 'border-b-2 h-[38px]' } `} onClick={() => handleTab('Reminder2')} >
                        Reminder - Staff {individual_answered >= 0 && <span className={`${individual_answered < 50 ? 'text-primary-red' : (individual_answered < 80 ? 'text-yellow-500' : 'text-green-500')} text-sm font-normal`}>({individual_answered}% Answered)</span>}
                    </button>
                    <div className="w-full mr-auto border-b-2">&nbsp;</div>
                </div>
                <table className="text-primary-black border-primary-grey text-base text-left w-full">
                    <tbody>
                        <tr className="bg-gray-300">
{//                            <th className="border-2 w-1/12 px-2">Pers. No</th>
}
                            <th className="border-2 px-2">Name</th>
{/*                            <th className="border-2 px-2">Email</th>
                            <th className="border-2 w-2/12 px-2">Personnel Area</th>
*/}
                            <th className="border-2 w-1/12 px-2">Org Unit</th>
                            <th className="border-2 w-1/12 px-2">FES Level</th>
                            <th className="border-2 px-2">Manager</th>
                            <th className="border-2 px-2 w-2/12">Key Status</th>
                            {(tabs === 'Reminder' || tabs === 'Reminder2') &&
                                <th className="border-2 px-2 w-1/12">Count</th>
                            }
                            { (currentRound.status === 'Active') &&
                                <th className="border-2 w-12">
                                    <div className="flex flex-row items-center justify-center">
                                        <input id="select" type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSelectAll(personnels)} onChange={handleSelectAll} />
                                    </div>
                                </th>
                            }
                        </tr>
                        {personnels.sort((a,b) => (a.full_name > b.full_name) ? 1 : ((b.full_name > a.full_name) ? -1 : 0)).filter((personnel) => filterLogic(personnel)).map((personnel, index) => (
                            <tr key={personnel.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
{//                                <td className="border-2"><div className="flex flex-row px-2 cursor-default">{personnel.pers_no}</div></td>
}
                                <td className="border-2"><div className="flex flex-row px-2 cursor-default">{personnel.full_name}</div></td>
{/*                                <td className="border-2"><div className="flex flex-row px-2 cursor-default">{personnel.email}</div></td>
                                <td className="border-2"><div className="flex flex-row px-2 cursor-default">{personnel.personnel_area}</div></td>
*/}
                                <td className="border-2"><div className="flex flex-row px-2 cursor-default">{personnel.organization_unit}</div></td>
                                <td className="border-2"><div className="flex flex-row px-2 cursor-default justify-end">{personnel.fes_level}</div></td>
                                <td className="border-2"><div className="flex flex-row px-2 cursor-default">{personnel.name_of_manager}</div></td>
                                <td className="border-2"><div className={`flex flex-row px-2 cursor-default${personnel.key_status === 'Key'? ' text-green-500' : ''}${personnel.key_status === 'Non-key'? ' text-primary-red' : ''}`}>{personnel.key_status}</div></td>
                                {(tabs === 'Reminder') &&
                                    <td className="border-2"><div className="flex flex-row px-2 cursor-default justify-end">{personnel.supervisor_reminder_count}</div></td>
                                }
                                {(tabs === 'Reminder2') &&
                                    <td className="border-2"><div className="flex flex-row px-2 cursor-default justify-end">{personnel.personnel_reminder_count}</div></td>
                                }
                                { (currentRound.status === 'Active') &&
                                    <td className={`${(tabs === 'Reminder' && personnel.supervisor_questionnaire_status === 'Submitted') || (tabs === 'Reminder2' && personnel.personnel_questionnaire_status === 'Submitted') ? 'bg-green-700' : ''} border-2`}>
                                        <div className="flex flex-row items-center justify-center">
                                            { ((tabs === 'Questionnaire') ||
                                                (tabs === 'Reminder' && personnel.supervisor_questionnaire_status !== 'Submitted' && personnel.key_status !== 'Non-key') ||
                                                (tabs === 'Reminder2' && personnel.personnel_questionnaire_status !== 'Submitted')) &&
                                                <input id="select" type="checkbox" className="w-4 h-4 bg-primary-grey" checked={personnel.selected } onChange={e => setPersonnels(personnels.map(item => item.id === personnel.id ? { ...item, selected: e.target.checked } : item))} />
                                            }
                                        </div>
                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {isLoading && <Loading /> }
        </div>
    );
}

export default SendQuestionnaire;
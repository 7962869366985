import { useState, useEffect, Fragment} from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import api from '../Api';
import Loading from "../Loading";

function RoundForm ({ language, presets, setForceRefresh, forceRefresh }) {
    const { roundId } = useParams();
    const [rounds, setRounds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [round, setRound] = useState({id: '', name: '', description: '', prev_round_id: 0, prev_round_name: '', question_template_id: 0, question_template_name: '', status_id: 0, status: '' });
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const navigate = useNavigate();
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        const fetchRound = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/round/${roundId}`);
                setRound(response.data[0]);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        if (roundId !== 'new') {
            fetchRound();
        } else {
            setIsNew(true);
            setIsLoading(false);
        }
    },[roundId]);

    useEffect(() => {
        const fetchRounds = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/round_name?key=${forceRefresh}`);
                setRounds(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchRounds();
    },[forceRefresh]);

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/question-template?key=${forceRefresh}`);
                setTemplates(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchTemplates();
    },[forceRefresh]);


    const postRound = async () => {
        try {
            setIsLoading(true);
            const response = await api.post('/round',round);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/round`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const putRound = async () => {
        try {
            setIsLoading(true);
            const response = await api.put('/round',round);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/round`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const delRound = async () => {
        try {
            setIsLoading(true);
            const response = await api.delete(`/round/${roundId}`);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/round`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSave = () => {
        if (!isEdit && !isNew) setIsEdit(true);
        if (isNew) postRound();
        if (isEdit) putRound(); 
    }

    const handleCancel = () => {
        setForceRefresh(crypto.randomUUID());
        navigate(`/${language}/round`);
    }

    const handleDelete = () => {
        delRound();
    }

    return (
        <Fragment>
            {isLoading && <Loading />}
            {!isLoading &&
                <div className="modal bg-gray-300 bg-opacity-50">
                    <div className="w-[720px] bg-primary-white drop-shadow-md border border-primary-green flex flex-col items-center text-base">
                        <div className="bg-primary-green text-primary-white text-xl font-medium px-4 py-2 items-start mr-auto w-full">{(isNew ? `New` : (isEdit? `Edit` : `View`)) + ` - Round`}</div>
                        <form className="text-primary-black w-5/6 py-8">
                            <fieldset disabled={!(isEdit || isNew)}>
                                <label htmlFor="name" className="float-left w-3/12 pt-2">Name</label>
                                <input id="name" autoComplete="off" type="text" key={round.id} className="p-1 border rounded-sm mb-3 float-left w-9/12" value={round.name} onChange={e => setRound({ ...round, name: e.target.value })} />

                                <label htmlFor="description" className="float-left w-3/12 pt-2">Description</label>
                                <textarea id="description" rows="4" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={round.description} onChange={e => setRound({ ...round, description: e.target.value })} />

                                <label htmlFor="prev_round" className="float-left w-3/12 pt-2">Previous Round</label>
                                <select id="prev_round" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-black disabled:opacity-100" value={round.prev_round_name} 
                                    onChange={e => setRound({ ...round, prev_round_id: e.target.options[e.target.options.selectedIndex].getAttribute('data-key'), prev_round_name: e.target.value })} >
                                    <option data-key='0' value=''></option>
                                    {rounds.filter((item) => item.id !== Number(roundId)).map((round, index) => (
                                        <option key={index} data-key={round.id} value={round.name}>{round.name}</option>
                                    ))}
                                </select>

                                <label htmlFor="qtemplate" className="float-left w-3/12 pt-2">Question Template</label>
                                <select id="qtemplate" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-black disabled:opacity-100" value={round.question_template_name} 
                                    onChange={e => setRound({ ...round, question_template_id : e.target.options[e.target.options.selectedIndex].getAttribute('data-key'), question_template_name: e.target.value })} >
                                    <option data-key='0' value=''></option>
                                    {templates.map((template, index) => (
                                        <option key={index} data-key={template.id} value={template.name}>{template.name}</option>
                                    ))}
                                </select>

                                {!isNew &&
                                    <Fragment>
                                        <label htmlFor="status" className="float-left w-3/12 pt-2">Status</label>
                                        <select id="status" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-black disabled:opacity-100" value={round.status} 
                                            onChange={e => setRound({ ...round, status_id: e.target.options[e.target.options.selectedIndex].getAttribute('data-key'), status: e.target.value })} >
                                            <option value=""></option>
                                            {presets.filter(preset => (preset.tag === 'RS')).map((preset, index) => (
                                                <option key={index} data-key={preset.id} value={preset.name}>{preset.name}</option>
                                            ))}
                                        </select>
                                    </Fragment>
                                }
                            </fieldset>
                        </form>
                        <div className=" text-primary-white font-medium w-full flex justify-end pb-8 text-lg">  
                            { !isNew  &&
                                <button className="bg-primary-red rounded-lg h-10 w-40 mr-12" onClick={handleDelete}>Delete</button>
                            }
                            <button className="bg-primary-green rounded-lg h-10 w-40 mr-6" onClick={handleSave}>{isEdit || isNew ? `Save` : `Edit`}</button>
                            <button className="bg-primary-grey rounded-lg h-10 w-40 mr-6" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default RoundForm;

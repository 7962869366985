import { useEffect } from 'react';

function About ({ setNavbar, setActiveMenu }) {

    useEffect(() => {
        setActiveMenu(0);
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    return (
        <div className="w-8/12 py-3">
            <h1 className="text-2xl font-medium text-primary-black">About</h1>
            <div className="flex py-3">
                <div className="text-primary-black text-lg text-justify pr-20">
                    <p className="pb-5">REMS is a HR engagement and retention process and part of the HR Expert Toolbox.</p>
                    <p className="pb-5">
                        The web-based tool provides a platform for employees and supervisors to give valuable 
                        feedback by answering specific questions and give open feedback on certain aspects of
                        the employment at DRÄXLMAIER. REMS helps to gain transparency and provides respective
                        follow up actions based on the given feedbacks.
                    </p>
                    <p className="pb-5">
                        Objectives:
                    </p>
                    <ul className="list-disc pl-6">
                        <li className="pb-5">
                            Employer of choice and attract, retain and develop appropriately skilled and 
                            talented employees.
                        </li>
                        <li className="pb-5">
                            REMS focuses on providing HR and Management with the necessary transparency for
                            further actions for our employees. This is being achieved by evaluation the
                            current situatio with respective customized solutions and conistent follow-up.
                            With this service, HR can further strengthen the role as strtegic business partner.
                        </li>
                    </ul>
                </div>
                <div className="w-[420px] h-[420px] min-w-[420px] min-h-[420px]">
                    <svg width="420" height="420" viewBox="50 50 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <marker
                                id="arrowcrimson"
                                viewBox="0 0 10 10"
                                refX="2"
                                refY="5"
                                markerWidth="2"
                                markerHeight="2"
                                orient="auto-start-reverse"
                                fill="crimson">
                                <path d="M 0 0 L 7 5 L 0 10 z" />
                            </marker>
                            <marker
                                id="arrowgold"
                                viewBox="0 0 10 10"
                                refX="2"
                                refY="5"
                                markerWidth="2"
                                markerHeight="2"
                                orient="auto-start-reverse"
                                fill="gold">
                                <path d="M 0 0 L 7 5 L 0 10 z" />
                            </marker>
                            <marker
                                id="arrowlimegreen"
                                viewBox="0 0 10 10"
                                refX="2"
                                refY="5"
                                markerWidth="2"
                                markerHeight="2"
                                orient="auto-start-reverse"
                                fill="limegreen">
                                <path d="M 0 0 L 7 5 L 0 10 z" />
                            </marker>
                            <marker
                                id="arrowgrey"
                                viewBox="0 0 10 10"
                                refX="2"
                                refY="5"
                                markerWidth="2"
                                markerHeight="2"
                                orient="auto-start-reverse"
                                fill="#888e95">
                                <path d="M 0 0 L 7 5 L 0 10 z" />
                            </marker>
                        </defs>

                        <circle cx="300" cy="300" r="220" stroke="#2a6473" strokeWidth="60"/>

                        <line x1="300" y1="50" x2="300" y2="550" stroke="white" strokeWidth="10" />
                        <line x1="50" y1="300" x2="550" y2="300" stroke="white" strokeWidth="10" />

                        <image href={require('../assets/about.png')} x="50" y="50" height="500" width="500" />

                        <circle cx="300" cy="300" r="40" fill="#ffffff" />
                        <circle cx="300" cy="300" r="40" stroke="#0097ac" strokeWidth="2" />

                        <g transform="translate(-67.4606 -17.332) translate(300 300)">
                            <path d="M54.0706 12.5743 H65.0238 V7.47664 H69.3703C70.4135 7.47664 71.4566 7.64656 72.3259 7.81648C74.2384 8.32624 75.6293 9.51568 76.6724 11.045C77.5417 12.4043 78.0633 13.9336 78.2372 15.4629C78.4111 16.8223 78.4111 18.3516 78.2372 19.7109C77.8895 21.2402 77.3679 22.7695 76.6724 24.1289C75.8031 25.6582 74.2384 26.8476 72.3259 27.3574C71.2828 27.6972 70.2396 27.6972 69.1964 27.6972H64.8499 V22.5996 H53.8967 V34.664 H70.2396C71.8044 34.664 73.3691 34.494 74.9338 34.3241C77.194 33.9843 79.4542 33.3046 81.5405 32.1151C83.9746 30.7558 85.887 28.8866 87.1041 26.5078C88.1472 24.8085 88.6688 22.7695 89.0165 20.9004C89.3643 18.5215 89.3643 16.1426 89.0165 13.9336C88.6688 11.8946 88.1472 10.0254 87.1041 8.32624C85.887 5.94735 83.9746 4.07822 81.5405 2.71885C79.4542 1.52941 77.194 0.849727 74.9338 0.509885C73.3691 0.339965 71.8044 0.170044 70.2396 0.170044H53.8967 V12.5743 H54.0706Z" fill="#1E2728" />
                        </g>

                        <path id="curvetop" d="M 95 300 A 205 205 0 0 1 505 300" />
                        <text fill="white" style={{font: '40px Helvetica Neue LT Pro'}}>
                            <textPath href="#curvetop">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;J O B&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LEADER</textPath>
                        </text>

                        <path id="curvebtm" d="M 65 300 A 235 235 0 0 0 535 300" />
                        <text fill="white" style={{font: '40px Helvetica Neue LT Pro'}}>
                            <textPath href="#curvebtm">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;COMPANY&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;REWARDS</textPath>
                        </text>

                        <g transform="rotate(45 300 300)">
                            <g transform="translate(250,300)">
                                <path fill="#888e95" d="M 1 0 l 0 -16 l -88 0 l 0 -16 l -45 32 l 45 32 l 0 -16 l 88 0 z"></path>
                                <path fill="limegreen" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 42 30 l 0 -15 l 88 0 z"></path>
                                <path fill="#42be42" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 130 0 z"></path>
                                <path fill="#28a428" d="M -103 0 l 15 -15 l 0 -15 l -15 30 l 15 30 l 0 -15 l -15 -15 z"></path>
                                <path fill="#28a428" d="M 0 0 l 0 -15 l -15 15 l 15 15 z"></path>
                            </g>
                        </g>
                        <g transform="rotate(135 300 300)">
                            <g transform="translate(250,300)">
                                <path fill="#888e95" d="M 1 0 l 0 -16 l -88 0 l 0 -16 l -45 32 l 45 32 l 0 -16 l 88 0 z"></path>
                                <path fill="gold" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 42 30 l 0 -15 l 88 0 z"></path>
                                <path fill="#e6c619" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 130 0 z"></path>
                                <path fill="#ccac00" d="M -103 0 l 15 -15 l 0 -15 l -15 30 l 15 30 l 0 -15 l -15 -15 z"></path>
                                <path fill="#ccac00" d="M 0 0 l 0 -15 l -15 15 l 15 15 z"></path>
                            </g>
                        </g>
                        <g transform="rotate(225 300 300)">
                            <g transform="translate(250,300)">
                                <path fill="#888e95" d="M 1 0 l 0 -16 l -88 0 l 0 -16 l -45 32 l 45 32 l 0 -16 l 88 0 z"></path>
                                <path fill="crimson" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 42 30 l 0 -15 l 88 0 z"></path>
                                <path fill="#c82848" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 130 0 z"></path>
                                <path fill="#b01030" d="M -103 0 l 15 -15 l 0 -15 l -15 30 l 15 30 l 0 -15 l -15 -15 z"></path>
                                <path fill="#b01030" d="M 0 0 l 0 -15 l -15 15 l 15 15 z"></path>
                            </g>
                        </g>
                        <g transform="rotate(315 300 300)">
                            <g transform="translate(250,300)">
                                <path fill="#888e95" d="M 1 0 l 0 -16 l -88 0 l 0 -16 l -45 32 l 45 32 l 0 -16 l 88 0 z"></path>
                                <path fill="limegreen" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 42 30 l 0 -15 l 88 0 z"></path>
                                <path fill="#42be42" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 130 0 z"></path>
                                <path fill="#28a428" d="M -103 0 l 15 -15 l 0 -15 l -15 30 l 15 30 l 0 -15 l -15 -15 z"></path>
                                <path fill="#28a428" d="M 0 0 l 0 -15 l -15 15 l 15 15 z"></path>
                            </g>
                        </g>

                    </svg>
                </div>
            </div>
        </div>
    );   
}

export default About;
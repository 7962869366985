//import { useState, useEffect, Fragment } from "react";
import { Fragment } from "react";
import { BsCaretDownFill } from 'react-icons/bs';
//import { Link, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
//import api from "./Api";

function Navbar ({ language, translation, activeMenu }) {
//    const [languages, setLanguages] = useState([]);
//    const location = useLocation();
/*
    useEffect(() => {
        const fetchLanguage = async () => {
            try {
                const response = await api.get('/translation');
                setLanguages(response.data);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchLanguage();
    },[]);
 */

    return (
        <Fragment>
            <div className="mr-auto flex bg-[#4d4d4d]">
                <div className="h-[50px] pl-[6px] w-full">
                    <ul className="mn h-[50px]">
                        <li className="mn-item group">
                            <div className={`${(activeMenu === 1) ? "text-primary-white " : ""}flex cursor-pointer h-[50px] items-center justify-center`}>Round<br />Management<BsCaretDownFill className="w-[28px]" /></div>
                            <ul className="dd group-hover:block">
                                <li><Link className="dd-item" to={`/${language}/round`}>Round Overview</Link></li>
                                <li><hr className="dd-separator" /></li>
                                <li><Link className="dd-item" to={`/${language}/personnel`}>Employee Information</Link></li>
                            </ul>
                        </li>
                        <li className="mn-item group" >
                            <div className={`${(activeMenu === 2) ? "text-primary-white " : ""}flex cursor-pointer h-[50px] items-center justify-center`}>Key<br />Identification<BsCaretDownFill className="w-[28px]" /></div>
                            <ul className="dd group-hover:block">
                                <li><Link className="dd-item" to={`/${language}/send-assessment`}>Send Assessment</Link></li>
                            </ul>
                        </li>
                        <li className="mn-item group" >
                            <div className={`${(activeMenu === 3) ? "text-primary-white " : ""}flex cursor-pointer h-[50px] items-center justify-center`}>Questionnaire<BsCaretDownFill className="w-[28px]" /></div>
                            <ul className="dd group-hover:block">
                                <li><Link className="dd-item" to={`/${language}/send-questionnaire`}>Send Questionnaire</Link></li>
                            </ul>
                        </li>                    
                        <li className="mn-item group" >
                            <div className={`${(activeMenu === 4) ? "text-primary-white " : ""}flex cursor-pointer h-[50px] items-center justify-center`}>R&E Profile<BsCaretDownFill className="w-[28px]" /></div>
                            <ul className="dd group-hover:block">
                                <li><Link className="dd-item" to={`/${language}/profile/team`}>Team Profile</Link></li>
                                <li><hr className="dd-separator" /></li>
                                <li><Link className="dd-item" to={`/${language}/profile/individual`}>Individual Profile</Link></li>
                                <li><hr className="dd-separator" /></li>
                                <li><Link className="dd-item" to={`/${language}/profile/summary`}>Management Summary</Link></li>
                            </ul>
                        </li>                    
                        <li className="mn-item group" >
                            <div className={`${(activeMenu === 5) ? "text-primary-white " : ""}flex cursor-pointer h-[50px] items-center justify-center`}>Toolbox &<br />Follow-up<BsCaretDownFill className="w-[28px]" /></div>
                            <ul className="dd group-hover:block">
                                <li><Link className="dd-item" to={`/${language}/follow-up`}>Follow-Up</Link></li>
                                <li><hr className="dd-separator" /></li>
                                <li><Link className="dd-item" to={`/${language}/status-report`}>Status Report</Link></li>
                            </ul>
                        </li>
                        <li className="basis-full h-0" />
                        <li className="mn-item group border-l border-r-0" >
                            <div className={`${(activeMenu === 6) ? "text-primary-white " : ""}flex cursor-pointer h-[50px] items-center justify-center ml-auto mr-0`}>Admin<br />Center<BsCaretDownFill className="w-[28px]" /></div>
                            <ul className="ddr group-hover:block">
                                <li><Link className="dd-item" to={`/${language}/assessment-template`}>Assessment Template</Link></li>
                                <li><hr className="dd-separator" /></li>
                                <li><Link className="dd-item" to={`/${language}/questionnaire-template`}>Questionnaire Template</Link></li>
                                <li><hr className="dd-separator" /></li>
                                <li><Link className="dd-item" to={`/${language}/question`}>Question Management</Link></li>
                                <li><hr className="dd-separator" /></li>
                                <li><Link className="dd-item" to={`/${language}/toolbox`}>Toolbox Management</Link></li>
                            </ul>
                        </li>                    
                    </ul>
                </div>
{/*
                <div className="text-gray-400 text-base font-medium pt-[3px] pr-1 text-right float-right">
                    {languages.map((_language, index) => (
                        <Link key={index} className={`${language === _language.language_code.toLowerCase() ? "text-primary-white" : "hover:text-primary-white"} ${index !==0 && "border-l"} px-2 border-gray-500`} to={`/`+ _language.language_code.toLowerCase() + location.pathname.substring(3)}>{_language.language_code}</Link>
                    ))}   
                </div>
*/}
            </div>

            <div className="h-[46px] -mt-[12px] bg-arrow bg-no-repeat bg-left-bottom bg-transparent" />

        </Fragment>
    );
}

export default Navbar;
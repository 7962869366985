import { Fragment, useEffect, useState } from 'react';
import { BsChevronRight, BsChevronDown, BsCardText } from 'react-icons/bs';
import api from "../Api";
import Loading from "../Loading";
import { Link, Outlet } from 'react-router-dom';

function FollowUp({ language, setNavbar, setActiveMenu, forceRefresh, setForceRefresh, currentRound, setCurrentRound, setSelectedforReport, setCurrentQuestions }) {
    const [isLoading, setIsLoading] = useState(true);
    const [rounds, setRounds] = useState([]);
    const [personnels, setPersonnels] = useState([]);
    const [assignedTool, setAssignedTool] = useState([]);
    const [group_id, setGroup_id] = useState([]);
    const [guids, setGuids] = useState([]);
    const [templates, setTemplates] = useState([]);


    useEffect(() => {
        setActiveMenu(5);
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    useEffect(() => {
        const fetchRounds = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/round_name?key=${forceRefresh}`);
                setRounds(response.data);
                const response2 = await api.get(`/template/followup?key=${forceRefresh}`);
                setTemplates(response2.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchRounds();
    },[forceRefresh]);

    useEffect(() => {
        const hierarchized = (personnels, manager_id, name_of_manager, level, group_id) => {
            const result = [];
            personnels.filter((personnel) => personnel.name_of_manager === name_of_manager)
                .sort((a,b) => (a.full_name > b.full_name) ? 1 : ((b.full_name > a.full_name) ? -1 : 0))
                .map((personnel, index) => {
                    const hierarchy = hierarchized(personnels, personnel.id, personnel.full_name, level + 1, group_id === '' ? index + '' : (group_id + '.' + index));
                    result.push({...personnel, level: level, group_id: group_id === '' ? index + '' : (group_id + '.' + index), has_child: hierarchy.length > 0, manager_id: manager_id, collapse: false, selected: false});
                    hierarchy.map((personnel) => result.push(personnel));
                    return personnel;
                });
            return result;
        };

        const fetchPersonnels = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/overview/round/${currentRound.id}?key=${forceRefresh}`);
                setPersonnels(hierarchized(response.data, 0, '', 0, ''));
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        };

        fetchPersonnels();
    },[currentRound, forceRefresh]);

    useEffect(() => {
        const fetchAssignedTool = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/assigned-tool/round/${currentRound.id}?key=${forceRefresh}`);
                setAssignedTool(response.data);
                const response1 = await api.get(`/assigned-tool/guid/round/${currentRound.id}?key=${forceRefresh}`);
                setGuids(response1.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        };

        fetchAssignedTool();
    },[currentRound, forceRefresh]);

    const handleRound = (e) => {
        const roundId = e.target.options[e.target.options.selectedIndex].getAttribute('data-key');
        if (roundId !== '0')
            setCurrentRound(rounds.find(({ id }) => id === Number(roundId)));
        else
            setCurrentRound({id: 0, name: '', status:''});
    }

    const handleCollapse = (id) => {
        const newpersonnel = personnels.map((personnel) => {
            if (personnel.id === id) {
                if (personnel.collapse)
                    setGroup_id(group_id.filter(group_id => group_id.label !== personnel.group_id))
                else
                    setGroup_id([...group_id, {label: personnel.group_id}]);
                return {...personnel, collapse: !personnel.collapse};
            } else
                return personnel;
        });
        setPersonnels(newpersonnel);
    }

    const filterLogic = (personnel) => {
        if (group_id.length !==0 && group_id.find(({ label }) => String(personnel.group_id).startsWith(label) && personnel.group_id !== label)) return false;
        return true;
    }

    const checkSelectAll = (assignedTool) => {
        let selectAll = assignedTool.length !== 0 ? true : false;
        let countTrue = 0;
        assignedTool.forEach(item => {
            if (filterLogic(item) &&  (item.selected === false || item.selected === 0) ) selectAll = false;
            if (filterLogic(item) &&  item.selected === true) countTrue++;
        });
        return countTrue > 0 ? selectAll : false;
    }

    const handleSelectAll = (e) => {
        setAssignedTool(assignedTool.map(item => ({ ...item, selected: e.target.checked })));
/*
        setPersonnels(
            personnels.map(item => filterLogic(item) && item.name_of_manager !=='' ? { ...item, selected: e.target.checked } : item)
        );
*/
    }

    const handleSelect = (e, id) => {
        setAssignedTool(assignedTool.map(item => item.responsible_id === id ? { ...item, selected: e.target.checked } : item));
    }

    const saveSelected = async (assignedTool) => {
        try {
            setIsLoading(true);
            const response = await api.put('/send-follow-up', assignedTool);
            console.log(response);
            setForceRefresh(crypto.randomUUID());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const sendSelected = async (personnels) => {
        try {
            setIsLoading(true);
            const response = api.post('/sendmail',personnels);
            console.log(response);
            setForceRefresh(crypto.randomUUID());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSend = () => {
        saveSelected(
            assignedTool
                .filter(tool => tool.selected === true)
                .map((tool) => { return Object.values({guid: guids.find(guid => guid.responsible_id === tool.responsible_id).guid, id: tool.id })})
        );

        sendSelected(
            assignedTool
                .filter(tool => tool.selected === true)
                .sort((a,b) => (a.responsible_id > b.responsible_id) ? 1 : ((b.responsible_id > a.responsible_id) ? -1 : 0))
                .filter((item, pos, ary) => {return !pos || item.responsible_id !== ary[pos - 1].responsible_id;})
                .map(tool => {
                    let personnel = personnels.find(personnel => personnel.id === tool.responsible_id);
                    let msg = templates.find(({feature, item}) => feature === 'followup' && item === 'followhtml').t_text
                        .replace('{{link}}','https://rems.virtuosity.sg/'+ language + '/follow-up/'+ guids.find(guid => guid.responsible_id === tool.responsible_id).guid)
                        .replace('{{Name}}', personnel.full_name)
                        .replace('{{E-mail}}', personnel.email)
                        .replace('{{tools}}',`<table><tbody><tr><th>Assigned To</th><th>Retention Range</th><th>Assigned Tool</th><th>Due Date</th><th>Status</th></tr>${assignedTool.filter(t => t.responsible_id === tool.responsible_id ).map(tool => {return `<tr><td>${personnels.find(p => p.id === tool.personnel_id).full_name}</td><td>${tool.retention_individual === 1 ? "Individual" : tool.retention_department === 1 ? "Team" : tool.retention_company === 1 ? "Dept/Company" : ""}</td><td>${tool.assigned_tool}</td><td>${tool.due_date}</td><td>${tool.status}</td></tr>`}).join("")}</tbody></table>`);
//                    console.log(assignedTool.filter(t => t.responsible_id === tool.responsible_id ).map(tool => {return `<tr><td>${personnels.find(p => p.id === tool.personnel_id).full_name}</td><td>${tool.retention_individual === 1 ? "Individual" : tool.retention_department === 1 ? "Team" : tool.retention_company === 1 ? "Dept/Company" : ""}</td><td>${tool.assigned_tool}</td><td>${tool.due_date}</td><td>${tool.status}</td></tr>`}).join(""));
                    return { 
                        name: personnel.full_name, 
                        email: personnel.email,
                        subject: templates.find(({feature, item}) => feature === 'followup' && item === 'followsub').t_text,
                        email_html: msg
                    }
                })
        );
    }

    return (
        <div className="w-11/12 py-3">
            <div className="flex flex-row">
                <h1 className="text-2xl font-medium text-primary-black">Follow Up</h1>
                <select className="ml-3 p-1 border rounded-sm w-2/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={currentRound.name}
                    onChange={(e) => handleRound(e)} >
                    <option data-key='0' value=''></option>
                    {rounds.map((round, index) => (
                        <option key={index} data-key={round.id} value={round.name}>{round.name}</option>
                    ))}
                </select>
            </div>
            <div className="flex flex-row text-primary-black py-3">
                <Link to={`/${language}/follow-up/assigned-tool/new`}>
                    <button className="bg-primary-green text-primary-white text-lg font-medium px-10 py-1 rounded-lg mr-2">Add Tool</button>
                </Link>
                <button className="bg-primary-green text-primary-white text-lg font-medium px-10 py-1 rounded-lg mr-2" onClick={handleSend}>Send</button>
            </div>
            <div className="pt-8">

                <table className="text-primary-black border-primary-grey text-base text-left w-full">
                    <tbody>
                        <tr className="bg-gray-300">
                            <th className="border-2 px-2">Responsible for Follow-up</th>
                            <th className="border-2 px-2">Assigned to</th>
                            <th className="border-2 w-1/12 px-2">Retention Range</th>
                            <th className="border-2 px-2">Assigned Tool</th>
                            <th className="border-2 w-1/12 px-2">Due Date</th>
                            <th className="border-2 w-1/12 px-2">Status</th>
                            <th className="border-2 w-1/12 px-2">Sent</th>
                            <th className="border-2 w-12">                                
                                <div className="flex flex-row items-center justify-center">
                                    <input id="selectall" type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSelectAll(assignedTool)} onChange={handleSelectAll} />
                                </div>
                            </th>
                        </tr>
                        {personnels.sort((a, b) => (a.group_id > b.group_id) ? 1 : ((b.group_id > a.group_id) ? -1 : 0))
                            .filter((personnel) => personnel.has_child === true &&
                                (assignedTool.filter(tool => tool.personnel_id === personnel.id && (tool.retention_company === 1 || tool.retention_department === 1)).length > 0 || 
                                    personnels.filter(subordinate => subordinate.group_id.startsWith(personnel.group_id) && subordinate.level === (personnel.level + 1) && assignedTool.filter(tool => tool.personnel_id === subordinate.id && tool.retention_individual === 1).length > 0).length > 0
                                )
                            )
                            .filter((personnel) => filterLogic(personnel))
                            .map((supervisor, index1) => (

                            personnels.filter(personnel => ((personnel.id === supervisor.id && assignedTool.filter(tool => tool.personnel_id === supervisor.id && (tool.retention_company === 1 || tool.retention_department === 1)).length > 0) || 
                                (String(personnel.group_id).startsWith(supervisor.group_id) && personnel.level === (supervisor.level + 1) && assignedTool.filter(tool => tool.personnel_id === personnel.id && tool.retention_individual === 1).length > 0 )))
                                .map((staffplus, index2) => (

                                assignedTool.filter(tool => (tool.personnel_id === supervisor.id && tool.personnel_id === staffplus.id && (tool.retention_company === 1 || tool.retention_department === 1)) || (tool.personnel_id !== supervisor.id && tool.personnel_id === staffplus.id && tool.retention_individual === 1)).map((tool, index3) => (
                                    <tr key={index1 + '-' + index2 + '-' + index3} className={`${index1 % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                                        {index2 === 0 && index3 === 0 &&
                                            <td className="border-2" rowSpan={
                                                personnels.filter(personnel => ((personnel.id === supervisor.id && assignedTool.filter(tool => tool.personnel_id === supervisor.id && (tool.retention_company === 1 || tool.retention_department === 1)).length > 0) || 
                                                    (String(personnel.group_id).startsWith(supervisor.group_id) && personnel.level === (supervisor.level + 1) && assignedTool.filter(tool => tool.personnel_id === personnel.id && tool.retention_individual === 1).length > 0 )))
                                                    .reduce((a, personnel) => (a + assignedTool.filter(tool => (tool.personnel_id === personnel.id && personnel.id === supervisor.id && (tool.retention_company === 1 || tool.retention_department === 1)) || (tool.personnel_id === personnel.id && personnel.id !== supervisor.id && tool.retention_individual === 1)).length ), 0)
                                                }>
                                                <div className="flex flex-row pr-2 cursor-default">
                                                    {String('\u00A0').repeat(supervisor.level * 5)}
                                                    <div className="w-6">
                                                        {supervisor.has_child &&
                                                            personnels.find(personnel => String(personnel.group_id).startsWith(supervisor.group_id) && personnel.level === supervisor.level + 1 && assignedTool.filter(tool => tool.personnel_id === personnel.id && (tool.retention_company === 1 || tool.retention_department === 1)).length > 0) && 
                                                            <button className="text-primary-black px-1 h-6 border-0 m-0" onClick={() => handleCollapse(supervisor.id)}>{supervisor.collapse !== true ? <BsChevronDown /> : <BsChevronRight />}</button>}
                                                    </div>
                                                    {supervisor.full_name}
                                                </div>
                                            </td>
                                        }
                                        {index3 === 0 &&
                                            <td className="border-2" rowSpan={assignedTool.filter(tool => (tool.personnel_id === supervisor.id && tool.personnel_id === staffplus.id && (tool.retention_company === 1 || tool.retention_department === 1)) || (tool.personnel_id !== supervisor.id && tool.personnel_id === staffplus.id && tool.retention_individual === 1)).length}>
                                                <div className="px-2 cursor-default">{staffplus.full_name}</div>
                                            </td>
                                        }
                                        <td className="border-2">
                                            <Link to={`/${language}/follow-up/assigned-tool/${tool.id}`}><div className="px-2">{tool.retention_individual === 1 && "Individual"}{tool.retention_department === 1 && "Team"}{tool.retention_company === 1 && "Dept/Company"}</div></Link>
                                        </td>
                                        <td className="border-2">
                                            <Link to={`/${language}/follow-up/assigned-tool/${tool.id}`}><div className="px-2">{tool.assigned_tool}<div className="inline-block mx-1"><Link to={`/${language}/follow-up/tool/${tool.toolbox_id}`}><BsCardText /></Link></div></div></Link>
                                        </td>
                                        <td className="border-2"><Link to={`/${language}/follow-up/assigned-tool/${tool.id}`}><div className="px-2">{tool.due_date}</div></Link></td>
                                        <td className="border-2"><Link to={`/${language}/follow-up/assigned-tool/${tool.id}`}><div className="px-2">{tool.status}</div></Link></td>
                                        {index2 === 0 && index3 === 0 &&
                                            <Fragment>
                                                <td className="border-2" rowSpan={
                                                    personnels.filter(personnel => ((personnel.id === supervisor.id && assignedTool.filter(tool => tool.personnel_id === supervisor.id && (tool.retention_company === 1 || tool.retention_department === 1)).length > 0) || 
                                                        (String(personnel.group_id).startsWith(supervisor.group_id) && personnel.level === (supervisor.level + 1) && assignedTool.filter(tool => tool.personnel_id === personnel.id && tool.retention_individual === 1).length > 0 )))
                                                        .reduce((a, personnel) => (a + assignedTool.filter(tool => (tool.personnel_id === personnel.id && personnel.id === supervisor.id && (tool.retention_company === 1 || tool.retention_department === 1)) || (tool.personnel_id === personnel.id && personnel.id !== supervisor.id && tool.retention_individual === 1)).length ), 0)
                                                    }>
                                                    <div className="px-2 cursor-default">{tool.send_count}</div>
                                                </td>
                                                <td className="border-2" rowSpan={
                                                    personnels.filter(personnel => ((personnel.id === supervisor.id && assignedTool.filter(tool => tool.personnel_id === supervisor.id && (tool.retention_company === 1 || tool.retention_department === 1)).length > 0) || 
                                                        (String(personnel.group_id).startsWith(supervisor.group_id) && personnel.level === (supervisor.level + 1) && assignedTool.filter(tool => tool.personnel_id === personnel.id && tool.retention_individual === 1).length > 0 )))
                                                        .reduce((a, personnel) => (a + assignedTool.filter(tool => (tool.personnel_id === personnel.id && personnel.id === supervisor.id && (tool.retention_company === 1 || tool.retention_department === 1)) || (tool.personnel_id === personnel.id && personnel.id !== supervisor.id && tool.retention_individual === 1)).length ), 0)
                                                    }>
                                                    <div className="flex flex-row items-center justify-center">
                                                        <input id={"select" + index1 + '-' + index2 + '-' + index3} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected } onChange={(e) => handleSelect(e, supervisor.id)} />
                                                    </div>
                                                </td>
                                            </Fragment>
                                        }
                                    </tr>
                                ))
                            ))
                        ))}
{/*}                        
                        {personnels.sort((a,b) => (a.group_id > b.group_id) ? 1 : ((b.group_id > a.group_id) ? -1 : 0)).filter((personnel) => filterLogic(personnel)).map((personnel, index) => (
                            <Fragment key={index}>
                                {personnels.filter((item) => (String(item.group_id).startsWith(personnel.group_id) && item.level === personnel.level + 1) || item.id === personnel.id).map((p,index2) => (
                                    assignedTool.filter(tool => (tool.personnel_id === p.id && p.level === personnel.level && (tool.retention_department === 1 || tool.retention_company === 1)) || 
                                        (tool.personnel_id === p.id && p.level === personnel.level + 1 && tool.retention_individual === 1)).length === 0 ?
                                        <tr key={index + '-0'} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                                            <td className="border-2">
                                                <div className="flex flex-row pr-2 cursor-default">
                                                    {!filter_mode &&
                                                        <Fragment> 
                                                            {String('\u00A0').repeat(personnel.level * 5)}
                                                            <div className="w-6">
                                                                {personnel.has_child && <button className="text-primary-black px-1 h-6 border-0 m-0" onClick={() => handleCollapse(personnel.id)}>{personnel.collapse ? <BsChevronRight /> : <BsChevronDown />}</button>}
                                                            </div>
                                                        </Fragment>
                                                    }
                                                    {personnel.full_name}
                                                </div>
                                            </td>
                                            <td className="border-2"><div className="flex flex-row px-2 cursor-default">{personnel.organization_unit}</div></td>
                                            <td className="border-2"><div className="flex flex-row px-2 cursor-default justify-end">{personnel.fes_level}</div></td>
                                            <td className="border-2"><div className={`flex flex-row px-2 cursor-default${personnel.key_status === 'Key'? ' text-green-500' : ''}${personnel.key_status === 'Non-key'? ' text-primary-red' : ''}`}>{personnel.key_status}</div></td>
                                            <td className="border-2"><div className="flex flex-col px-2 cursor-default"></div></td>
                                            <td className="border-2"><div className="flex flex-row px-2 cursor-default"></div></td>
                                            <td className="border-2"><div className="flex flex-col px-2 cursor-default"></div></td>
                                            <td className="border-2"><div className="flex flex-row px-2 cursor-default"></div></td>
                                            <td className="border-2"><div className="flex flex-row px-2 cursor-default"></div></td>
                                        </tr>:
                                        <Fragment>
                                            {assignedTool.filter(tool => (tool.personnel_id === p.id && p.level === personnel.level && (tool.retention_department === 1 || tool.retention_company === 1)) || 
                                                (tool.personnel_id === p.id && p.level === personnel.level + 1 && tool.retention_individual === 1)).map((tool, index3) => (
                                                <tr key={index + '-' + index3} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                                                    {index2 === 0 &&
                                                        <Fragment>
                                                            <td className="border-2" rowSpan={assignedTool.filter(tool => (tool.personnel_id === p.id && p.level === personnel.level && (tool.retention_department === 1 || tool.retention_company === 1)) || 
                                                                (tool.personnel_id === p.id && p.level === personnel.level + 1 && tool.retention_individual === 1)).length === 0 ? 1 : assignedTool.filter(tool => (tool.personnel_id === p.id && p.level === personnel.level && (tool.retention_department === 1 || tool.retention_company === 1)) || 
                                                                (tool.personnel_id === p.id && p.level === personnel.level + 1 && tool.retention_individual === 1)).length}>
                                                                <div className="flex flex-row pr-2 cursor-default">
                                                                    {!filter_mode &&
                                                                        <Fragment> 
                                                                            {String('\u00A0').repeat(personnel.level * 5)}
                                                                            <div className="w-6">
                                                                                {personnel.has_child && <button className="text-primary-black px-1 h-6 border-0 m-0" onClick={() => handleCollapse(personnel.id)}>{personnel.collapse ? <BsChevronRight /> : <BsChevronDown />}</button>}
                                                                            </div>
                                                                        </Fragment>
                                                                    }
                                                                    {personnel.full_name}
                                                                </div>
                                                            </td>
                                                            <td className="border-2" rowSpan={assignedTool.filter(tool => (tool.personnel_id === p.id && p.level === personnel.level && (tool.retention_department === 1 || tool.retention_company === 1)) || 
                                                                (tool.personnel_id === p.id && p.level === personnel.level + 1 && tool.retention_individual === 1)).length === 0 ? 1 : assignedTool.filter(tool => (tool.personnel_id === p.id && p.level === personnel.level && (tool.retention_department === 1 || tool.retention_company === 1)) || 
                                                                (tool.personnel_id === p.id && p.level === personnel.level + 1 && tool.retention_individual === 1)).length}><div className="flex flex-row px-2 cursor-default">{personnel.organization_unit}</div></td>
                                                            <td className="border-2" rowSpan={assignedTool.filter(tool => (tool.personnel_id === p.id && p.level === personnel.level && (tool.retention_department === 1 || tool.retention_company === 1)) || 
                                                                (tool.personnel_id === p.id && p.level === personnel.level + 1 && tool.retention_individual === 1)).length === 0 ? 1 : assignedTool.filter(tool => (tool.personnel_id === p.id && p.level === personnel.level && (tool.retention_department === 1 || tool.retention_company === 1)) || 
                                                                (tool.personnel_id === p.id && p.level === personnel.level + 1 && tool.retention_individual === 1)).length}><div className="flex flex-row px-2 cursor-default justify-end">{personnel.fes_level}</div></td>
                                                            <td className="border-2" rowSpan={assignedTool.filter(tool => (tool.personnel_id === p.id && p.level === personnel.level && (tool.retention_department === 1 || tool.retention_company === 1)) || 
                                                                (tool.personnel_id === p.id && p.level === personnel.level + 1 && tool.retention_individual === 1)).length === 0 ? 1 : assignedTool.filter(tool => (tool.personnel_id === p.id && p.level === personnel.level && (tool.retention_department === 1 || tool.retention_company === 1)) || 
                                                                (tool.personnel_id === p.id && p.level === personnel.level + 1 && tool.retention_individual === 1)).length}><div className={`flex flex-row px-2 cursor-default${personnel.key_status === 'Key'? ' text-green-500' : ''}${personnel.key_status === 'Non-key'? ' text-primary-red' : ''}`}>{personnel.key_status}</div></td>
                                                        </Fragment>
                                                    }
                                                    <td className="border-2"><div className="flex flex-col px-2 cursor-default">{tool.assigned_tool} {tool.retention_individual === 1 && "(" + p.full_name + ")"}</div></td>
                                                    <td className="border-2"><div className="flex flex-row px-2 cursor-default"></div></td>
                                                    <td className="border-2"><div className="flex flex-col px-2 cursor-default">{tool.retention_individual === 1 && "Individual"}{tool.retention_department === 1 && "Team"}{tool.retention_company === 1 && "Dept/Company"}</div></td>
                                                    <td className="border-2"><div className="flex flex-row px-2 cursor-default"></div></td>
                                                    <td className="border-2"><div className="flex flex-row px-2 cursor-default"></div></td>
                                                </tr>
                                            ))}
                                        </Fragment>

                                ))}
                            </Fragment>

                        ))}
                                                */}
                    </tbody>
                </table>
            </div>
            <Outlet />
            {isLoading && <Loading /> }
{/*
            {showDialog && <Dialog title="Show Profile" setShowDialog={setShowDialog} caption="Please select any employee." /> }
*/}
        </div>
    );
}

export default FollowUp;
import { useEffect, useState } from 'react';
import api from "../Api";
import { useParams } from 'react-router-dom';
import Loading from "../Loading";
import Dialog from "../Dialog";

function Assessment({setNavbar, forceRefresh, setForceRefresh}) {
    const { guid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [personnels, setPersonnels] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [enableSaveSubmit, setEnableSaveSubmit] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        setNavbar(false);
    },[setNavbar]);

    useEffect(() => {
        const fetchPersonnels = async () => {
            try {
                const response = await api.get(`/assessment/${guid}?key=${forceRefresh}`);
                setPersonnels(response.data);
                const response2 = await api.get(`/template/assessment?key=${forceRefresh}`);
                setTemplates(response2.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        fetchPersonnels();
    },[guid, forceRefresh]);

    useEffect(() => {
        if (personnels.find(personnel => personnel.status !== 'Submitted') !== undefined) 
            setEnableSaveSubmit(true);
        else
            setEnableSaveSubmit(false);
    },[personnels]); 

    const saveSubmitted = async (personnels) => {
        try {
            setIsLoading(true);
            const response = await api.put('/assessment/submit',personnels);
            console.log(response);
            setForceRefresh(Date.now());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const saveAnswered = async (personnels) => {
        try {
            setIsLoading(true);
            const response = await api.put('/assessment/save',personnels);
            console.log(response);
            setForceRefresh(Date.now());
            setIsLoading(false);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSubmit = () => {
        if (personnels.find(personnel => personnel.critical_skill === null || personnel.unique_role === null) !== undefined) 
            setShowDialog(true);
        else
            saveSubmitted(personnels.map(({assessment_id, critical_skill, unique_role, ...personnel}) => { return Object.values({critical_skill, unique_role, assessment_id}) }));
    }

    const handleSave = () => {
        saveAnswered(personnels.map(({assessment_id, critical_skill, unique_role, ...personnel}) => { return Object.values({critical_skill, unique_role, assessment_id}) }));
    }

    return (
        <div className="w-11/12 py-3">
            <h1 className="text-2xl font-medium text-primary-black">Key Identification Assessment</h1>
            <div>
                <div className="text-lg text-primary-black my-3">
                    <p dangerouslySetInnerHTML={{__html:`${!isLoading ? templates.find(({feature, item}) => feature === 'assessment' && item === 'assesstext').t_text.replace(/\n/g, '<br />') : ''}` }} />
                </div>
                <div className="flex flex-row text-primary-black py-3">
                    <button className={`${enableSaveSubmit ? 'bg-primary-green':'bg-primary-grey'} text-primary-white text-lg px-10 py-1 rounded-lg mr-2`}
                        disabled={!enableSaveSubmit} onClick={handleSave}>Save</button>
                    <button className={`${enableSaveSubmit ? 'bg-primary-green':'bg-primary-grey'} text-primary-white text-lg px-10 py-1 rounded-lg mr-2`}
                        disabled={!enableSaveSubmit} onClick={handleSubmit}>Submit</button>
                </div>
                <div className="mt-8"></div>
                <table className="text-primary-black border-primary-grey text-base text-left w-full">
                    <tbody>
                        <tr className="bg-gray-300">
                            <th className="border-2 w-4/12 px-2">Name</th>
                            <th className="border-2 w-4/12 px-2">
                                {!isLoading ? templates.find(({feature, item}) => feature === 'assessment' && item === 'assesscat1').t_text : ''}
                            </th>
                            <th className="border-2 w-4/12 px-2">
                                {!isLoading ? templates.find(({feature, item}) => feature === 'assessment' && item === 'assesscat2').t_text : ''}
                            </th>
                        </tr>
                        {personnels.map((personnel, index) => (
                            <tr key={personnel.id} className={index % 2 === 0? `bg-white` : `bg-gray-100`}>
                                <td className="border-2"><div className="flex flex-row px-2">{personnel.full_name}</div></td>
                                <td className="border-2"><div className="flex flex-row align-middle justify-center">
                                    {personnel.status !== 'Submitted' &&
                                        <select className="p-1 border rounded-sm float-left disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={personnel.critical_skill_text} 
                                            onChange={e => setPersonnels(personnels.map(item => item.id === personnel.id ? { ...item, critical_skill: (e.target.value === 'Yes')? 1 : (e.target.value === 'No')? 0 : null, critical_skill_text: e.target.value } : item)) } >
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    }
                                    {personnel.status === 'Submitted' &&
                                        <span>{personnel.critical_skill_text}</span>
                                    }
                                </div></td>
                                <td className="border-2"><div className="flex flex-row align-middle justify-center">
                                    {personnel.status !== 'Submitted' &&
                                        <select className="p-1 border rounded-sm float-left disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={personnel.unique_role_text} 
                                            onChange={e => setPersonnels(personnels.map(item => item.id === personnel.id ? { ...item, unique_role: (e.target.value === 'Yes')? 1 : (e.target.value === 'No')? 0 : null, unique_role_text: e.target.value } : item)) } >
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    }
                                    {personnel.status === 'Submitted' &&
                                        <span>{personnel.unique_role_text}</span>
                                    }
                                </div></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {isLoading && <Loading /> }
            {showDialog && <Dialog title="Submit" setShowDialog={setShowDialog} caption="Please answer all the assessment with 'Yes' of 'No' before submitting. Or you can click save to save your assessment temporarily." /> }
        </div>
    );
}

export default Assessment;
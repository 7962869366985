import { useEffect, useState } from 'react';
import api from '../Api';
import { Link, Outlet } from 'react-router-dom';
import Loading from '../Loading';

function Toolbox({setActiveMenu, setNavbar, forceRefresh, language, currentQTemplate, setCurrentQTemplate}) {
    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [toolboxes, setToolboxes] = useState([]);

    useEffect(() => {
        setActiveMenu(6);
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    useEffect(() => {
        const fetchRounds = async () => {
            try {
                setIsLoading1(true);
                const response = await api.get(`/question-template?key=${forceRefresh}`);
                setTemplates(response.data);
                setIsLoading1(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchRounds();
    },[forceRefresh]);


    useEffect(() => {
        const fetchToolboxes = async () => {
            try {
                const response = await api.get(`/toolbox/template/${currentQTemplate.id}?key=${forceRefresh}`);
                setToolboxes(response.data);
                setIsLoading2(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        fetchToolboxes();
    },[currentQTemplate, forceRefresh]);

    const handleTemplate = (e) => {
        const templateId = e.target.options[e.target.options.selectedIndex].getAttribute('data-key');
        if (templateId !== '0')
            setCurrentQTemplate(templates.find(({ id }) => id === Number(templateId)));
        else
            setCurrentQTemplate({id: 0, name: ''});
    }


    return (
        <div className="w-11/12 py-3">
            <div className="flex flex-row">
                <h1 className="text-2xl font-medium text-primary-black">Toolbox - </h1>
                <select id="template" className="ml-3 p-1 border rounded-sm w-2/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={currentQTemplate.name}
                    onChange={(e) => handleTemplate(e)} >
                    <option data-key='0' value=''></option>
                    {templates.map((template, index) => (
                        <option key={index} data-key={template.id} value={template.name}>{template.name}</option>
                    ))}
                </select>
            </div>
            <div>
                <div className="flex flex-row text-primary-black py-3">
                    <Link to={`/${language}/toolbox/new`}>
                        <button className="bg-primary-green text-primary-white text-lg font-medium px-10 py-1 rounded-lg mr-2" >New Tool</button>
                    </Link>
                </div>
                <table className="text-primary-black border-pri\mary-grey text-base text-left w-full">
                    <tbody>
                        <tr className="bg-gray-300">
                            <th className="border-2 w-2/12 px-2">Name</th>
                            <th className="border-2 w-1/12 px-2">Retention Range</th>
                            <th className="border-2 w-7/12 px-2">Description</th>
                            <th className="border-2 w-1/12 px-2">Attachment</th>
                            <th className="border-2 w-1/12 px-2">Last Edited</th>
                        </tr>
                        {toolboxes.map((toolbox, index) => (
                            <tr key={index} className={index % 2 === 0? `bg-white` : `bg-gray-100`}>
                                <td className="border-2"><Link to={`/${language}/toolbox/`+ toolbox.id}><div className="flex flex-row px-2">{toolbox.title}</div></Link></td>
                                <td className="border-2"><Link to={`/${language}/toolbox/`+ toolbox.id}><div className="flex flex-col px-2">
                                    { toolbox.retention_individual === 1 && <div>Individual</div> }
                                    { toolbox.retention_department === 1 && <div>Team</div> }
                                    { toolbox.retention_company === 1 && <div>Department/Company</div> }
                                </div></Link></td>
                                <td className="border-2"><Link to={`/${language}/toolbox/`+ toolbox.id}><div className="flex flex-row px-2">{toolbox.description}</div></Link></td>
                                <td className="border-2"><Link to={`/${language}/toolbox/`+ toolbox.id}><div className="flex flex-row px-2">{toolbox.attachment}</div></Link></td>
                                <td className="border-2"><Link to={`/${language}/toolbox/`+ toolbox.id}><div className="flex flex-row px-2">{toolbox.last_edited}</div></Link></td>
                            </tr> 
                    ))}
                    </tbody>
                </table>
            </div>
            <Outlet />
            {(isLoading1 || isLoading2) && <Loading /> }
        </div>
    );
}

export default Toolbox;
import { Fragment, useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { BsCardText } from 'react-icons/bs';
import RatingAllCategoryChart from '../components/RatingAllCategoryChart';
import RatingCategoryChart from '../components/RatingCategoryChart';
import Loading from "../Loading";
import SpiderChart from '../components/SpiderChart';
import TrendArrow from '../components/TrendArrow';
import TrendChart from '../components/TrendChart';
import api from "../Api";

function TeamReport({ language, setNavbar, setActiveMenu, forceRefresh, setForceRefresh, currentRound, setCurrentRound, selectedforReport, currentQuestions }) {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPersonnel, setSelectedPersonnel] = useState([]); //useState({id: 0, full_name: '', level: 999, group_id: 'zzz', work_contract: ''});
    const [tabs, setTabs] = useState('');
    const [lowestHierarchy, setLowestHierarchy] = useState(false);
    const [recTools, setRecTools] = useState([]);
    const [selTools, setSelTools] = useState([]); //useState({id: 0, personnel_id: 0, toolbox_id: 0, retention_individual: false, retention_department: false, retention_company: false, selected: false});
    const [tools, setTools] = useState([]);
    const [hrcomment, setHrcomment] = useState([]); //useState({personnel_id: 0, whole_hierarchy: '', direct_report: '', direct_report_key: '', direct_report_nonkey: '', individual: ''});
    const [saveStatus, setSaveStatus] = useState('');

    useEffect(() => {
        setActiveMenu(4);
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    useEffect(() => {
        const fetchSelTool = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/selected-tools/team/${selectedPersonnel?.id}?key=${forceRefresh}`);
                setSelTools(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        
        fetchSelTool();
    },[selectedPersonnel, forceRefresh])

    useEffect(() => {
        const fetchComment = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/hrcomment/${selectedPersonnel.id}?key=${forceRefresh}`);
                setHrcomment(response.data[0]);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        
        fetchComment();
    },[selectedPersonnel, forceRefresh])

    useEffect(() => {
        const fetchTool = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/recommended-tool/round/${selectedPersonnel?.round_id}/team?key=${forceRefresh}`);
                setRecTools(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        
        fetchTool();
    },[selectedPersonnel, forceRefresh])

    useEffect(() => {
        const answer = (a, qno) => {
            let pos = 0
            switch (qno.substring(0,1)) {
                case 'J' : pos = 0 + Number(qno.substring(1)) - 1; break;
                case 'L' : pos = 4 + Number(qno.substring(1)) - 1; break;
                case 'R' : pos = 8 + Number(qno.substring(1)) - 1; break;
                case 'C' : pos = 12 + Number(qno.substring(1)) - 1; break;
                default: 
            }
            return Number(a?.substring(pos, pos + 1));
        }

        let teamAnswer = overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false);
        let deptAnswer = !lowestHierarchy ? overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false) : "5555555555555555";

        setTools( lowestHierarchy ?
            recTools.filter((tool) => {
                if (answer( teamAnswer, tool.question_no) < 3 && tool.retention_department === 1) return true; 
                return false;
            }).filter((item, pos, self) => {
                return self.map(tool => tool.question_no.substring(0,1) + '-' + tool.id).indexOf(item.question_no.substring(0,1) + '-' + item.id) === pos;
            }).map(tool => (
                {...tool, selected: selTools.find(stool => stool.toolbox_id === tool.id && (stool.selected === true || stool.selected === 1 ) && stool.retention_department === 1) === undefined ? false : true}
            )):
            recTools.filter((tool) => {
                if (answer( teamAnswer, tool.question_no) < 3 && tool.retention_department === 1) return true; 
                return false;
            }).filter((item, pos, self) => {
                return self.map(tool => tool.question_no.substring(0,1) + '-' + tool.id).indexOf(item.question_no.substring(0,1) + '-' + item.id) === pos;
            }).map(tool => (
                {...tool, selected: selTools.find(stool => stool.toolbox_id === tool.id && (stool.selected === true || stool.selected === 1 ) && stool.retention_department === 1) === undefined ? false : true}
            )).concat(
                recTools.filter((tool) => {
                    if (answer( deptAnswer, tool.question_no) < 3 && tool.retention_company === 1) return true; 
                    return false;
                }).filter((item, pos, self) => {
                    return self.map(tool => tool.question_no.substring(0,1) + '-' + tool.id).indexOf(item.question_no.substring(0,1) + '-' + item.id) === pos;
                }).map(tool => (
                    {...tool, selected: selTools.find(stool => stool.toolbox_id === tool.id && (stool.selected === true || stool.selected === 1 ) && stool.retention_company === 1) === undefined ? false : true}
                ))
            )
        );
    }, [recTools, selTools, selectedPersonnel, selectedforReport, lowestHierarchy])

    useEffect(() => {
        let selected = selectedforReport.find((personnel) => personnel.selected && personnel.has_child);
        let tabs = 'direct'
        let lowest = true;
        selectedforReport.filter((item) => String(item.group_id).startsWith(selected.group_id) && item.level >= selected.level + 1).forEach((item) => {
            if(item.has_child) { 
                lowest = false;
                tabs = 'whole';
            }
        });
        setTabs(tabs);
        setLowestHierarchy(lowest);
        setSelectedPersonnel(selected);
        setIsLoading(false);
    },[selectedforReport]) 

    const handlePersonnel = (e) => {        
        const personnelId = e.target.options[e.target.options.selectedIndex].getAttribute('data-key');
//        if (personnelId !== '0') {
            let selected = selectedforReport.find(({ id }) => id === Number(personnelId))
            let tabs = 'direct'
            let lowest = true;
            selectedforReport.filter((item) => String(item.group_id).startsWith(selected.group_id) && item.level >= selected.level + 1).forEach((item) => {
                if(item.has_child) { 
                    lowest = false;
                    tabs = 'whole';
                }
            });
            setTabs(tabs);
            setLowestHierarchy(lowest);
            setSelectedPersonnel(selected);
            setForceRefresh(crypto.randomUUID());
//        } else
//            setSelectedPersonnel({id: 0, full_name: '', level: 999, group_id: 'zzz'});
    }

/*
    const overallSatisfactionLevel = (selected, prev) => {
        let count = 0;
        let satisfied = 0;
        selected.forEach((item) => {
            count++;
            for(let i=0; i < 16; i++) {
                if(prev) {
                    if(Number(String(item.prev_personnel_answer).substring(i, i+1)) > 3) satisfied++;
                } else {
                    if(Number(String(item.personnel_answer).substring(i, i+1)) > 3) satisfied++;
                }
            }
        });
        let percentage = Math.round((((satisfied / (16 * count)) * 100) + Number.EPSILON) * 10) / 10;
        return isNaN(percentage) ? 0 : percentage;
    }

    const overallRetentionRisk = (selected, prev) => {
        let count = 0;
        let risk = 0;
        selected.forEach((item) => {
            count++;
            for(let i=0; i < 16; i++) {
                if(prev) {
                    if(item.prev_personnel_answer !== null && Number(String(item.prev_personnel_answer).substring(i, i+1)) < 3) risk++;
                } else {
                    if(item.personnel_answer !== null && Number(String(item.personnel_answer).substring(i, i+1)) < 3) risk++;
                }
            }
        });
        let percentage = Math.round((((risk / (16 * count)) * 100) + Number.EPSILON) * 10) / 10;
        return isNaN(percentage) ? 0 : percentage;
    }
*/

    const overallAverageAnswer = (selected, supervisor, prev = false) => {
        let total = [];
        let count = 0;
        let answer = 0;
        selected?.forEach((item) => {
            for(let i = 0; i<16; i++) {
                if (prev) {
                    if (supervisor) 
                        answer = isNaN(Number(String(item.prev_supervisor_answer).substring(i,i+1))) ? 0 : Number(String(item.prev_supervisor_answer).substring(i,i+1))
                    else
                        answer = isNaN(Number(String(item.prev_personnel_answer).substring(i,i+1))) ? 0 : Number(String(item.prev_personnel_answer).substring(i,i+1));
                } else {
                    if (supervisor) 
                        answer =  Number(String(item.supervisor_answer).substring(i,i+1))
                    else
                        answer = Number(String(item.personnel_answer).substring(i,i+1));
                }

                if (count === 0 ) 
                    total.push(answer)
                else
                    total[i] = total[i] + answer;
            }
            count++;
        });
        for(let i = 0; i < total.length; i++) {
            total[i] = Math.round(total[i] / count);
        }
        return total.join('');
    }

    const trendByCategory = (selected, category, supervisor = false) => {
        let total = 0;
        let count = 0;
        selected.forEach((item) => {
            count++;
            for(let i=0; i < 4; i++) {
                if (supervisor) {
                    total+= Number(String(item.supervisor_answer).substring((category * 4)+i, (category * 4) +i+1));
                } else {
                    total+= Number(String(item.personnel_answer).substring((category * 4)+i, (category * 4) +i+1));
                }
            }
        });
        let trend = Math.round(((total / (count * 4)) + Number.EPSILON) * 10) / 10;
        return isNaN(trend) ? 0 : trend;
    }
/*
    const countUrgentCalls = (answer = '0000000000000000') => {
        let count = 0;
        for(let i = 0; i<16; i++) {
            if (Number(String(answer).substring(i,i+1)) < 3) count++;
        }
        return count;
    }

    const countDeviations = (answer1 = '0000000000000000', answer2 = '0000000000000000') => {
        let count = 0;
        for(let i = 0; i<16; i++) {
            if (Math.abs(Number(String(answer1).substring(i,i+1)) - Number(String(answer2).substring(i,i+1)))  > 1) count++;
        }
        return count;
    }
*/
    const ratingPerCategoryAvg = (selected, category) => {
        let count = 0;
        let rating = 0;
        selected?.forEach((item) => {
            count++;
            for(let i=0; i < 4; i++) {
                rating += Number(String(item.personnel_answer).substring((category * 4) + i,(category * 4) + i+1));
            }
        });
        let avg = Math.round(((rating / (4 * count)) + Number.EPSILON) * 10) / 10;
        avg = isNaN(avg) ? 0 : avg;
        let result = '';
        if (avg === 0) result = <div className="h-[30px] w-[30px]" />;
        if (avg > 0) result = <TrendArrow trend={2} width="30px" height="30px" />;
        if (avg > 2.4) result = <TrendArrow trend={3} width="30px" height="30px" />;
        if (avg > 3.4) result = <TrendArrow trend={5} width="30px" height="30px" />;
        return result;
    }

    const ratingPerAnswerGroupAvg = (selected, category, group) => {
        let count = 0;
        let rating = 0;
        selected?.forEach((item) => {
            count++;
            for(let i=0; i < 4; i++) {
                let a = Number(String(item.personnel_answer).substring((category * 4) + i,(category * 4) + i+1));
                switch(group) {
                    case 0:
                        if( a > 3) rating++;
                        break;
                    case 1:
                        if( a === 3) rating++;
                        break;
                    case 2:
                        if( a < 3) rating++;
                        break;
                    default:
                }
            }
        });
        let percentage = Math.round((((rating / (4 * count)) * 100) + Number.EPSILON) * 10) / 10;
        return isNaN(percentage) ? 0 : percentage;
    }

    const ratingPerAnswerGroupAvgAll = (selected, group, prev = false) => {
        let count = 0;
        let rating = 0;
        selected?.forEach((item) => {
            count++;
            for(let i=0; i < 16; i++) {
                let a = Number(String(item.personnel_answer).substring(i,i+1));
                if (prev) a = Number(String(item.prev_personnel_answer).substring(i,i+1));
                switch(group) {
                    case 0:
                        if( a > 3) rating++;
                        break;
                    case 1:
                        if( a === 3) rating++;
                        break;
                    case 2:
                        if( a < 3) rating++;
                        break;
                    default:
                }
            }
        });
        let percentage = Math.round((((rating / (16 * count)) * 100) + Number.EPSILON) * 10) / 10;
        return isNaN(percentage) ? 0 : percentage;
    }

    const ratingUrgentCallperQuestionAvg = (selected, category, no) => {
        let count = 0;
        let urgent = 0;
        selected?.forEach((item) => {
            count++;
            let a = Number(String(item.personnel_answer).substring((category * 4) + no,(category * 4) + no+1));
            if (a < 3) urgent++;
        });
        let rating = Math.round(((urgent / count) + Number.EPSILON) * 10) / 10;
        rating = isNaN(rating) ? 0 : rating;
        let result = '';
        if (rating === 0) result = <div className="h-[30px] w-[30px]" />;
        if (rating > 0) result = <TrendArrow trend={5} width="30px" height="30px" />;
        if (rating > 0.2) result = <TrendArrow trend={3} width="30px" height="30px" />;
        if (rating > 0.6) result = <TrendArrow trend={2} width="30px" height="30px" />;
        return result;
    }

    const countUrgentCallperQuestion = (selected, category, no) => {
        let count = 0;
        selected?.forEach((item) => {
            let a = Number(String(item.personnel_answer).substring((category * 4) + no,(category * 4) + no+1));
            if (a < 3) count++;
        });
        return count;
    }

    const percentageDeviation = ( item ) => {
        let deviation = 0
        for(let i=0; i < 16; i++) {
            let a = Number(String(item?.personnel_answer).substring(i,i+1));
            let b = Number(String(item?.supervisor_answer).substring(i,i+1));
            if( Math.abs(a-b) > 1) deviation++;
        }
        let percentage = Math.round((((deviation / 16) * 100) + Number.EPSILON) * 10) / 10;
        return isNaN(percentage) ? 0 : percentage;
    }

    const percentageUrgentcall = ( item ) => {
        let urgencall = 0
        for(let i=0; i < 16; i++) {
            let a = Number(String(item?.personnel_answer).substring(i,i+1));
            if( a < 3) urgencall++;
        }
        let percentage = Math.round((((urgencall / 16) * 100) + Number.EPSILON) * 10) / 10;
        return isNaN(percentage) ? 0 : percentage;        
    }

    const handleCheck = (e) => {
        if (!selTools.find(stool => stool.toolbox_id === Number(e.target.getAttribute('data-key')) && ((tabs === 'whole' && stool.retention_company === 1) || (tabs === 'direct' && stool.retention_department === 1)) )) {
            switch(tabs) {
                case 'whole':
                    selTools.push({id: 0, personnel_id:selectedPersonnel.id, toolbox_id: Number(e.target.getAttribute('data-key')), retention_individual: 0, retention_department: 0, retention_company: 1, selected: 0});
                    break;
                case 'direct':
                    selTools.push({id: 0, personnel_id:selectedPersonnel.id, toolbox_id: Number(e.target.getAttribute('data-key')), retention_individual: 0, retention_department: 1, retention_company: 0, selected: 0});
                    break;
                default:
            }
        }
        setSelTools(selTools.map(item => item.toolbox_id === Number(e.target.getAttribute('data-key')) && ((tabs === 'whole' && item.retention_company === 1) || (tabs === 'direct' && item.retention_department === 1)) ? { ...item, selected: e.target.checked} : item));
    }

    const postReport = async (mode) => {
        try {
            setIsLoading(true);
            const response = await api.post(`/report/team/${selectedPersonnel.id}`, {selected_tools: selTools.filter(item => item.selected !== false) , hr_comment: hrcomment === undefined ? { ...hrcomment, whole_hierarchy: '', direct_report: '', team_status_id: mode === 1 ? 11 : 12} : { ...hrcomment, team_status_id: mode === 1 ? 11 : 12}});
            console.log(response);
            setForceRefresh(crypto.randomUUID());
            setIsLoading(false);
            setSaveStatus(mode === 1 ? " - Saved" : " - Completed");
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSave = (mode) => {
        postReport(mode);
    }

    const handleTab = (tabname) => {
        setTabs(tabname);
    }

    return (
        <div className="w-11/12 py-3">
            <div className="flex flex-row">
                <h1 className="text-2xl font-medium text-primary-black">Team Profile - Supervisor</h1>
                <select id="supervisor" className="text-2xl font-medium ml-3 border rounded-sm disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={selectedPersonnel?.full_name}
                    onChange={(e) => handlePersonnel(e)} >
                    <option data-key='0' value=''></option>
                    {selectedforReport.filter((personnel) => personnel.selected && personnel.has_child).map((personnel, index) => (
                        <option key={index} data-key={personnel.id} value={personnel.full_name}>{personnel.full_name}</option>
                    ))}
                </select>
                <span className="ml-2 text-2xl font-medium text-green-500">{saveStatus}</span>
            </div>
            <div className="flex flex-row text-primary-black py-3">
                <button className="bg-primary-green text-primary-white text-lg font-medium px-10 py-1 rounded-lg mr-2" onClick={() => handleSave(1)}>Save</button>
                <button className="bg-primary-green text-primary-white text-lg font-medium px-10 py-1 rounded-lg mr-8" onClick={() => handleSave(2)}>Save & Mark as Complete</button>
            </div>

            <div className="w-full flex my-2 items-end pb-2 border-primary-grey">
                    <div className="w-2 border-b-2">&nbsp;</div>
                    { !lowestHierarchy &&
                        <button className={`text-primary-black text-lg px-6 py-1 rounded-t-lg border-t-2 border-l-2 border-r whitespace-nowrap ${tabs === 'whole'     ? 'border-b-0 h-[42px] font-semibold' : 'border-b-2 h-[38px]' } `} onClick={() => handleTab('whole')} >Whole Hierarchy</button>
                    }
                    <button className={`text-primary-black text-lg px-6 py-1 rounded-t-lg border-t-2 border-l border-r-2 whitespace-nowrap ${tabs === 'direct'    ? 'border-b-0 h-[42px] font-semibold' : 'border-b-2 h-[38px]' } `} onClick={() => handleTab('direct')} >Direct Reports</button>
                    {/*}
                    <button className={`text-primary-black text-lg px-6 py-1 rounded-t-lg border-t-2 border-l border-r-2 whitespace-nowrap ${tabs === 'directkey' ? 'border-b-0 h-[42px] font-semibold' : 'border-b-2 h-[38px]' } `} onClick={() => handleTab('directkey')} >Direct Reports - Key Employee</button>
                    <button className={`text-primary-black text-lg px-6 py-1 rounded-t-lg border-t-2 border-l border-r-2 whitespace-nowrap ${tabs === 'directnonkey' ? 'border-b-0 h-[42px] font-semibold' : 'border-b-2 h-[38px]' } `} onClick={() => handleTab('directnonkey')} >Direct Reports - Non-key Employee</button>
                    */}
                    <button className={`text-primary-black text-lg px-6 py-1 rounded-t-lg border-t-2 border-l border-r-2 whitespace-nowrap ${tabs === 'special'     ? 'border-b-0 h-[42px] font-semibold' : 'border-b-2 h-[38px]' } `} onClick={() => handleTab('special')} >Special Reports</button>
                    <div className="w-full mr-auto border-b-2">&nbsp;</div>
            </div>
            { tabs === 'whole' &&
                <Fragment>
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                        Overview - Whole Hierarchy
                    </div>
                    <div className="w-full py-7 flex flex-row items-center justify-center">
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="h-[74px]"></td>
                                    </tr>
                                    <tr>
                                        <td className="h-[304px] max-w-[80px]">
                                            <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                                <div>Current round:</div>
                                                <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.round_name}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                        <tr>
                                            <td className="h-[304px] max-w-[80px]">
                                                <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                                    <div>Previous round:</div>
                                                    <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="w-[360px] mr-[5px]">
                                <tbody>
                                    <tr>
                                        <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2">
                                            <div>Employee Engagement level: {ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0)}%</div>
                                            <div>Distribution of ratings across all categories</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[300px] border-primary-dark-green border-2">
                                            <RatingAllCategoryChart height="300px" width="300px"
                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0)} 
                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1)}
                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2)} />
                                        </td>
                                    </tr>
                                    { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                        <tr>
                                            <td className="h-[300px] border-primary-dark-green border-2">
                                                <RatingAllCategoryChart height="300px" width="300px"
                                                    rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, true)} 
                                                    rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, true)}
                                                    rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, true)} />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <table className="mt-[5px] border-2 border-primary-dark-green w-[360px]">
                                <tbody>
                                    <tr>
                                        <td className="text-center text-xs">
                                            <div className="bg-[#d7ecd0] px-2">4 - 5</div>
                                            <div className="bg-[#fcf6bc] px-2">3</div>                        
                                            <div className="bg-[#f7b59b] px-2">1 - 2</div>
                                        </td>
                                        <td className="h-[52px] flex flex-row text-xs items-center justify-center">
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 70%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(270 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 50%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(180 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &lt; 50%</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="">
                            <div className="flex flex-row justify-between">
                                <div>
                                    <table className="mr-[5px]">
                                        <tbody>
                                            <tr>
                                                <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2 text-center">
                                                    <div>Trend</div>
                                                    <div>All Employees</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false)}/>
                                                </td>
                                            </tr>
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                                <tr>
                                                    <td className="h-[300px] border-primary-dark-green border-2">
                                                        <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false, true)}/>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table className="mr-[5px]">
                                        <tbody>
                                            <tr>
                                                <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2 text-center">
                                                    <div>Trend</div>
                                                    <div>Non-key Employees</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1  && item.key_status === 'Non-key'), false)}/>
                                                </td>
                                            </tr>
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                                <tr>
                                                    <td className="h-[300px] border-primary-dark-green border-2">
                                                        <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1  && item.key_status === 'Non-key'), false, true)}/>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center">
                                                    <div>Trend</div>
                                                    <div>Key Employees</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1  && item.key_status === 'Key'), false)}/>
                                                </td>
                                            </tr>
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                                <tr>
                                                    <td className="h-[300px] border-primary-dark-green border-2">
                                                        <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1  && item.key_status === 'Key'), false, true)}/>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex flex-row items-center justify-center mt-[5px] border-2 border-primary-dark-green h-[56px]">
                                <TrendArrow trend={2} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &lt; 2.5</div>
                                <TrendArrow trend={3} height="40px" width="40px" />
                                <div className="pl-1 pr-8">2.5 &le; Average &lt; 3.5</div>
                                <TrendArrow trend={5} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &ge; 3.5</div>
                            </div>
                        </div>
                    </div>
{/*                    
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                        Overview - Whole Hierarchy
                    </div>
                    <div className="w-full py-7 flex flex-row">
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="h-[68px] w-[200px]"></td>
                                    </tr>
                                    <tr>
                                        <td className="h-[268px] p-2 pl-6">
                                            <div>Current round:</div>
                                            <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1)[0]?.round_name}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[268px] p-2 pl-6">
                                            <div>Previous round:</div>
                                            <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1)[0]?.prev_round_name}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="mr-2">
                                <tbody>
                                    <tr>
                                        <td className="w-[380px] h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2">
                                            <div>Employee Engagement level: {ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0)}%</div>
                                            <div>Distribution of ratings across all categories</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <RatingAllCategoryChart height="250px" width="250px"
                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0)} 
                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1)}
                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <RatingAllCategoryChart height="250px" width="250px"
                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, true)} 
                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, true)}
                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, true)} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="mt-2 border-2 border-primary-dark-green w-[380px] mr-2">
                                <tbody>
                                    <tr>
                                        <td className="text-center text-xs">
                                            <div className="bg-[#d7ecd0] px-4">4 - 5</div>
                                            <div className="bg-[#fcf6bc] px-4">3</div>                        
                                            <div className="bg-[#f7b59b] px-4">1 - 2</div>
                                        </td>
                                        <td className="h-[52px] flex flex-row text-xs items-center justify-center">
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 70%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(270 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 50%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(180 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &lt; 50%</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div className="flex flex-row">
                                <table className="mr-2">
                                    <tbody>
                                        <tr>
                                            <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2 text-center">
                                                <div>Trend</div>
                                                <div>All Employees</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false, true)}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="mr-2">
                                    <tbody>
                                        <tr>
                                            <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2 text-center">
                                                <div>Trend</div>
                                                <div>Non-key Employees</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1  && item.key_status === 'Non-key'), false)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1  && item.key_status === 'Non-key'), false, true)}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="mr-2">
                                    <tbody>
                                        <tr>
                                            <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center" colSpan="2">
                                                <div>Trend</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                <div>Key Employees</div>
                                            </td>
                                            <td className="bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                <div>Supervisor</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1  && item.key_status === 'Key'), false)}/>
                                            </td>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1  && item.key_status === 'Key'), true)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1  && item.key_status === 'Key'), false, true)}/>
                                            </td>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1  && item.key_status === 'Key'), true, true)}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex flex-row items-center justify-center mt-2 border-2 border-primary-dark-green mr-2 h-[56px]">
                                <TrendArrow trend={2} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &lt; 2.5</div>
                                <TrendArrow trend={3} height="40px" width="40px" />
                                <div className="pl-1 pr-8">2.5 &le; Average &lt; 3.5</div>
                                <TrendArrow trend={5} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &ge; 3.5</div>
                            </div>
                        </div>
                    </div>
*/}


                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                        REMS Profile - Whole Hierarchy
                    </div>
                    <div className="w-full flex py-7">
                        <div className="w-5/12 flex items-center justify-center px-9">
                            <SpiderChart width="640px" supervisor_answer="" personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false)} />
                        </div>
                        <div className="w-7/12 pt-9">
                            <table className="w-full">
                                <tbody>
                                    <tr className="text-lg text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 align-top text-left" rowSpan="2">Category</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green w-2/12 text-left pl-4">Trend</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left w-1/3">Urgent Calls</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green w-1/3 text-left pl-4">Proposed</th>
                                    </tr>
                                    <tr className="text-sm text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-lg text-left pl-4">
                                            Employee
                                        </th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4">
                                            *Also, in previous round
                                        </th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-lg text-left pl-4">
                                            Tools
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="px-4 border-t-2 border-x-2 border-primary-dark-green">JOB</td>
                                        
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center px-2">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0)} height="60px" width="60px" />
                                            </div>
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                <Fragment key={'profilewholej'+index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false).substring(index, index + 1)) < 3 &&
                                                        <div className="flex flex-row">
                                                            <div className="font-bold">{question.question_no + '.'}</div>
                                                            <div className="ml-1">{question.title}{ selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null && overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false, true) !== null && Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false, true).substring(index, index + 1)) < 3 && <span>*</span>}</div>
                                                        </div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "J" && tool.retention_company === 1).map((tool, index) => (
                                                <div key={"wtoolsjob" + index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool.id} id={"wtoolsjob" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/team/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                                </div>
                                            ))}
                                        </td>
{/*
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { recTools.filter(tool => String(tool.question_no).substring(0,1) === "J" && tool.retention_company === 1).filter((tool) => {
                                                if (Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false).substring(Number(String(tool.question_no).substring(1)) - 1, Number(String(tool.question_no).substring(1)))) < 3) return true;
                                                return false;
                                            }).filter((item, pos, self) => {
                                                return self.map(tool => tool.id).indexOf(item.id) === pos;
                                            }).map((tool, index) => (
                                                <div key={index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool?.id} id={"toolsjob" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSel(tool.id)} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2"><label htmlFor={"toolsjob" + index}>{tool?.title}</label></div>
                                                </div>
                                            ))}
                                        </td>
*/}
                                    </tr>

                                    <tr>
                                        <td className="px-4 border-t-2 border-x-2 border-primary-dark-green">LEADER</td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center px-2">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1)} height="60px" width="60px" />
                                            </div>
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                <Fragment key={'profilewholel'+index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false).substring(4 + index, 4 + index + 1)) < 3 &&
                                                        <div className="flex flex-row">
                                                            <div className="font-bold">{question.question_no + '.'}</div>
                                                            <div className="ml-1">{question.title}{ selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null && overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false, true) !== null && Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false, true).substring(4 + index, 4 + index + 1)) < 3 && <span>*</span>}</div>
                                                        </div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "L" && tool.retention_company === 1).map((tool, index) => (
                                                <div key={"wtoolsleader" + index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool.id} id={"wtoolsleader" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/team/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                                </div>
                                            ))}
                                        </td>
{/*
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { recTools.filter(tool => String(tool.question_no).substring(0,1) === "L" && tool.retention_company === 1).filter((tool) => {
                                                if (Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false).substring(4 + Number(String(tool.question_no).substring(1)) - 1, 4 + Number(String(tool.question_no).substring(1)))) < 3) return true;
                                                return false;
                                            }).filter((item, pos, self) => {
                                                return self.map(tool => tool.id).indexOf(item.id) === pos;
                                            }).map((tool, index) => (
                                                <div key={index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool?.id} id={"toolsleader" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSel(tool.id)} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2"><label htmlFor={"toolsleader" + index}>{tool?.title}</label></div>
                                                </div>
                                            ))}
                                        </td>
*/}
                                    </tr>

                                    <tr>
                                        <td className="px-4 border-t-2 border-x-2 border-primary-dark-green">REWARDS</td>

                                        <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center px-2">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2)} height="60px" width="60px" />
                                            </div>
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                <Fragment key={'profilewholer'+index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false).substring(8 + index, 8 + index + 1)) < 3 &&
                                                        <div className="flex flex-row">
                                                            <div className="font-bold">{question.question_no + '.'}</div
                                                            ><div className="ml-1">{question.title}{ selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null && overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false, true) !== null && Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false, true).substring(8 + index, 8 + index + 1)) < 3 && <span>*</span>}</div>
                                                        </div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "R" && tool.retention_company === 1).map((tool, index) => (
                                                <div key={"wtoolsreward" + index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool.id} id={"wtoolsreward" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/team/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                                </div>
                                            ))}
                                        </td>
{/*
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { recTools.filter(tool => String(tool.question_no).substring(0,1) === "R" && tool.retention_company === 1).filter((tool) => {
                                                if (Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false).substring(8 + Number(String(tool.question_no).substring(1)) - 1, 8 + Number(String(tool.question_no).substring(1)))) < 3) return true;
                                                return false;
                                            }).filter((item, pos, self) => {
                                                return self.map(tool => tool.id).indexOf(item.id) === pos;
                                            }).map((tool, index) => (
                                                <div key={index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool?.id} id={"toolsreward" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSel(tool.id)} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2"><label htmlFor={"toolsreward" + index}>{tool?.title}</label></div>
                                                </div>
                                            ))}
                                        </td>
*/}
                                    </tr>

                                    <tr>
                                        <td className="px-4 border-2 border-primary-dark-green">COMPANY</td>

                                        <td className="py-4 border-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center px-2">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3)} height="60px" width="60px" />
                                            </div>
                                        </td>

                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                <Fragment key={'profilewholec'+index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false).substring(12 + index, 12 + index + 1)) < 3 &&
                                                        <div className="flex flex-row">
                                                            <div className="font-bold">{question.question_no + '.'}</div>
                                                            <div className="ml-1">{question.title}{ selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null && overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false, true) !== null && Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false, true).substring(12 + index, 12 + index + 1)) < 3 && <span>*</span>}</div>
                                                        </div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>

                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "C" && tool.retention_company === 1).map((tool, index) => (
                                                <div key={"wtoolscompany" + index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool.id} id={"wtoolscompany" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/team/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                                </div>
                                            ))}
                                        </td>
{/*                                        
                                        <td className="py-4 border-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { recTools.filter(tool => String(tool.question_no).substring(0,1) === "C" && tool.retention_company === 1).filter((tool) => {
                                                if (Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), false).substring(12 + Number(String(tool.question_no).substring(1)) - 1, 12 + Number(String(tool.question_no).substring(1)))) < 3) return true;
                                                return false;
                                            }).filter((item, pos, self) => {
                                                return self.map(tool => tool.id).indexOf(item.id) === pos;
                                            }).map((tool, index) => (
                                                <div key={index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool?.id} id={"toolscompany" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSel(tool.id)} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2"><label htmlFor={"toolscompany" + index}>{tool?.title}</label></div>
                                                </div>
                                            ))}
                                        </td>
*/}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
{/*
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                        REMS Profile - Whole Hierarchy
                    </div>
                    <div className="w-full flex py-7">
                        <div className="w-2/6">
                            <SpiderChart width="520px" supervisor_answer="" personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false)} />
                        </div>
                        <div className="w-4/6 pt-9">
                            <table className="w-full">
                                <tbody>
                                    <tr className="text-lg text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Category</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">Trend</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left">Urgent Calls</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Proposed Tools</th>
                                    </tr>
                                    <tr className="text-lg text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">
                                            Employee
                                        </th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4 text-sm">
                                            *Also, in previous round
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">JOB</td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(index, index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false, true).substring(index, index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "J").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(Number(String(tool.question_no).substring(1)) - 1, Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">LEADER</td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(4 + index, 4 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false, true).substring(4 + index, 4 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "L").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(4 + Number(String(tool.question_no).substring(1)) - 1, 4 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">REWARDS</td>
                                        <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(8 + index, 8 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false, true).substring(8 + index, 8 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "R").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(8 + Number(String(tool.question_no).substring(1)) - 1, 8 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-2 border-primary-dark-green">COMPANY</td>
                                        <td className="py-4 border-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(12 + index, 12 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false, true).substring(12 + index, 12 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "C").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), false).substring(12 + Number(String(tool.question_no).substring(1)) - 1, 12 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
*/}
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">HR Comment - Whole Hierarchy</div>
                    <div className="mt-3 mb-12">
                        <div className="border-2 border-primary-dark-green">
                            <textarea id="commentwhole" rows="8" className="p-1 w-full" placeholder="Fluctuation rate:" value={hrcomment?.whole_hierarchy} onChange={e => hrcomment === undefined ? setHrcomment({ ...hrcomment, whole_hierarchy: e.target.value, direct_report: ''}) : setHrcomment({ ...hrcomment, whole_hierarchy: e.target.value })} />
                        </div>
                    </div>

                </Fragment>
            }

            { tabs === 'direct' &&
                <Fragment>
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                        Overview - Direct Reports
                    </div>
                    <div className="w-full py-7 flex flex-row items-center justify-center">
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="h-[74px]"></td>
                                    </tr>
                                    <tr>
                                        <td className="h-[304px] max-w-[80px]">
                                            <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                                <div>Current round:</div>
                                                <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1)[0]?.round_name}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                        <tr>
                                            <td className="h-[304px] max-w-[80px]">
                                                <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                                    <div>Previous round:</div>
                                                    <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1)[0]?.prev_round_name}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="w-[360px]">
                                <tbody>
                                    <tr>
                                        <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2">
                                            <div>Employee Engagement level: {ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), 0)}%</div>
                                            <div>Distribution of ratings across all categories</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[300px] border-primary-dark-green border-2">
                                            <RatingAllCategoryChart height="300px" width="300px"
                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), 0)} 
                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), 1)}
                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), 2)} />
                                        </td>
                                    </tr>
                                    { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                        <tr>
                                            <td className="h-[300px] border-primary-dark-green border-2">
                                                <RatingAllCategoryChart height="300px" width="300px"
                                                    rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), 0, true)} 
                                                    rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), 1, true)}
                                                    rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), 2, true)} />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <table className="mt-[5px] border-2 border-primary-dark-green w-[360px] mr-[5px]">
                                <tbody>
                                    <tr>
                                        <td className="text-center text-xs">
                                            <div className="bg-[#d7ecd0] px-2">4 - 5</div>
                                            <div className="bg-[#fcf6bc] px-2">3</div>                        
                                            <div className="bg-[#f7b59b] px-2">1 - 2</div>
                                        </td>
                                        <td className="h-[52px] flex flex-row text-xs items-center justify-center">
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 70%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(270 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 50%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(180 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &lt; 50%</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="">
                            <div className="flex flex-row">
                                <div>
                                    <table className="mr-[5px]">
                                        <tbody>
                                            <tr>
                                                <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2 text-center">
                                                    <div>Trend</div>
                                                    <div>All Employees</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false)}/>
                                                </td>
                                            </tr>
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                                <tr>
                                                    <td className="h-[300px] border-primary-dark-green border-2">
                                                        <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false, true)}/>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table className="mr-[5px]">
                                        <tbody>
                                            <tr>
                                                <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2 text-center">
                                                    <div>Trend</div>
                                                    <div>Non-key Employees</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Non-key'), false)}/>
                                                </td>
                                            </tr>
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                                <tr>
                                                    <td className="h-[300px] border-primary-dark-green border-2">
                                                        <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Non-key'), false, true)}/>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center" colSpan="2">
                                                    <div>Trend</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                    <div>Key Employees</div>
                                                </td>
                                                <td className="bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                    <div>Supervisor</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="h-[300px] border-primary-dark-green border-2">
                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Key'), false)}/>
                                                </td>
                                                <td className="h-[300px] border-primary-dark-green border-2 bg-[#bddce3]">
                                                    <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Key'), true)}/>
                                                </td>
                                            </tr>
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                                <tr>
                                                    <td className="h-[300px] border-primary-dark-green border-2">
                                                        <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Key'), false, true)}/>
                                                    </td>
                                                    <td className="h-[300px] border-primary-dark-green border-2 bg-[#bddce3]">
                                                        <TrendChart width="300px" height="300px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Key'), true, true)}/>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex flex-row items-center justify-center mt-[5px] border-2 border-primary-dark-green h-[56px]">
                                <TrendArrow trend={2} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &lt; 2.5</div>
                                <TrendArrow trend={3} height="40px" width="40px" />
                                <div className="pl-1 pr-8">2.5 &le; Average &lt; 3.5</div>
                                <TrendArrow trend={5} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &ge; 3.5</div>
                            </div>
                        </div>
                    </div>                    
{/*
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                        Overview - Direct Reports
                    </div>
                    <div className="w-full py-7 flex flex-row">
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="h-[68px] w-[200px]"></td>
                                    </tr>
                                    <tr>
                                        <td className="h-[268px] p-2 pl-6">
                                            <div>Current round:</div>
                                            <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1)[0]?.round_name}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[268px] p-2 pl-6">
                                            <div>Previous round:</div>
                                            <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1)[0]?.prev_round_name}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="mr-2">
                                <tbody>
                                    <tr>
                                        <td className="w-[380px] h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2">
                                            <div>Employee Engagement level: {ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 0)}%</div>
                                            <div>Distribution of ratings across all categories</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <RatingAllCategoryChart height="250px" width="250px"
                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 0)} 
                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 1)}
                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 2)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <RatingAllCategoryChart height="250px" width="250px"
                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 0, true)} 
                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 1, true)}
                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 2, true)} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="mt-2 border-2 border-primary-dark-green w-[380px] mr-2">
                                <tbody>
                                    <tr>
                                        <td className="text-center text-xs">
                                            <div className="bg-[#d7ecd0] px-4">4 - 5</div>
                                            <div className="bg-[#fcf6bc] px-4">3</div>                        
                                            <div className="bg-[#f7b59b] px-4">1 - 2</div>
                                        </td>
                                        <td className="h-[52px] flex flex-row text-xs items-center justify-center">
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 70%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(270 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 50%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(180 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &lt; 50%</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div className="flex flex-row">
                                <table className="mr-2">
                                    <tbody>
                                        <tr>
                                            <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2 text-center">
                                                <div>Trend</div>
                                                <div>All Employees</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false, true)}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="mr-2">
                                    <tbody>
                                        <tr>
                                            <td className="h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2 text-center">
                                                <div>Trend</div>
                                                <div>Non-key Employees</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key'), false)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key'), false, true)}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="mr-2">
                                    <tbody>
                                        <tr>
                                            <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center" colSpan="2">
                                                <div>Trend</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                <div>Key Employees</div>
                                            </td>
                                            <td className="bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                <div>Supervisor</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), false)}/>
                                            </td>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), true)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), false, true)}/>
                                            </td>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), true, true)}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex flex-row items-center justify-center mt-2 border-2 border-primary-dark-green mr-2 h-[56px]">
                                <TrendArrow trend={2} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &lt; 2.5</div>
                                <TrendArrow trend={3} height="40px" width="40px" />
                                <div className="pl-1 pr-8">2.5 &le; Average &lt; 3.5</div>
                                <TrendArrow trend={5} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &ge; 3.5</div>
                            </div>
                        </div>
                    </div>
*/}
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                        REMS Profile - Direct Reports
                    </div>
                    <div className="w-full flex py-7">
                        <div className="w-5/12">
                            <SpiderChart width="640px" supervisor_answer="" personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false)} />
                        </div>
                        <div className="w-7/12 pt-9">
                            <table className="w-full">
                                <tbody>
                                    <tr className="text-lg text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green align-top text-left px-4" rowSpan="2">Category</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green w-2/12 text-left pl-4">Trend</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left w-1/3">Urgent Calls</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green w-1/3 text-left pl-4">Proposed</th>
                                    </tr>
                                    <tr className="text-lg text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4">
                                            Employee
                                        </th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4 text-sm">
                                            *Also, in previous round
                                        </th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4">
                                            Tools
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="px-6 border-t-2 border-x-2 border-primary-dark-green">JOB</td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center px-2">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), 0)} height="60px" width="60px" />
                                            </div>
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                <Fragment key={"ducallj"+index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false).substring(index, index + 1)) < 3 &&
                                                        <div className="flex flex-row">
                                                            <div className="font-bold">{question.question_no + '.'}</div>
                                                            <div className="ml-1">{question.title}{ selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1)[0]?.prev_round_name !== null && overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false, true) !== null && Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false, true).substring(index, index + 1)) < 3 && <span>*</span>}</div>
                                                        </div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "J" && tool.retention_department === 1).map((tool, index) => (
                                                <div key={"dtoolsjob" + index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool.id} id={"dtoolsjob" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/team/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                                </div>
                                            ))}
                                        </td>
{/*
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { recTools.filter(tool => String(tool.question_no).substring(0,1) === "J" && tool.retention_department === 1).filter((tool) => {
                                                if (Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false).substring(Number(String(tool.question_no).substring(1)) - 1, Number(String(tool.question_no).substring(1)))) < 3) return true;
                                                return false;
                                            }).filter((item, pos, self) => {
                                                return self.map(tool => tool.id).indexOf(item.id) === pos;
                                            }).map((tool, index) => (
                                                <div key={index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool?.id} id={"toolsjob" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSel(tool.id)} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2"><label htmlFor={"toolsjob" + index}>{tool?.title}</label></div>
                                                </div>
                                            ))}
                                        </td>
*/}
                                    </tr>

                                    <tr>
                                        <td className="px-6 border-t-2 border-x-2 border-primary-dark-green">LEADER</td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center px-2">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), 1)} height="60px" width="60px" />
                                            </div>
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                <Fragment key={"ducalll"+index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false).substring(4 + index, 4 + index + 1)) < 3 &&
                                                        <div className="flex flex-row">
                                                            <div className="font-bold">{question.question_no + '.'}</div>
                                                            <div className="ml-1">{question.title}{ selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1)[0]?.prev_round_name !== null && overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false, true) !== null && Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false, true).substring(4 + index, 4 + index + 1)) < 3 && <span>*</span>}</div>
                                                        </div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "L" && tool.retention_department === 1).map((tool, index) => (
                                                <div key={"dtoolsleader" + index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool.id} id={"dtoolsleader" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/team/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                                </div>
                                            ))}
                                        </td>
{/*
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { recTools.filter(tool => String(tool.question_no).substring(0,1) === "L" && tool.retention_department === 1).filter((tool) => {
                                                if (Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false).substring(4 + Number(String(tool.question_no).substring(1)) - 1, 4 + Number(String(tool.question_no).substring(1)))) < 3) return true;
                                                return false;
                                            }).filter((item, pos, self) => {
                                                return self.map(tool => tool.id).indexOf(item.id) === pos;
                                            }).map((tool, index) => (
                                                <div key={index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool?.id} id={"toolsleader" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSel(tool.id)} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2"><label htmlFor={"toolsleader" + index}>{tool?.title}</label></div>
                                                </div>
                                            ))}
                                        </td>
*/}
                                    </tr>

                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">REWARDS</td>

                                        <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center px-2">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), 2)} height="60px" width="60px" />
                                            </div>
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                <Fragment key={"ducallr"+index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false).substring(8 + index, 8 + index + 1)) < 3 &&
                                                        <div className="flex flex-row">
                                                            <div className="font-bold">{question.question_no + '.'}</div>
                                                            <div className="ml-1">{question.title}{ selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1)[0]?.prev_round_name !== null && overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false, true) !== null && Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false, true).substring(8 + index, 8 + index + 1)) < 3 && <span>*</span>}</div>
                                                        </div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>

                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "R" && tool.retention_department === 1).map((tool, index) => (
                                                <div key={"dtoolsreward" + index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool.id} id={"dtoolsreward" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/team/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                                </div>
                                            ))}
                                        </td>
{/*
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { recTools.filter(tool => String(tool.question_no).substring(0,1) === "R" && tool.retention_department === 1).filter((tool) => {
                                                if (Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false).substring(8 + Number(String(tool.question_no).substring(1)) - 1, 8 + Number(String(tool.question_no).substring(1)))) < 3) return true;
                                                return false;
                                            }).filter((item, pos, self) => {
                                                return self.map(tool => tool.id).indexOf(item.id) === pos;
                                            }).map((tool, index) => (
                                                <div key={index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool?.id} id={"toolsreward" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSel(tool.id)} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2"><label htmlFor={"toolsreward" + index}>{tool?.title}</label></div>
                                                </div>
                                            ))}
                                        </td>
*/}
                                    </tr>

                                    <tr>
                                        <td className="text-xl px-6 border-2 border-primary-dark-green">COMPANY</td>

                                        <td className="py-4 border-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center px-2">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), 3)} height="60px" width="60px" />
                                            </div>
                                        </td>

                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                <Fragment key={"ducallc"+index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false).substring(12 + index, 12 + index + 1)) < 3 &&
                                                        <div className="flex flex-row">
                                                            <div className="font-bold">{question.question_no + '.'}</div>
                                                            <div className="ml-1">{question.title}{ selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1)[0]?.prev_round_name !== null && overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false, true) !== null && Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false, true).substring(12 + index, 12 + index + 1)) < 3 && <span>*</span>}</div>
                                                        </div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>

                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "C" && tool.retention_department === 1).map((tool, index) => (
                                                <div key={"dtoolscompany" + index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool.id} id={"dtoolscompany" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={tool.selected} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2">{tool.title}<div className="inline-block mx-1"><Link to={`/${language}/profile/team/report/tool/${tool.id}`}><BsCardText /></Link></div></div>
                                                    
                                                </div>
                                            ))}
                                        </td>
{/*
                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { recTools.filter(tool => String(tool.question_no).substring(0,1) === "C" && tool.retention_department === 1).filter((tool) => {
                                                if (Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1), false).substring(12 + Number(String(tool.question_no).substring(1)) - 1, 12 + Number(String(tool.question_no).substring(1)))) < 3) return true;
                                                return false;
                                            }).filter((item, pos, self) => {
                                                return self.map(tool => tool.id).indexOf(item.id) === pos;
                                            }).map((tool, index) => (
                                                <div key={index} className="flex flex-row">
                                                    <div className="mt-[2px]"><input data-key={tool?.id} id={"toolscompany" + index} type="checkbox" className="w-4 h-4 bg-primary-grey" checked={checkSel(tool.id)} onChange={(e) => handleCheck(e)} /></div>
                                                    <div className="mx-2"><label htmlFor={"toolscompany" + index}>{tool?.title}</label></div>
                                                </div>
                                            ))}
                                        </td>
*/}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
{/*
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">REMS Profile - Direct Reports</div>
                    <div className="w-full flex py-7">
                        <div className="w-2/6">
                            <SpiderChart width="520px" supervisor_answer="" personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false)} />
                        </div>
                        <div className="w-4/6 pt-9">
                            <table className="w-full">
                                <tbody>
                                    <tr className="text-lg text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Category</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">Trend</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left">Urgent Calls</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Proposed Tools</th>
                                    </tr>
                                    <tr className="text-lg text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">
                                            Employee
                                        </th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4 text-sm">
                                            *Also, in previous round
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">JOB</td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 0)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(index, index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false, true).substring(index, index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "J").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(Number(String(tool.question_no).substring(1)) - 1, Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">LEADER</td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 1)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(4 + index, 4 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false, true).substring(4 + index, 4 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "L").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(4 + Number(String(tool.question_no).substring(1)) - 1, 4 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">REWARDS</td>
                                        <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 2)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(8 + index, 8 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false, true).substring(8 + index, 8 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "R").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(8 + Number(String(tool.question_no).substring(1)) - 1, 8 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-2 border-primary-dark-green">COMPANY</td>
                                        <td className="py-4 border-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), 3)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(12 + index, 12 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false, true).substring(12 + index, 12 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "C").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1), false).substring(12 + Number(String(tool.question_no).substring(1)) - 1, 12 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
*/}

                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                        Comparison Key vs Non-key - Direct Reports
                    </div>
                    <div className="w-full py-7 flex flex-row">
                        <div className="w-1/2">
                            <div className="text-center text-2xl">Key Employees</div>
                            <SpiderChart width="520px" supervisor_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1 && item.key_status === 'Key'), true)} personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1 && item.key_status === 'Key'), false)} />
                        </div>
                        <div className="w-1/2">
                            <div className="text-center text-2xl">Non-key Employees</div>
                            <SpiderChart width="520px" supervisor_answer="" personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1 && item.key_status === 'Non-key'), false)} />
                        </div>
                    </div>

                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">Trend Chart - Direct Reports (Key)</div>
                    <div className="w-full py-7 flex flex-wrap">
                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Key').map((emp, index) => ( 
                            <Fragment key={index}>
                                { index % 4 === 0 && 
                                    <Fragment> 
                                        <div className="basis-full h-0" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="h-[74px]"></td>
                                                </tr>
                                                <tr>
                                                    <td className="h-[180px] max-w-[80px]">
                                                        <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                                            <div>Current round:</div>
                                                            <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1 && item.key_status === 'Key')[0]?.round_name}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                                    <tr>
                                                        <td className="h-[180px] max-w-[80px]">
                                                            <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                                                <div>Previous round:</div>
                                                                <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1 && item.key_status === 'Key')[0]?.prev_round_name}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </Fragment>
                                }
                                <table className="mr-2">
                                    <tbody>
                                        <tr>
                                            <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center" colSpan="2">
                                                <div>Trend</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                <div>{emp.full_name}</div>
                                            </td>
                                            <td className="bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                <div>{emp.name_of_manager}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[180px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="180px" height="180px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), false)}/>
                                            </td>
                                            <td className="h-[180px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                                <TrendChart width="180px" height="180px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), true)}/>
                                            </td>
                                        </tr>
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                            <tr>
                                                <td className="h-[180px] border-primary-dark-green border-2 p-2">
                                                    <TrendChart width="180px" height="180px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), false, true)}/>
                                                </td>
                                                <td className="h-[180px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                                    <TrendChart width="180px" height="180px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), true, true)}/>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </Fragment>
                        ))}
                    </div>

                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">Trend Chart - Direct Reports (Non-key)</div>
                    <div className="w-full py-7 flex flex-wrap">
                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Non-key').map((emp, index) => ( 
                            <Fragment key={index}>
                                { index % 8 === 0 && 
                                    <Fragment> 
                                        <div className="basis-full h-0" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="h-[74px]"></td>
                                                </tr>
                                                <tr>
                                                    <td className="h-[180px] max-w-[80px]">
                                                        <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                                            <div>Current round:</div>
                                                            <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1 && item.key_status === 'Non-key')[0]?.round_name}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                                    <tr>
                                                        <td className="h-[180px] max-w-[80px]">
                                                            <div className="transform origin-center translate-y-[12px] rotate-[-90deg] whitespace-nowrap font-semibold">
                                                                <div>Previous round:</div>
                                                                <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1 && item.key_status === 'Non-key')[0]?.prev_round_name}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </Fragment>
                                }
                                <table className="mr-2">
                                    <tbody>
                                        <tr>
                                            <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center">
                                                <div>Trend</div>
                                                <div>{emp.full_name}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[180px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="180px" height="180px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Non-key' && item.full_name === emp.full_name), false)}/>
                                            </td>
                                        </tr>
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1)[0]?.prev_round_name !== null &&
                                            <tr>
                                                <td className="h-[180px] border-primary-dark-green border-2 p-2">
                                                    <TrendChart width="180px" height="180px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level === selectedPersonnel?.level + 1  && item.key_status === 'Non-key' && item.full_name === emp.full_name), false, true)}/>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </Fragment>
                        ))}
                    </div>

                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">HR Comment - Direct Reports</div>
                    <div className="mt-3 mb-12">
                        <div className="border-2 border-primary-dark-green">
                            <textarea id="commentdirect" rows="8" className="p-1 w-full" placeholder="Fluctuation rate:" value={hrcomment?.direct_report} onChange={e => hrcomment === undefined ? setHrcomment({ ...hrcomment, whole_hierarchy: '', direct_report: e.target.value }) : setHrcomment({ ...hrcomment, direct_report: e.target.value })} />
                        </div>
                    </div>

                </Fragment>
            }
{/*
            { tabs === 'directkey' &&
                <Fragment>
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                        Overview - Direct Reports (Key)
                    </div>
                    <div className="w-full py-7 flex flex-row">
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="h-[68px] w-[200px]"></td>
                                    </tr>
                                    <tr>
                                        <td className="h-[268px] p-2 pl-6">
                                            <div>Current round:</div>
                                            <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key')[0]?.round_name}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[268px] p-2 pl-6">
                                            <div>Previous round:</div>
                                            <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key')[0]?.prev_round_name}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="mr-2">
                                <tbody>
                                    <tr>
                                        <td className="w-[380px] h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2">
                                            <div>Employee Engagement level: {ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 0)}%</div>
                                            <div>Distribution of ratings across all categories</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <RatingAllCategoryChart height="250px" width="250px"
                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 0)} 
                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 1)}
                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 2)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <RatingAllCategoryChart height="250px" width="250px"
                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 0, true)} 
                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 1, true)}
                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 2, true)} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="mt-2 border-2 border-primary-dark-green w-[380px] mr-2">
                                <tbody>
                                    <tr>
                                        <td className="text-center text-xs">
                                            <div className="bg-[#d7ecd0] px-4">4 - 5</div>
                                            <div className="bg-[#fcf6bc] px-4">3</div>                        
                                            <div className="bg-[#f7b59b] px-4">1 - 2</div>
                                        </td>
                                        <td className="h-[52px] flex flex-row text-xs items-center justify-center">
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 70%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(270 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 50%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(180 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &lt; 50%</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div className="flex flex-row">
                                <table className="mr-2">
                                    <tbody>
                                        <tr>
                                            <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center" colSpan="2">
                                                <div>Trend</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[33px] w-[290px] bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                <div>Key Employees</div>
                                            </td>
                                            <td className="w-[290px] bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                                <div>Supervisor</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), false)}/>
                                            </td>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), true)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), false, true)}/>
                                            </td>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key'), true, true)}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex flex-row items-center justify-center mt-2 border-2 border-primary-dark-green mr-2 h-[56px]">
                                <TrendArrow trend={2} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &lt; 2.5</div>
                                <TrendArrow trend={3} height="40px" width="40px" />
                                <div className="pl-1 pr-8">2.5 &le; Average &lt; 3.5</div>
                                <TrendArrow trend={5} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &ge; 3.5</div>
                            </div>
                        </div>
                    </div>

                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">REMS Profile - Direct Reports (Key)</div>
                    <div className="w-full flex py-7">
                        <div className="w-2/6">
                            <SpiderChart width="520px" supervisor_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true)} personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false)} />
                        </div>
                        <div className="w-4/6 pt-9">
                            <table className="w-full">
                                <tbody>
                                    <tr className="text-lg text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Category</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green" colSpan="2">Trend</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left">Urgent Calls</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left">Deviations</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Proposed Tools</th>
                                    </tr>
                                    <tr className="text-lg text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">
                                            Employee
                                        </th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">
                                            Supervisor
                                        </th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4 text-sm">
                                            *Also, in previous round
                                        </th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4 text-sm">
                                            *Also, in previous round
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">JOB</td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 0)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 0, true)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(index, index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(index, index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true).substring(index, index + 1)) - 
                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(index, index + 1))) > 1 &&
                                                        <div>{question.question_no + '.' + question.title}{ Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true, true).substring(index, index + 1)) -
                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(index, index + 1))) > 1 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "J").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(Number(String(tool.question_no).substring(1)) - 1, Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">LEADER</td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 1)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 1, true)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(4 + index, 4 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(4 + index, 4 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true).substring(4 + index, 4 + index + 1)) -
                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(4 + index, 4 + index + 1))) > 1 &&
                                                        <div>{question.question_no + '.' + question.title}{ Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true, true).substring(4 + index, 4 + index + 1)) -
                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(4 + index, 4 + index + 1))) > 1 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "L").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(4 + Number(String(tool.question_no).substring(1)) - 1, 4 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">REWARDS</td>
                                        <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 2)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 2, true)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(8 + index, 8 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(8 + index, 8 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true).substring(8 + index, 8 + index + 1)) -
                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(8 + index, 8 + index + 1))) > 1 &&
                                                        <div>{question.question_no + '.' + question.title}{ Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true, true).substring(8 + index, 8 + index + 1)) -
                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(8 + index, 8 + index + 1))) > 1 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "R").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(8 + Number(String(tool.question_no).substring(1)) - 1, 8 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-2 border-primary-dark-green">COMPANY</td>
                                        <td className="py-4 border-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 3)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), 3, true)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(12 + index, 12 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(12 + index, 12 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true).substring(12 + index, 12 + index + 1)) -
                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(12 + index, 12 + index + 1))) > 1 &&
                                                        <div>{question.question_no + '.' + question.title}{ Math.abs(Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), true, true).substring(12 + index, 12 + index + 1)) -
                                                        Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false, true).substring(12 + index, 12 + index + 1))) > 1 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "C").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key'), false).substring(12 + Number(String(tool.question_no).substring(1)) - 1, 12 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">Trend Chart - Direct Reports (Key)</div>
                    <div className="w-full py-7 flex flex-row">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="h-[68px] w-[200px]"></td>
                                </tr>
                                <tr>
                                    <td className="h-[268px] p-2 pl-6">
                                        <div>Current round:</div>
                                        <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key')[0]?.round_name}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="h-[268px] p-2 pl-6">
                                        <div>Previous round:</div>
                                        <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Key')[0]?.prev_round_name}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key').map((emp, index) => ( 
                            <table className="mr-2" key={index}>
                                <tbody>
                                    <tr>
                                        <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center" colSpan="2">
                                            <div>Trend</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                            <div>{emp.full_name}</div>
                                        </td>
                                        <td className="bg-primary-dark-green text-primary-white px-4 pb-2 border-primary-dark-green border-2 text-center">
                                            <div>{emp.name_of_manager}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), false)}/>
                                        </td>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                            <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), true)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), false, true)}/>
                                        </td>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2 bg-[#bddce3]">
                                            <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Key' && item.full_name === emp.full_name), true, true)}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ))}
                    </div>

                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">HR Comment - Direct Reports (Key)</div>
                    <div className="mt-3 mb-12">
                        <div className="border-2 border-primary-dark-green">
                            <textarea id="commentkey" rows="8" className="p-1 w-full" placeholder="Fluctuation rate:"/>
                        </div>
                    </div>
                </Fragment>
            }

            { tabs === 'directnonkey' &&
                <Fragment>
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">
                        Overview - Direct Reports (Non-key)
                    </div>
                    <div className="w-full py-7 flex flex-row">
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="h-[68px] w-[200px]"></td>
                                    </tr>
                                    <tr>
                                        <td className="h-[268px] p-2 pl-6">
                                            <div>Current round:</div>
                                            <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key')[0]?.round_name}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[268px] p-2 pl-6">
                                            <div>Previous round:</div>
                                            <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key')[0]?.prev_round_name}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className="mr-2">
                                <tbody>
                                    <tr>
                                        <td className="w-[380px] h-[70px] bg-primary-dark-green text-primary-white px-4 py-2 border-primary-dark-green border-2">
                                            <div>Employee Engagement level: {ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 0)}%</div>
                                            <div>Distribution of ratings across all categories</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <RatingAllCategoryChart height="250px" width="250px"
                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 0)} 
                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 1)}
                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 2)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <RatingAllCategoryChart height="250px" width="250px"
                                                rating_g={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 0, true)} 
                                                rating_y={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 1, true)}
                                                rating_r={ratingPerAnswerGroupAvgAll(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 2, true)} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="mt-2 border-2 border-primary-dark-green w-[380px] mr-2">
                                <tbody>
                                    <tr>
                                        <td className="text-center text-xs">
                                            <div className="bg-[#d7ecd0] px-4">4 - 5</div>
                                            <div className="bg-[#fcf6bc] px-4">3</div>                        
                                            <div className="bg-[#f7b59b] px-4">1 - 2</div>
                                        </td>
                                        <td className="h-[52px] flex flex-row text-xs items-center justify-center">
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 70%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(270 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &ge; 50%</div>
                                            <svg width="35" height="35" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="rotate(180 100 100)">
                                                    <image href={require('../assets/cthumb.png')} x="0" y="0" height="200" width="200" />
                                                </g>
                                            </svg>
                                            <div className="pl-1 pr-2">EE &lt; 50%</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div className="flex flex-row">
                                <table className="mr-2">
                                    <tbody>
                                        <tr>
                                            <td className="h-[70px] w-[580px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center">
                                                <div>Trend</div>
                                                <div>Non-key Employees</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key'), false)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                                <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key'), false, true)}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex flex-row items-center justify-center mt-2 border-2 border-primary-dark-green mr-2 h-[56px]">
                                <TrendArrow trend={2} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &lt; 2.5</div>
                                <TrendArrow trend={3} height="40px" width="40px" />
                                <div className="pl-1 pr-8">2.5 &le; Average &lt; 3.5</div>
                                <TrendArrow trend={5} height="40px" width="40px" />
                                <div className="pl-1 pr-8">Average &ge; 3.5</div>
                            </div>
                        </div>
                    </div>

                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">REMS Profile - Direct Reports (Non-key)</div>
                    <div className="w-full flex py-7">
                        <div className="w-2/6">
                            <SpiderChart width="520px" supervisor_answer="" personnel_answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false)} />
                        </div>
                        <div className="w-4/6 pt-9">
                            <table className="w-full">
                                <tbody>
                                    <tr className="text-lg text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Category</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">Trend</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green pl-4 text-left">Urgent Calls</th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green py-2" rowSpan="2">Proposed Tools</th>
                                    </tr>
                                    <tr className="text-lg text-primary-white">
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green">
                                            Employee
                                        </th>
                                        <th className="border-x-2 border-primary-dark-green bg-primary-dark-green text-left pl-4 text-sm">
                                            *Also, in previous round
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">JOB</td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 0)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "J").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(index, index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false, true).substring(index, index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "J").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(Number(String(tool.question_no).substring(1)) - 1, Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">LEADER</td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 1)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "L").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(4 + index, 4 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false, true).substring(4 + index, 4 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "L").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(4 + Number(String(tool.question_no).substring(1)) - 1, 4 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-t-2 border-x-2 border-primary-dark-green">REWARDS</td>
                                        <td className="py-4 border-t-2 border-x-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 2)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "R").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(8 + index, 8 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false, true).substring(8 + index, 8 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-x-2 border-t-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "R").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(8 + Number(String(tool.question_no).substring(1)) - 1, 8 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl px-6 border-2 border-primary-dark-green">COMPANY</td>
                                        <td className="py-4 border-2 border-primary-dark-green">
                                            <div className="flex items-center justify-center">
                                                <TrendArrow trend={trendByCategory(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), 3)} height="60px" width="60px" />
                                            </div>
                                        </td>
                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { currentQuestions.filter(question => String(question.question_no).substring(0,1) === "C").map((question, index) => (
                                                <Fragment key={index}>
                                                    { Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(12 + index, 12 + index + 1)) < 3 &&
                                                        <div>{question.question_no + '.' + question.title}{ Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false, true).substring(12 + index, 12 + index + 1)) < 3 && <span>*</span>}</div>
                                                    }
                                                </Fragment>
                                            ))}
                                        </td>
                                        <td className="py-4 border-2 border-primary-dark-green pl-4 align-top">
                                            { tools.filter(tool => String(tool.question_no).substring(0,1) === "C").map((tool) => {
                                                let result = null;
                                                Number(overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key'), false).substring(12 + Number(String(tool.question_no).substring(1)) - 1, 12 + Number(String(tool.question_no).substring(1)))) < 3 ? result =  tool.title : result = null;
                                                return result;
                                            }).filter((item, pos, self) => {
                                                return self.indexOf(item) === pos;
                                            }).map((tool, index) => (<div key={index}>{tool}</div>))}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">Trend Chart - Direct Reports (Non-key)</div>
                    <div className="w-full py-7 flex flex-row">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="h-[68px] w-[200px]"></td>
                                </tr>
                                <tr>
                                    <td className="h-[268px] p-2 pl-6">
                                        <div>Current round:</div>
                                        <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key')[0]?.round_name}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="h-[268px] p-2 pl-6">
                                        <div>Previous round:</div>
                                        <div>{selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1 && item.key_status === 'Non-key')[0]?.prev_round_name}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key').map((emp, index) => ( 
                            <table className="mr-2" key={index}>
                                <tbody>
                                    <tr>
                                        <td className="h-[33px] bg-primary-dark-green text-primary-white px-4 pt-2 border-primary-dark-green border-2 text-center">
                                            <div>Trend</div>
                                            <div>{emp.full_name}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key' && item.full_name === emp.full_name), false)}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="h-[250px] border-primary-dark-green border-2 p-2">
                                            <TrendChart width="250px" height="250px" answer={overallAverageAnswer(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level === selectedPersonnel.level + 1  && item.key_status === 'Non-key' && item.full_name === emp.full_name), false, true)}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ))}
                    </div>

                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-3">HR Comment - Direct Reports (Non-key)</div>
                    <div className="mt-3 mb-12">
                        <div className="border-2 border-primary-dark-green">
                            <textarea id="commentnonkey" rows="8" className="p-1 w-full" placeholder="Fluctuation rate:"/>
                        </div>
                    </div>
                </Fragment>
            }
*/}

            { tabs === 'special' &&
                <Fragment>
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                        Special Report 1: Employee Satisfaction per Category
                    </div>
                    <div className="flex flex-row py-7">
                        <table className="w-full h-full">
                            <tbody>
                                <tr>
                                    <td className="w-4/5 bg-primary-dark-green text-primary-white border-2 border-primary-dark-green p-2" colSpan="4">Distribution of employee ratings per category</td>
                                </tr>
                                <tr>
                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                        <div className="flex flex-row justify-center pt-2">Job<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0)}</span></div>
                                        <RatingCategoryChart height="380px" width="380px" category="job"
                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, 0)} 
                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, 1)}
                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, 2)} />
                                    </td>
                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                        <div className="flex flex-row justify-center pt-2">Leader<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1)}</span></div>
                                        <RatingCategoryChart height="380px" width="380px" category="leader"
                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, 0)} 
                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, 1)}
                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, 2)} />
                                    </td>
                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                        <div className="flex flex-row justify-center pt-2">Rewards<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2)}</span></div>
                                        <RatingCategoryChart height="380px" width="380px" category="rewards"
                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, 0)} 
                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, 1)}
                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, 2)} />
                                    </td>
                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                        <div className="flex flex-row justify-center pt-2">Company<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3)}</span></div>
                                        <RatingCategoryChart height="380px" width="380px" category="company"
                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3, 0)} 
                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3, 1)}
                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3, 2)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-4/5 bg-primary-dark-green text-primary-white border-2 border-primary-dark-green p-2" colSpan="4">Overall rating (trend) and accumulated number of urgent calls per question</td>
                                </tr>
                                <tr>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, 0)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, 0)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "J1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, 0)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, 0)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "L1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, 0)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, 0)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "R1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3, 0)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3, 0)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "C1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, 1)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, 1)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "J2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, 1)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, 1)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "L2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, 1)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, 1)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "R2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3, 1)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3, 1)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "C2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, 2)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, 2)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "J3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, 2)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, 2)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "L3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, 2)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, 2)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "R3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3, 2)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3, 2)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "C3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, 3)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 0, 3)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "J4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                </td>
                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, 3)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 1, 3)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "L4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, 3)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 2, 3)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "R4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3, 3)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1), 3, 3)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "C4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
{/*
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                        Special Report 1: Employee Satisfaction per Category
                    </div>
                    <div className="flex flex-row py-7">
                        <table className="w-full h-full">
                            <tbody>
                                <tr>
                                    <td className="w-4/5 bg-primary-dark-green text-primary-white border-2 border-primary-dark-green p-2" colSpan="4">Distribution of employee ratings per category</td>
                                </tr>
                                <tr>
                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                        <div className="flex flex-row justify-center pt-2">Job<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0)}</span></div>
                                        <RatingCategoryChart height="200px" width="200px" category="job"
                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 0)} 
                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 1)}
                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 2)} />
                                    </td>
                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                        <div className="flex flex-row justify-center pt-2">Leader<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1)}</span></div>
                                        <RatingCategoryChart height="200px" width="200px" category="leader"
                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 0)} 
                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 1)}
                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 2)} />
                                    </td>
                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                        <div className="flex flex-row justify-center pt-2">Rewards<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2)}</span></div>
                                        <RatingCategoryChart height="200px" width="200px" category="rewards"
                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 0)} 
                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 1)}
                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 2)} />
                                    </td>
                                    <td className="border-2 border-primary-dark-green w-1/5 text-center text-2xl">
                                        <div className="flex flex-row justify-center pt-2">Company<span className="pl-2">{ratingPerCategoryAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3)}</span></div>
                                        <RatingCategoryChart height="200px" width="200px" category="company"
                                            rating_g={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 0)} 
                                            rating_y={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 1)}
                                            rating_r={ratingPerAnswerGroupAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 2)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-4/5 bg-primary-dark-green text-primary-white border-2 border-primary-dark-green p-2" colSpan="4">Overall rating (trend) and accumulated number of urgent calls per question</td>
                                </tr>
                                <tr>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 0)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 0)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "J1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 0)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 0)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "L1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 0)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 0)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "R1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 0)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 0)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "C1").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 1)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 1)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "J2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 1)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 1)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "L2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 1)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 1)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "R2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 1)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 1)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "C2").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 2)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 2)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "J3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 2)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 2)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "L3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 2)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 2)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "R3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-primary-dark-green">
                                        <div className="border-b-2 border-primary-black border-dotted pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 2)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 2)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "C3").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 3)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 0, 3)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "J4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                </td>
                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 3)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 1, 3)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "L4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 3)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 2, 3)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "R4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                    <td className="border-x-2 border-b-2 border-primary-dark-green">
                                        <div className="pl-2 h-9 flex flex-row justify-start items-center">
                                            <span>{ratingUrgentCallperQuestionAvg(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 3)}</span>
                                            <span className="text-primary-red mx-2">{countUrgentCallperQuestion(selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel.group_id) && item.level >= selectedPersonnel.level + 1), 3, 3)}</span>
                                            <span>{currentQuestions.filter(question => question.question_no === "C4").map((question, index) => (<Fragment key={index}>{question.question_no + '.' + question.title}</Fragment>))}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
*/}

                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                        Special Report 2: Urgent Calls vs Deviations
                    </div>
                    <div className="py-7">
                        <table className="w-full">
                            <tbody>
                                <tr>
                                    <td className="w-[34px] h-[34px]"></td>
                                    <td className="px-2 border-r-2 text-sm whitespace-nowrap">Sup/Emp View<br />(% Deviations)</td>
                                    <td colSpan="7"></td>
                                </tr>
                                <tr>
                                    <td className="text-xs bg-[#ed9883] text-center align-middle max-w-[34px]"><div className="transform origin-center translate-y-[12px] rotate-[-90deg]">EXTREME</div></td>
                                    <td className="text-sm text-center py-6 px-2 border-2 ">x &gt; 75%</td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f4ed9f]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 75 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f4ed9f]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 75 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#ed9883]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 75 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#ed9883]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 75 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#ed9883]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 75 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="text-xs bg-gradient-to-t from-[#f4ed9f] to-[#ed9883] text-center align-middle max-w-[34px]" rowSpan="2"><div className="transform origin-center rotate-[-90deg]">HIGH</div></td>
                                    <td className="text-sm text-center py-6 px-2 border-2">50% &lt; x &le; 75%</td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f4ed9f]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 50 && percentageDeviation(item) <= 75 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f4ed9f]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 50 && percentageDeviation(item) <= 75 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#ed9883]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 50 && percentageDeviation(item) <= 75 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#ed9883]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 50 && percentageDeviation(item) <= 75 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#ed9883]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 50 && percentageDeviation(item) <= 75 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="text-sm text-center py-6 px-2 border-2">25% &lt; x &le; 50%</td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f4ed9f]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 25 && percentageDeviation(item) <= 50 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f4ed9f]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 25 && percentageDeviation(item) <= 50 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#ed9883]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 25 && percentageDeviation(item) <= 50 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#ed9883]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 25 && percentageDeviation(item) <= 50 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#ed9883]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 25 && percentageDeviation(item) <= 50 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="text-xs bg-gradient-to-t from-[#cae1c2] to-[#f4ed9f] text-center align-middle max-w-[34px]"><div className="transform origin-center translate-y-[8px] rotate-[-90deg]">MEDIUM</div></td>
                                    <td className="text-sm text-center py-6 px-2 border-2">12.5% &lt; x &le; 25%</td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#cae1c2]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 12.5 && percentageDeviation(item) <= 25 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#cae1c2]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 12.5 && percentageDeviation(item) <= 25 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f1c391]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 12.5 && percentageDeviation(item) <= 25 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f1c391]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 12.5 && percentageDeviation(item) <= 25 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f1c391]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) > 12.5 && percentageDeviation(item) <= 25 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="text-xs bg-[#cae1c2] text-center align-middle max-w-[34px]"><div className="transform origin-center rotate-[-90deg]">LOW</div></td>
                                    <td className="text-sm text-center py-6 px-2 border-2">x &le; 12.5%</td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#cae1c2]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) <= 12.5 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#cae1c2]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) <= 12.5 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f1c391]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) <= 12.5 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f1c391]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) <= 12.5 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-[#f1c391]">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Key').map((item, index) => (
                                                (percentageDeviation(item) <= 12.5 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td></td>
                                </tr>
                                <tr className="text-sm">
                                    <td></td>
                                    <td></td>
                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &le; 12.5%</td>
                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">12.5% &lt; x &le; 25%</td>
                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">25% &lt; x &le; 50%</td>
                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">50% &lt; x &le; 75%</td>
                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &gt; 75%</td>
                                    <td className="border-t-2 px-2 whitespace-nowrap">Retention Risk<br />(% Urgent Calls)</td>
                                </tr>
                                <tr className="text-xs">
                                    <td></td>
                                    <td></td>
                                    <td className="w-1/12 text-center py-2 border-t-2 bg-[#cae1c2]">LOW</td>
                                    <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#cae1c2] to-[#f4ed9f]">MEDIUM</td>
                                    <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#f4ed9f] to-[#ed9883]" colSpan="2">HIGH</td>
                                    <td className="w-1/12 text-center py-2 border-t-2 bg-[#ed9883]">EXTREME</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className="text-center py-6 px-2 text-sm">Non-key</td>
                                    <td className="border-2 w-2/12 py-6 px-2">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Non-key').map((item, index) => (
                                                (percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Non-key').map((item, index) => (
                                                (percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Non-key').map((item, index) => (
                                                (percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Non-key').map((item, index) => (
                                                (percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && item.key_status === 'Non-key').map((item, index) => (
                                                (percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}</div>
                                            ))
                                        }
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                        Special Report 3: Retention Risk by Job Tenure
                    </div>
                    <div className="py-7">
                        <table className="w-full">
                            <tbody>
                                <tr>
                                    <td className="px-2 border-r-2 text-sm">Job Tenure<br />(Years)</td>
                                    <td colSpan="7"></td>
                                </tr>
                                <tr>
                                    <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">Year &ge; 5</td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 5 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 5 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 5 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 5 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 5 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="w-1/12"></td>
                                </tr>
                                <tr>
                                    <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">4 &le; year &lt; 5</td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 4 && item.job_tenure < 5 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 4 && item.job_tenure < 5 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 4 && item.job_tenure < 5 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 4 && item.job_tenure < 5 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 4 && item.job_tenure < 5 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">3 &le; year &lt; 4</td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 3 && item.job_tenure < 4 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 3 && item.job_tenure < 4 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 3 && item.job_tenure < 4 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 3 && item.job_tenure < 4 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 3 && item.job_tenure < 4 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">1 &le; year &lt; 3</td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 1 && item.job_tenure < 3 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 1 && item.job_tenure < 3 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 1 && item.job_tenure < 3 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 1 && item.job_tenure < 3 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure >= 1 && item.job_tenure < 3 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">Year &lt; 1</td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure < 1 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure < 1 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure < 1 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure < 1 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                        { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                (item.job_tenure < 1 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                            ))
                                        }
                                    </td>
                                    <td></td>
                                </tr>
                                <tr className="text-sm">
                                    <td></td>
                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &le; 12.5%</td>
                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">12.5% &lt; x &le; 25%</td>
                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">25% &lt; x &le; 50%</td>
                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">50% &lt; x &le; 75%</td>
                                    <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &gt; 75%</td>
                                    <td className="border-t-2 px-2">Retention Risk<br />(% Urgent Calls)</td>
                                </tr>
                                <tr className="text-xs">
                                    <td></td>
                                    <td className="w-1/12 text-center py-2 border-t-2 bg-[#cae1c2]">LOW</td>
                                    <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#cae1c2] to-[#f4ed9f]">MEDIUM</td>
                                    <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#f4ed9f] to-[#ed9883]" colSpan="2">HIGH</td>
                                    <td className="w-1/12 text-center py-2 border-t-2 bg-[#ed9883]">EXTREME</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="text-primary-white bg-primary-dark-green text-3xl px-5 py-3 font-medium mt-9">
                        Special Report 4: Retention Risk by FES
                    </div>
                    { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && Number(item.fes_level) >= 17).length > 0 &&
                        <div className="py-7">
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className="px-2 border-r-2 text-sm">FES<br />&nbsp;</td>
                                        <td colSpan="7"></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 22+</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) >= 22 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) >= 22 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) >= 22 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) >= 22 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) >= 22 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="w-1/12"></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 21</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 21 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 21 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 21 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 21 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 21 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 20</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 20 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 20 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 20 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 20 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 20 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 19</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 19 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 19 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 19 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 19 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 19 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 18</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 18 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 18 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 18 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 18 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 18 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 17</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 17 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 17 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 17 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 17 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 17 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr className="text-sm">
                                        <td></td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &le; 12.5%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">12.5% &lt; x &le; 25%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">25% &lt; x &le; 50%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">50% &lt; x &le; 75%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &gt; 75%</td>
                                        <td className="border-t-2 px-2">Retention Risk<br />(% Urgent Calls)</td>
                                    </tr>
                                    <tr className="text-xs">
                                        <td></td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#cae1c2]">LOW</td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#cae1c2] to-[#f4ed9f]">MEDIUM</td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#f4ed9f] to-[#ed9883]" colSpan="2">HIGH</td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#ed9883]">EXTREME</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                    { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && Number(item.fes_level) < 17 && Number(item.fes_level) >= 11 ).length > 0 &&
                        <div className="py-7">
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className="px-2 border-r-2 text-sm">FES<br />&nbsp;</td>
                                        <td colSpan="7"></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 16</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 16 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 16 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 16 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 16 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 16 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="w-1/12"></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 15</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 15 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 15 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 15 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 15 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 15 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 14</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 14 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 14 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 14 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 14 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 14 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 13</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 13 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 13 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 13 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 13 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 13 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 12</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 12 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 12 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 12 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 12 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 12 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 11</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 11 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 11 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 11 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 11 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 11 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr className="text-sm">
                                        <td></td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &le; 12.5%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">12.5% &lt; x &le; 25%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">25% &lt; x &le; 50%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">50% &lt; x &le; 75%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &gt; 75%</td>
                                        <td className="border-t-2 px-2">Retention Risk<br />(% Urgent Calls)</td>
                                    </tr>
                                    <tr className="text-xs">
                                        <td></td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#cae1c2]">LOW</td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#cae1c2] to-[#f4ed9f]">MEDIUM</td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#f4ed9f] to-[#ed9883]" colSpan="2">HIGH</td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#ed9883]">EXTREME</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                    { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1 && Number(item.fes_level) < 11 && Number(item.fes_level) >= 5 ).length > 0 &&
                        <div className="py-7">
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className="px-2 border-r-2 text-sm">FES<br />&nbsp;</td>
                                        <td colSpan="7"></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 10</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 10 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 10 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 10 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 10 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 10 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="w-1/12"></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 9</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 9 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 9 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 9 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 9 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 9 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 8</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 8 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 8 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 8 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 8 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 8 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 7</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 7 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 7 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 7 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 7 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 7 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 6</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 6 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 6 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 6 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 6 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 6 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="w-1/12 text-center py-6 px-2 border-r-2 text-sm">FES 5</td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 5 && percentageUrgentcall(item) <= 12.5) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 5 && percentageUrgentcall(item) > 12.5 && percentageUrgentcall(item) <= 25 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 5 && percentageUrgentcall(item) > 25 && percentageUrgentcall(item) <= 50 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 5 && percentageUrgentcall(item) > 50 && percentageUrgentcall(item) <= 75 ) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td className="border-2 w-2/12 py-6 px-2 bg-slate-50">
                                            { selectedforReport.filter((item) => String(item.group_id).startsWith(selectedPersonnel?.group_id) && item.level >= selectedPersonnel?.level + 1).map((item, index) => (
                                                    (Number(item.fes_level) === 5 && percentageUrgentcall(item) > 75) && <div key={index}>{item.full_name}{item.key_status === 'Key' && ' (Key)'}</div>
                                                ))
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr className="text-sm">
                                        <td></td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &le; 12.5%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">12.5% &lt; x &le; 25%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">25% &lt; x &le; 50%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">50% &lt; x &le; 75%</td>
                                        <td className="border-2 w-1/12 text-center py-3 px-2 border-t-2">x &gt; 75%</td>
                                        <td className="border-t-2 px-2">Retention Risk<br />(% Urgent Calls)</td>
                                    </tr>
                                    <tr className="text-xs">
                                        <td></td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#cae1c2]">LOW</td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#cae1c2] to-[#f4ed9f]">MEDIUM</td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-gradient-to-r from-[#f4ed9f] to-[#ed9883]" colSpan="2">HIGH</td>
                                        <td className="w-1/12 text-center py-2 border-t-2 bg-[#ed9883]">EXTREME</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </Fragment>
            }
            <Outlet />
            {isLoading && <Loading /> }
        </div>
    );
}

export default TeamReport;
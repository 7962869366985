import { useEffect, useState } from 'react';
import api from '../Api';
import Loading from "../Loading";

function QuestionnaireTemplate({ setNavbar, setActiveMenu, forceRefresh, setForceRefresh }) {
    const [isLoading, setIsLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setActiveMenu(6);
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    useEffect(() => {
        const fetchKeyIDTemplates = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/template/questionnaire?key=${forceRefresh}`);
                setTemplates(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchKeyIDTemplates();
    },[forceRefresh]);

    const putTemplate = async (templates) => {
        try {
            setIsLoading(true);
            const response = await api.put('/template',templates);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSave = () => {
        if (isEdit) {
            putTemplate(templates.map(({id, feature, item, t_text}) => { return Object.values({feature, item, t_text, id}) }));
        } 
        setIsEdit(!isEdit);
    }

    const handleTemplate = key => e => {
        let newTemplates = [...templates];
        newTemplates[newTemplates.findIndex ((template => template.feature === 'questionnaire' && template.item === key ))].t_text = e.target.value;
        setTemplates(newTemplates);
    }

    return (
        <div className="w-11/12 py-3">
            <h1 className="text-2xl font-medium text-primary-black">Questionnaire Template</h1>
            <div className="w-full ">
                <div className="flex flex-row text-primary-black py-3">
                    <button className="bg-primary-green text-primary-white text-lg font-medium px-10 py-1 rounded-lg" onClick={handleSave}>{isEdit ? `Save` : `Edit`}</button>
                </div>
                <form className="text-primary-black w-full">
                    <fieldset disabled={!isEdit} className="flex flex-row justify-between">
                        <div className="w-full">
                            <label htmlFor="qnairesub" className="float-left w-2/12 pt-2">Email subject</label>
                            <input type="text" className="p-1 border rounded-sm mb-3 float-left w-10/12" 
                                value={!isLoading ? templates.find(({feature, item}) => feature === 'questionnaire' && item === 'qnairesub').t_text : ''}
                                onChange={handleTemplate('qnairesub')} 
                            />

                            <label htmlFor="qnairehtml" className="float-left w-2/12 pt-2">Email text</label>
                            <textarea rows="4" className="p-2 border rounded-sm mb-3 float-left w-10/12" 
                                value={!isLoading ? templates.find(({feature, item}) => feature === 'questionnaire' && item === 'qnairehtml').t_text : ''} 
                                onChange={handleTemplate('qnairehtml')} 
                            />

                            <label htmlFor="remindsub" className="float-left w-2/12 pt-2">Reminder subject</label>
                            <input type="text" className="p-1 border rounded-sm mb-3 float-left w-10/12" 
                                value={!isLoading ? templates.find(({feature, item}) => feature === 'questionnaire' && item === 'remindsub').t_text : ''}
                                onChange={handleTemplate('remindsub')} 
                            />

                            <label htmlFor="remindhtml" className="float-left w-2/12 pt-2">Reminder text</label>
                            <textarea rows="4" className="p-2 border rounded-sm mb-3 float-left w-10/12" 
                                value={!isLoading ? templates.find(({feature, item}) => feature === 'questionnaire' && item === 'remindhtml').t_text : ''}
                                onChange={handleTemplate('remindhtml')} 
                            />

                            <label htmlFor="qnaireext" className="float-left w-2/12 pt-2">Questionnaire-Supervisor text</label>
                            <textarea rows="4" className="p-2 border rounded-sm mb-3 float-left w-10/12" 
                                value={!isLoading ? templates.find(({feature, item}) => feature === 'questionnaire' && item === 'qnairetexts').t_text : ''}
                                onChange={handleTemplate('qnairetext')} 
                            />

                            <label htmlFor="qnaireext" className="float-left w-2/12 pt-2">Questionnaire-Staff text</label>
                            <textarea rows="4" className="p-2 border rounded-sm mb-3 float-left w-10/12" 
                                value={!isLoading ? templates.find(({feature, item}) => feature === 'questionnaire' && item === 'qnairetexti').t_text : ''}
                                onChange={handleTemplate('qnairetext')} 
                            />
                        </div>
                    </fieldset>
                </form>
            </div>
            {isLoading && <Loading />}
        </div>
    );
}

export default QuestionnaireTemplate;
import { Fragment } from "react";

function TrendArrow ({ trend = 0, width = 600, height = 600 }) {

    return (
        <svg width={width} height={height} className="block" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
            { trend !== 0 &&
                <Fragment>
                    { trend < 2.5 && 
                        <Fragment>
                            <g transform="rotate(270 66 66)">
                                <g transform="translate(131,66)">
                                    <path fill="#888e95" d="M 1 0 l 0 -16 l -88 0 l 0 -16 l -45 32 l 45 32 l 0 -16 l 88 0 z"></path>
                                    <path fill="crimson" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 42 30 l 0 -15 l 88 0 z"></path>
                                    <path fill="#c82848" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 130 0 z"></path>
                                    <path fill="#b01030" d="M -103 0 l 15 -15 l 0 -15 l -15 30 l 15 30 l 0 -15 l -15 -15 z"></path>
                                    <path fill="#b01030" d="M 0 0 l 0 -15 l -15 15 l 15 15 z"></path>
                                </g>
                            </g>
                        </Fragment>
                    }

                    { trend >= 2.5 && trend < 3.5 && 
                        <Fragment>
                            <g transform="rotate(180 66 66)">
                                <g transform="translate(131,66)">
                                    <path fill="#888e95" d="M 1 0 l 0 -16 l -88 0 l 0 -16 l -45 32 l 45 32 l 0 -16 l 88 0 z"></path>
                                    <path fill="gold" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 42 30 l 0 -15 l 88 0 z"></path>
                                    <path fill="#e6c619" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 130 0 z"></path>
                                    <path fill="#ccac00" d="M -103 0 l 15 -15 l 0 -15 l -15 30 l 15 30 l 0 -15 l -15 -15 z"></path>
                                    <path fill="#ccac00" d="M 0 0 l 0 -15 l -15 15 l 15 15 z"></path>
                                </g>
                            </g>
                        </Fragment>
                    }

                    { trend >= 3.5 && 
                        <Fragment>
                            <g transform="rotate(90 66 66)">
                                <g transform="translate(131,66)">
                                    <path fill="#888e95" d="M 1 0 l 0 -16 l -88 0 l 0 -16 l -45 32 l 45 32 l 0 -16 l 88 0 z"></path>
                                    <path fill="limegreen" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 42 30 l 0 -15 l 88 0 z"></path>
                                    <path fill="#42be42" d="M 0 0 l 0 -15 l -88 0 l 0 -15 l -42 30 l 130 0 z"></path>
                                    <path fill="#28a428" d="M -103 0 l 15 -15 l 0 -15 l -15 30 l 15 30 l 0 -15 l -15 -15 z"></path>
                                    <path fill="#28a428" d="M 0 0 l 0 -15 l -15 15 l 15 15 z"></path>
                                </g>
                            </g>
                        </Fragment>
                    }

                </Fragment>
            }
        </svg>
    );
}

export default TrendArrow;
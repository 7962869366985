import { Outlet, useParams } from 'react-router-dom'; 
import { useEffect, useState } from 'react';
import { getLabel } from './Utils';
import api from "./Api";
import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';

function Main({ language, translation, setLanguage, setTranslation, navbar, setPresets, activeMenu }) {
    const { languageCode } = useParams();
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);

    useEffect(() => {
        const fetchTranslation = async () => {
            try {
                setIsLoading1(true);
                const response = await api.get(`/translation/${languageCode}`);
                setTranslation(response.data);
                setLanguage(languageCode);
                setIsLoading1(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchTranslation();
    },[languageCode, setLanguage, setTranslation]);

    useEffect(() => {
        const fetchPreset = async () => {
            try {
                setIsLoading2(true);
                const response = await api.get(`/preset`);
                setPresets(response.data);
                setIsLoading2(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchPreset();
    },[setPresets]);

    useEffect(() => {
        document.title = 'REMS ' + getLabel (translation, 'version_text');
    },[translation]);

    return (
        <div className="flex flex-col w-screen h-screen">
            <Header language={language} translation={translation} navbar={navbar} activeMenu={activeMenu} />
            <div className="flex flex-col grow w-full overflow-y-auto items-center pt-[34px]">
                <Outlet />
            </div>
            <Footer />
            {(isLoading1 || isLoading2) && <Loading />}
        </div>
    );
}

export default Main;
import { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import api from "../Api";
import Loading from "../Loading";
import { BsPersonLinesFill } from "react-icons/bs";


function Round({ language, setNavbar, setActiveMenu, forceRefresh, setCurrentRound }) {
    const [isLoading, setIsLoading] = useState(true);
    const [rounds, setRounds] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setActiveMenu(1);
    },[setActiveMenu]);

    useEffect(() => {
        setNavbar(true);
    },[setNavbar]);

    useEffect(() => {
        const fetchRounds = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/round?key=${forceRefresh}`);
                setRounds(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response.data);
                }
            }
        }

        fetchRounds();
    },[forceRefresh]);

    const handleJump = (round) => {
        setCurrentRound({...round, name: round.name + ( round.template_name === '' ? '' : ' - ' + round.template_name)});                
        navigate(`/${language}/personnel`)
    }

    return (
        <div className="w-11/12 py-3">
            <h1 className="text-2xl font-medium text-primary-black">Round Overview</h1>
            <div>
                <div className="flex flex-row text-primary-black py-3">
                    <Link to={`/${language}/round/new`} className="mr-auto">
                        <button className="bg-primary-green text-primary-white text-lg px-10 py-1 rounded-lg">New</button>
                    </Link>
                </div>
                <table className="text-primary-black border-primary-grey text-base text-left w-full">
                    <tbody>
                        <tr className="bg-gray-300">
                            <th className="border-2 px-2">&nbsp;</th>
                            <th className="border-2 w-2/12 px-2">Name</th>
                            <th className="border-2 w-2/12 px-2">Description</th>
                            <th className="border-2 w-2/12 px-2">Head of Round</th>
                            <th className="border-2 w-1/12 px-2">Org Unit</th>
                            <th className="border-2 w-2/12 px-2">Total Employees (Key)</th>
                            <th className="border-2 w-1/12 px-2">Status</th>
                            <th className="border-2 w-2/12 px-2">Facilitator</th>
                        </tr>
                        {rounds.map((round, index) => (
                            <tr key={index} className={index % 2 === 0? `bg-white` : `bg-gray-100`}>
                                <td className="border-2"><div className='cursor-pointer' onClick={(e) => handleJump(round)}><BsPersonLinesFill className="w-6 h-6" /></div></td>
                                <td className="border-2"><Link to={`/${language}/round/`+round.id}><div className="flex flex-row px-2">{round.name}</div></Link></td>
                                <td className="border-2"><Link to={`/${language}/round/`+round.id}><div className="flex flex-row px-2">{round.description}</div></Link></td>
                                <td className="border-2"><Link to={`/${language}/round/`+round.id}><div className="flex flex-row px-2">{round.name_of_manager}</div></Link></td>
                                <td className="border-2"><Link to={`/${language}/round/`+round.id}><div className="flex flex-row px-2">{round.organization_unit}</div></Link></td>
                                <td className="border-2"><Link to={`/${language}/round/`+round.id}><div className="flex flex-row px-2 justify-end">{`${round.total_personnel} (${round.total_key_personnel})`}</div></Link></td>
                                <td className="border-2"><Link to={`/${language}/round/`+round.id}><div className="flex flex-row px-2">{round.status}</div></Link></td>
                                <td className="border-2"><Link to={`/${language}/round/`+round.id}><div className="flex flex-row px-2">{round.facilitator}</div></Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Outlet />
            {isLoading && <Loading />}
        </div>
    );
}

export default Round;
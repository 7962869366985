import { BsInfoCircle } from "react-icons/bs";

function Dialog ({ title, setShowDialog, caption }) {
    const handleOK = () => {
        setShowDialog(false);
    }

    return (
        <div className="modal bg-gray-300 bg-opacity-50 cursor-progress">
            <div className="w-[520px] bg-primary-white drop-shadow-md border border-primary-green flex flex-col items-center text-base">
                <div className="bg-primary-green text-primary-white text-xl font-medium px-4 py-2 items-start mr-auto w-full">{title}</div>
                <div className="text-lg text-primary-black my-8 w-5/6 flex">
                    <div className="w-1/6"><BsInfoCircle className="w-[50px] h-[50px]" /></div>
                    <div className="w-5/6">{caption}</div>
                </div>
                <div className=" text-primary-white font-medium w-full flex justify-center pb-5 text-lg">                    
                    <button className="bg-primary-green rounded-lg h-10 w-40" onClick={handleOK} >OK</button>
                </div>
            </div>
        </div>
    );
}

export default Dialog;
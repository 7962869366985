import { useState, useEffect, Fragment} from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import api from '../Api';
import Loading from "../Loading";

function AssignedToolForm ({ language, presets, setForceRefresh, forceRefresh, currentRound }) {
    const { assignedToolId } = useParams();
    const [assignedTool, setAssignedTool] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [personnels, setPersonnels] = useState([]);
    const [recTools, setRecTools] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const hierarchized = (personnels, manager_id, name_of_manager, level, group_id) => {
            const result = [];
            personnels.filter((personnel) => personnel.name_of_manager === name_of_manager)
                .sort((a,b) => (a.full_name > b.full_name) ? 1 : ((b.full_name > a.full_name) ? -1 : 0))
                .map((personnel, index) => {
                    const hierarchy = hierarchized(personnels, personnel.id, personnel.full_name, level + 1, group_id === '' ? index + '' : (group_id + '.' + index));
                    result.push({...personnel, level: level, group_id: group_id === '' ? index + '' : (group_id + '.' + index), has_child: hierarchy.length > 0, manager_id: manager_id, collapse: false, selected: false});
                    hierarchy.map((personnel) => result.push(personnel));
                    return personnel;
                });
            return result;
        };

        const fetchPersonnels = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/overview/round/${currentRound.id}?key=${forceRefresh}`);
                setPersonnels(hierarchized(response.data, 0, '', 0, ''));
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        };

        fetchPersonnels();
    },[currentRound, forceRefresh]);

    useEffect(() => {
        const fetchTool = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/recommended-tool/round/${currentRound?.id}/all?key=${forceRefresh}`);
                setRecTools(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }

        fetchTool();
    },[currentRound, forceRefresh])

    useEffect(() => {
        const fetchAssignedTool = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/assigned-tool/${assignedToolId}`);
                setAssignedTool(response.data[0]);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        if (assignedToolId !== 'new') {
            fetchAssignedTool();
        } else {
            setAssignedTool({ responsible_id : 0, responsible: '', due_date: '' })
            setIsNew(true);
            setIsLoading(false);
        }
    },[assignedToolId]);

    const handleRententionRange = (e) => {
        let p = personnels.find(personnel => personnel.id === assignedTool.assigned_id);
        let s = personnels.find(personnel => personnel.id === p.manager_id);
    
        switch (e.target.options[e.target.options.selectedIndex].getAttribute('data-key')) {
            case "1": 
                setAssignedTool({...assignedTool, retention_individual: 1, retention_department: 0, retention_company: 0, responsible_id: s.id, responsible: s.full_name, toolbox_id: null, assignedTool: '' });
                break;
            case "2":
                setAssignedTool({...assignedTool, retention_individual: 0, retention_department: 1, retention_company: 0, responsible_id: p.id, responsible: p.full_name, toolbox_id: null, assignedTool: '' });
                break;
            case "3":
                setAssignedTool({...assignedTool, retention_individual: 0, retention_department: 0, retention_company: 1, responsible_id: p.id, responsible: p.full_name, toolbox_id: null, assignedTool: '' });
                break;
            default :
                setAssignedTool({...assignedTool, retention_individual: 0, retention_department: 0, retention_company: 0, responsible_id: 0, responsible: ''});
        }
    }

    const postRound = async () => {
        try {
            setIsLoading(true);
            const response = await api.post('/assigned-tool',assignedTool);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/follow-up`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const putRound = async () => {
        try {
            setIsLoading(true);
            const response = await api.put('/assigned-tool',assignedTool);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/follow-up`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const delAssignedTool = async () => {
        try {
            setIsLoading(true);
            const response = await api.delete(`/assigned-tool/${assignedTool.id}`);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/follow-up`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSave = () => {
        if (!isEdit && !isNew) setIsEdit(true);
        if (isNew) postRound();
        if (isEdit) putRound(); 
    }

    const handleCancel = () => {
        setForceRefresh(crypto.randomUUID());
        navigate(`/${language}/follow-up`);
    }

    const handleDelete = () => {
        delAssignedTool();
    }

    return (
        <Fragment>
            {isLoading && <Loading />}
            {!isLoading &&
                <div className="modal bg-gray-300 bg-opacity-50">
                    <div className="w-[720px] bg-primary-white drop-shadow-md border border-primary-green flex flex-col items-center text-base">
                        <div className="bg-primary-green text-primary-white text-xl font-medium px-4 py-2 items-start mr-auto w-full">{(isNew ? `Add` : (isEdit? `Edit` : `View`)) + ` - Assigned Tool`}</div>
                        <form className="text-primary-black w-5/6 py-8">
                            <fieldset disabled={!(isEdit || isNew)}>
                                <label htmlFor="followup" className="float-left w-3/12 pt-2">Follow up by</label>
                                <input id="followup" autoComplete="off" type="text" key={assignedTool.id} className="p-1 border rounded-sm mb-3 float-left w-9/12" value={assignedTool.responsible} disabled={true} />

                                <label htmlFor="assignedto" className="float-left w-3/12 pt-2">Assigned to</label>
                                <select id="assignedto" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-black disabled:opacity-100" value={assignedTool.assigned} 
                                    onChange={e => setAssignedTool({ ...assignedTool, assigned_id: Number(e.target.options[e.target.options.selectedIndex].getAttribute('data-key')), assigned: e.target.value })} disabled={!isNew}>
                                    <option value=""></option>
                                    {personnels.map((personnel, index) => (
                                        <option key={index} data-key={personnel.id} value={personnel.full_name}>{personnel.full_name}</option>
                                    ))}
                                </select>

                                <label htmlFor="retentionrange" className="float-left w-3/12 pt-2">Retention range</label>
                                <select id="retentionrange" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-black disabled:opacity-100" 
                                    value={assignedTool.retention_individual === 1 ? "Individual" : (assignedTool.retention_department === 1 ? "Team" : (assignedTool.retention_company === 1 ? "Dept/Company" : ""))} 
                                    onChange={e => handleRententionRange(e)} disabled={!isNew}>
                                    <option data-key="0" value=""></option>
                                    <option data-key="1" value="Individual">Individual</option>
                                    <option data-key="2" value="Team">Team</option>
                                    <option data-key="3" value="Dept/Company">Dept/Company</option>
                                </select>

                                <label htmlFor="assignedtool" className="float-left w-3/12 pt-2">Assigned tool</label>
                                <select id="assignedtool" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-black disabled:opacity-100" value={assignedTool.assigned_tool} 
                                    onChange={e => setAssignedTool({ ...assignedTool, toolbox_id: e.target.options[e.target.options.selectedIndex].getAttribute('data-key'), assigned_tool: e.target.value })} disabled={!isNew}>
                                    <option value=""></option>
                                    {recTools
                                        .filter(tool => (assignedTool.retention_individual === 1 && tool.retention_individual === assignedTool.retention_individual) || (assignedTool.retention_department === 1 && tool.retention_department === assignedTool.retention_department) || (assignedTool.retention_company === 1 && tool.retention_company === assignedTool.retention_company))
                                        .filter((item, pos, self) => {
                                            return self.map(tool => tool.id).indexOf(item.id) === pos;
                                        })
                                        .map((tool, index) => (
                                        <option key={index} data-key={tool.id} value={tool.title}>{tool.title}</option>
                                    ))}
                                </select>

                                <label htmlFor="duedate" className="float-left w-3/12 pt-2">Due date</label>
                                <input id="duedate" type="text" className="p-1 border rounded-sm mb-3 float-left w-9/12"  value={assignedTool.due_date} onChange={e => setAssignedTool({ ...assignedTool, due_date: e.target.value })} />

                                {!isNew &&
                                    <Fragment>
                                        <label htmlFor="status" className="float-left w-3/12 pt-2">Status</label>
                                        <select id="status" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-black disabled:opacity-100" value={assignedTool.status} 
                                            onChange={e => setAssignedTool({ ...assignedTool, status_id: e.target.options[e.target.options.selectedIndex].getAttribute('data-key'), status: e.target.value })} >
                                            <option value=""></option>
                                            {presets?.filter(preset => (preset.tag === 'FS')).map((preset, index) => (
                                                <option key={index} data-key={preset.id} value={preset.name}>{preset.name}</option>
                                            ))}
                                        </select>
                                    </Fragment>
                                }
                            </fieldset>
                        </form>
                        <div className=" text-primary-white font-medium w-full flex justify-end pb-8 text-lg">  
                            { !isNew  &&
                                <button className="bg-primary-red rounded-lg h-10 w-40 mr-12" onClick={handleDelete}>Delete</button>
                            }
                            <button className="bg-primary-green rounded-lg h-10 w-40 mr-6" onClick={handleSave}>{isEdit || isNew ? `Save` : `Edit`}</button>
                            <button className="bg-primary-grey rounded-lg h-10 w-40 mr-6" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default AssignedToolForm;

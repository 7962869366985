import { Fragment } from 'react';
import Logo from './Logo';
import Navbar from './Navbar';

function Header ({ language, translation, navbar, activeMenu }) {
    return (
        <Fragment>
            <div className={`${navbar ? 'h-[183px]' : 'h-[133px]'} w-full bg-header bg-no-repeat bg-right-top bg-[#02050c] z-40`}>
                <Logo translation={translation} language={language} />
                {navbar && <Navbar language={language} translation={translation} activeMenu={activeMenu} />}
            </div>
{/*
            <div className="h-[34px] shrink-0"></div>
*/}
        </Fragment>
    );
}

export default Header;
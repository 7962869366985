import { useState, useEffect, Fragment} from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import api from '../Api';
import Loading from "../Loading";

function ToolboxTemplateForm ({ language, setForceRefresh }) {
    const { templateId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [toolboxTemplate, setToolboxTemplate] = useState({id: '', name: '', description: ''});
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchToolboxTemplate = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/question-template/${templateId}`);
                setToolboxTemplate(response.data[0]);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        if (templateId !== 'new') {
            fetchToolboxTemplate();
        } else {
            setIsNew(true);
            setIsLoading(false);
        }
    },[templateId]);

    const postToolboxTemplate = async () => {
        try {
            setIsLoading(true);
            const response = await api.post('/question-template',toolboxTemplate);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/question`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const putToolboxTemplate = async () => {
        try {
            setIsLoading(true);
            const response = await api.put('/question-template',toolboxTemplate);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/question`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const delToolboxTemplate = async () => {
        try {
            setIsLoading(true);
            const response = await api.delete(`/question-template/${templateId}`);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/question`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSave = () => {
        if (!isEdit && !isNew) setIsEdit(true);
        if (isNew) postToolboxTemplate();
        if (isEdit) putToolboxTemplate(); 
    }

    const handleCancel = () => {
        setForceRefresh(crypto.randomUUID());
        navigate(`/${language}/question`);
    }

    const handleDelete = () => {
        delToolboxTemplate();
    }

    return (
        <Fragment>
            {isLoading && <Loading />}
            {!isLoading &&
                <div className="modal bg-gray-300 bg-opacity-50">
                    <div className="w-[720px] bg-primary-white drop-shadow-md border border-primary-green flex flex-col items-center text-base">
                        <div className="bg-primary-green text-primary-white text-xl font-medium px-4 py-2 items-start mr-auto w-full">{(isNew ? `New` : (isEdit? `Edit` : `View`)) + ` - Template`}</div>
                        <form className="text-primary-black w-5/6 py-8">
                            <fieldset disabled={!(isEdit || isNew)}>
                                <label htmlFor="name" className="float-left w-3/12 pt-2">Name</label>
                                <input type="text" key={toolboxTemplate.id} className="p-1 border rounded-sm mb-3 float-left w-9/12" value={toolboxTemplate.name} onChange={e => setToolboxTemplate({ ...toolboxTemplate, name: e.target.value })} />

                                <label htmlFor="description" className="float-left w-3/12 pt-2">Description</label>
                                <textarea rows="4" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={toolboxTemplate.description} onChange={e => setToolboxTemplate({ ...toolboxTemplate, description: e.target.value })} />
                            </fieldset>
                        </form>
                        <div className=" text-primary-white font-medium w-full flex justify-end pb-8 text-lg">  
                            { !isNew  &&
                                <button className="bg-primary-red rounded-lg h-10 w-40 mr-12" onClick={handleDelete}>Delete</button>
                            }
                            <button className="bg-primary-green rounded-lg h-10 w-40 mr-6" onClick={handleSave}>{isEdit || isNew ? `Save` : `Edit`}</button>
                            <button className="bg-primary-grey rounded-lg h-10 w-40 mr-6" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default ToolboxTemplateForm;

import { Fragment } from 'react';

function RatingAllCategoryChart ({ rating_g = 0, rating_y = 0, rating_r = 0, width = 600, height = 600 }) {

    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        let angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
        return {
            x: Math.round(((centerX + (radius * Math.cos(angleInRadians))) + Number.EPSILON) * 1000) / 1000 ,
            y: Math.round(((centerY + (radius * Math.sin(angleInRadians))) + Number.EPSILON) * 1000) / 1000
        };
    }

    const describeArc = (x, y, radius, from, to) => {
        let str = polarToCartesian(x, y, radius, from * 3.6);
        let mid = polarToCartesian(x, y, radius, (((to - from)/2) + from) * 3.6);
        let end = polarToCartesian(x, y, radius, to * 3.6);
        let d = [
            "M", str.x, str.y, 
            "A", radius, radius, 0, 0, 1, mid.x, mid.y, radius, radius, 0, 0, 1, end.x, end.y, 
        ].join(" ");

        return d;       
    }
      
    return (
        <svg width={width} height={height} className="block m-auto" viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="300" cy="300" r="145" stroke="#888e95" strokeWidth="52"/>
            <path stroke="#d7ecd0" strokeWidth="50" d={describeArc(300, 300, 145, 0, Number(rating_g))} />
            <path stroke="#fcf6bc" strokeWidth="50" d={describeArc(300, 300, 145, Number(rating_g), Number(rating_g) + Number(rating_y))} />
            <path stroke="#f7b59b" strokeWidth="50" d={describeArc(300, 300, 145, Number(rating_g) + Number(rating_y), 100)} />

            <line x1="300" y1="300" x2={polarToCartesian(300,300,175,0).x} y2={polarToCartesian(300,300,175,0).y} stroke="#888e95" strokeWidth="12" />
            <line x1="300" y1="300" x2={polarToCartesian(300,300,175,0).x} y2={polarToCartesian(300,300,175,0).y} stroke="white" strokeWidth="10" />

            <line x1="300" y1="300" x2={polarToCartesian(300,300,175,Number(rating_g) * 3.6).x} y2={polarToCartesian(300,300,175,Number(rating_g) * 3.6).y} stroke="#888e95" strokeWidth="12" />
            <line x1="300" y1="300" x2={polarToCartesian(300,300,175,Number(rating_g) * 3.6).x} y2={polarToCartesian(300,300,175,Number(rating_g) * 3.6).y} stroke="white" strokeWidth="10" />

            <line x1="300" y1="300" x2={polarToCartesian(300,300,175,(Number(rating_g) + Number(rating_y)) * 3.6).x} y2={polarToCartesian(300,300,175,(Number(rating_g) + Number(rating_y)) * 3.6).y} stroke="#888e95" strokeWidth="12" />
            <line x1="300" y1="300" x2={polarToCartesian(300,300,175,(Number(rating_g) + Number(rating_y)) * 3.6).x} y2={polarToCartesian(300,300,175,(Number(rating_g) + Number(rating_y)) * 3.6).y} stroke="white" strokeWidth="10" />

            <circle cx="300" cy="300" r="175" stroke="white" strokeWidth="8" />
            <circle cx="300" cy="300" r="119" fill="white" />
            
            { (Number(rating_g) >= 70) &&
                <image href={require('../assets/cthumb.png')} x="200" y="200" height="200" width="200" /> }

            { (Number(rating_g) >= 50 && Number(rating_g) < 70) && 
                <g transform="rotate(270 300 300)">
                    <image href={require('../assets/cthumb.png')} x="200" y="200" height="200" width="200" />
                </g> }

            { (Number(rating_g) < 50) && 
                <g transform="rotate(180 300 300)">
                    <image href={require('../assets/cthumb.png')} x="200" y="200" height="200" width="200" />
                </g> }

            {Number(rating_g) !== 0 &&
                <Fragment> 
                    <line x1={polarToCartesian(300,300,145,(Number(rating_g) / 2) * 3.6).x} y1={polarToCartesian(300,300,145,(Number(rating_g) / 2) * 3.6).y}
                        x2="480" y2={Number(rating_g) / 2 < 25? "130" : "480"} stroke="black" />
                    <text x="490" y={Number(rating_g) / 2 < 25? "140" : "490"} fill="black" textAnchor="start" style={{font: "38px sans-serif"}}>{Number(rating_g)}%</text>
                </Fragment>}

            {Number(rating_y) !== 0 &&
                <Fragment> 
                    <line x1={polarToCartesian(300,300,145,(Number(rating_g) + (Number(rating_y) / 2)) * 3.6).x} y1={polarToCartesian(300,300,145,(Number(rating_g) + (Number(rating_y) / 2)) * 3.6).y}
                        x2={Number(rating_g) + (Number(rating_y) / 2) < 50 ? "480" : "130"} y2="480" stroke="black" />
                    <text x={Number(rating_g) + (Number(rating_y) / 2) < 50 ? "490" : "120"} y="490" fill="black" textAnchor={Number(rating_g) + (Number(rating_y) / 2) < 50 ? "start" : "end"} style={{font: "38px sans-serif"}}>{Number(rating_y)}%</text>
                </Fragment>}

            {Number(rating_r) !== 0 &&
                <Fragment> 
                    <line x1={polarToCartesian(300,300,145,(Number(rating_g) + Number(rating_y) + (Number(rating_r) / 2)) * 3.6).x} y1={polarToCartesian(300,300,145,(Number(rating_g) + Number(rating_y) + (Number(rating_r) / 2)) * 3.6).y}
                        x2="130" y2={Number(rating_g) + Number(rating_y) + (Number(rating_r) / 2) < 75 ? "480" : "130"} stroke="black" />
                    <text x="120" y={Number(rating_g) + Number(rating_y) + (Number(rating_r) / 2) < 75 ? "490" : "140"} fill="black" textAnchor="end" style={{font: "38px sans-serif"}}>{Number(rating_r)}%</text>
                </Fragment>}

        </svg>
    );
}

export default RatingAllCategoryChart;
import { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import api from '../Api';
import Loading from "../Loading";
import { MultiSelect } from "react-multi-select-component";

function ToolboxForm ({ language, setForceRefresh, currentQTemplate }) {
    const { toolboxId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [toolbox, setToolbox] = useState({id: null, template_id: '', title: '', category_leader: false, category_reward: false, category_company: false, category_job: false,
                                            retention_individual: false, retention_department: false, retention_company: false,
                                            target_professional: false, target_bluecollar: false,
                                            phrase: null, description: '', objective:'', implementation: '', decisionprocess: '',
                                            cost: '', effectiveness: '', ease_of_implementation: '', is_deleted: false, is_global: false});
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const navigate = useNavigate();
    const [topics, setTopics] = useState([]);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const fetchToolbox = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/toolbox/${toolboxId}`);
                setToolbox(response.data[0]);
                setTopics(response.data[0].phrase === null ? [] : response.data[0].phrase.substr(1).slice(0, -1).split('`').map((title) => ({ label: title, value: title })));
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        if (toolboxId !== 'new') { 
            fetchToolbox();
        } else {
            setIsNew(true);
            setIsLoading(false);
        }
    },[toolboxId]);

    useEffect(() => {
        const fetchToolboxes = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`/question/template/${currentQTemplate.id}`);
                setQuestions(response.data);
                setIsLoading(false);
            } catch (err) {
                if (err.response) {
                    console.log(err.response);
                }
            }
        }
        fetchToolboxes();
    },[currentQTemplate]);


    const postToolbox = async () => {
        try {
            setIsLoading(true);
            const response = await api.post(`/toolbox/${currentQTemplate.id}`,{...toolbox, template_id: currentQTemplate.id});
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/toolbox` );
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const putToolbox = async () => {
        try {
            setIsLoading(true);
            const response = await api.put('/toolbox',toolbox);
            console.log(response);
            setIsLoading(false);
            setForceRefresh(crypto.randomUUID());
            navigate(`/${language}/toolbox`);
        } catch (err) {
            if (err.response) {
                console.log(err.response);
            }
        }
    }

    const handleSave = () => {
        if (!isEdit && !isNew) setIsEdit(true);
        if (isNew) {
            setToolbox({...toolbox, template_id: currentQTemplate.id});
            postToolbox();
        }
        if (isEdit) {
            if (currentQTemplate.is_global === 1 || toolbox.is_global === 0) {
                putToolbox();
            } else {
//                setToolbox({...toolbox, template_id: currentQTemplate.id});
                postToolbox();
            } 
        } 
    }

    const handleCancel = () => {
        setForceRefresh(crypto.randomUUID());
        navigate(`/${language}/toolbox`);    
    }
/*
    const handleChange = (tags) => {
        setTags(tags);
        setToolbox({...toolbox, phrase: tags?.length !== 0 ? tags.join('`') : null});
    }
*/

    const customValueRenderer = (selected, _options) => {
        return selected.map(({ label }) => label).join(", ");
    };

    const multiselHandle = (e) => {
        setTopics(e);
        setToolbox({ ...toolbox, phrase: '`' + e.map(({label}) => label).join('`') + '`' });
    }

    return (
        <Fragment>
            {isLoading && <Loading />}
            {!isLoading &&
                <div className="modal bg-gray-300 bg-opacity-50">
                    <div className="w-[860px] bg-primary-white drop-shadow-md border border-primary-green flex flex-col items-center text-base">
                        <div className="bg-primary-green text-primary-white text-xl font-semibold px-4 py-2 items-start mr-auto w-full">{(isNew ? `New` : (isEdit? `Edit` : `View`)) + ` - Tool`}</div>
                        <form className="text-primary-black w-5/6 pt-8">
                            <fieldset disabled={!(isEdit || isNew)}>
                                <label htmlFor="title" className="float-left w-3/12 pt-2">Name</label>
                                <input id="title" type="text" key={toolbox.id} className="p-1 border rounded-sm mb-3 float-left w-9/12" value={toolbox.title} onChange={e => setToolbox({ ...toolbox, title: e.target.value })} />

                                <label className="float-left w-3/12 pt-2">Category</label>
                                <div className="w-9/12 flex flex-row justify-between mb-3">
                                    <div className="w-1/4">
                                        <input id="categoryleader" type="checkbox" className="p-1 mt-3 w-4 h-4 bg-primary-grey" checked={toolbox.category_leader} onChange={e => setToolbox({ ...toolbox, category_leader: e.target.checked })} />
                                        <label className="mx-2 pt-2" htmlFor="categoryleader">Leader</label>
                                    </div>
                                    <div className="w-1/4">
                                        <input id="categoryreward" type="checkbox" className="p-1 mt-3 w-4 h-4 bg-primary-grey" checked={toolbox.category_reward} onChange={e => setToolbox({ ...toolbox, category_reward: e.target.checked })} />
                                        <label className="mx-2 pt-2" htmlFor="categoryreward">Reward</label>
                                    </div>
                                    <div className="w-1/4">
                                        <input id="categorycompany" type="checkbox" className="p-1 mt-3 w-4 h-4 bg-primary-grey" checked={toolbox.category_company} onChange={e => setToolbox({ ...toolbox, category_company: e.target.checked })} />
                                        <label className="mx-2 pt-2" htmlFor="categorycompany">Company</label>
                                    </div>
                                    <div className="w-1/4">
                                        <input id="categoryjob" type="checkbox" className="p-1 mt-3 w-4 h-4 bg-primary-grey" checked={toolbox.category_job} onChange={e => setToolbox({ ...toolbox, category_job: e.target.checked })} />
                                        <label className="mx-2 pt-2" htmlFor="categoryjob">Job</label>
                                    </div>
                                </div>

                                <label className="float-left w-3/12 pt-2">Retention Range</label>
                                <div className="w-9/12 flex flex-row justify-between mb-3">
                                    <div className="w-1/3">
                                        <input id="retentionindividual" type="checkbox" className="p-1 mt-3 w-4 h-4 bg-primary-grey" checked={toolbox.retention_individual} onChange={e => setToolbox({ ...toolbox, retention_individual: e.target.checked })} />
                                        <label className="mx-2 pt-2" htmlFor="retentionindividual">Individual</label>
                                    </div>
                                    <div className="w-1/3">
                                        <input id="retentiondepartment" type="checkbox" className="p-1 mt-3 w-4 h-4 bg-primary-grey" checked={toolbox.retention_department} onChange={e => setToolbox({ ...toolbox, retention_department: e.target.checked })} />
                                        <label className="mx-2 pt-2" htmlFor="retentiondepartment">Team</label>
                                    </div>
                                    <div className="w-1/3">
                                        <input id="retentioncompany" type="checkbox" className="p-1 mt-3 w-4 h-4 bg-primary-grey" checked={toolbox.retention_company} onChange={e => setToolbox({ ...toolbox, retention_company: e.target.checked })} />
                                        <label className="mx-2 pt-2" htmlFor="retentioncompany">Dept/Company</label>
                                    </div>
                                </div>

                                <label className="float-left w-3/12 pt-2">Target Group</label>
                                <div className="w-9/12 flex flex-row justify-between mb-3">
                                    <div className="w-1/2">
                                        <input id="targetprofessional" type="checkbox" className="p-1 mt-3 w-4 h-4 bg-primary-grey" checked={toolbox.target_professional} onChange={e => setToolbox({ ...toolbox, target_professional: e.target.checked })} />
                                        <label className="mx-2 pt-2" htmlFor="targetprofessional">White Collar</label>
                                    </div>
                                    <div className="w-1/2">
                                        <input id="targetbluecollar" type="checkbox" className="p-1 mt-3 w-4 h-4 bg-primary-grey" checked={toolbox.target_bluecollar} onChange={e => setToolbox({ ...toolbox, target_bluecollar: e.target.checked })} />
                                        <label className="mx-2 pt-2" htmlFor="targetbluecollar">Blue Collar</label>
                                    </div>
                                </div>

                                <label className="float-left w-3/12 pt-2">Topic</label>
                                <MultiSelect className="tb mb-3 w-9/12 float-left"
                                    options={questions.filter((question) => ((question.work_contract_group === 'Blue' && (toolbox.target_bluecollar === 1 || toolbox.target_bluecollar === true)) || 
                                        (question.work_contract_group === 'White' && (toolbox.target_professional === 1 || toolbox.target_professional === true))) && 
                                        (((toolbox.category_company === 1 || toolbox.category_company === true) && question.question_no.substr(0,1) === 'C') ||
                                        ((toolbox.category_job === 1 || toolbox.category_job === true) && question.question_no.substr(0,1) === 'J') ||
                                        ((toolbox.category_leader === 1 || toolbox.category_leader === true) && question.question_no.substr(0,1) === 'L') ||
                                        ((toolbox.category_reward === 1 || toolbox.category_reward === true) && question.question_no.substr(0,1) === 'R')))
                                        .map(({title: label, title: value}) => ({ label, value }))
                                        .filter((value, index, self) => index === self.findIndex((t) => (t.label === value.label)))}
                                    value={topics}
                                    onChange={multiselHandle}
                                    hasSelectAll={false}
                                    disableSearch={true}
                                    disabled={!(isEdit || isNew)}
                                    valueRenderer={customValueRenderer}
                                    //ClearSelectedIcon={null}
                                    overrideStrings={{"selectSomeItems" : "[ Topic ]"}}
                                />

                                <label htmlFor="description" className="float-left w-3/12 pt-2">Description</label>
                                <textarea id="description" rows="5" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={toolbox.description} onChange={e => setToolbox({ ...toolbox, description: e.target.value })} />

                                <label htmlFor="objective" className="float-left w-3/12 pt-2">Objective</label>
                                <textarea id="objective" rows="5" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={toolbox.objective} onChange={e => setToolbox({ ...toolbox, objective: e.target.value })} />

                                <label htmlFor="implementation" className="float-left w-3/12 pt-2">Implementation</label>
                                <textarea id="implementation" rows="3" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={toolbox.implementation} onChange={e => setToolbox({ ...toolbox, implementation: e.target.value })} />

                                <label htmlFor="decisionprocess" className="float-left w-3/12 pt-2">Decision Process</label>
                                <textarea id="decisionprocess" rows="3" className="p-1 border rounded-sm mb-3 float-left w-9/12" value={toolbox.decisionprocess} onChange={e => setToolbox({ ...toolbox, decisionprocess: e.target.value })} />

                                <label htmlFor="cost" className="float-left w-3/12 pt-2">Cost</label>
                                <select id="cost" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={toolbox.cost} onChange={e => setToolbox({ ...toolbox, cost: e.target.value })}>
                                    <option value=""></option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                </select>

                                <label htmlFor="effective" className="float-left w-3/12 pt-2">Effectiveness</label>
                                <select id="effective" className="p-1 border rounded-sm mb-3 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={toolbox.effectiveness} onChange={e => setToolbox({ ...toolbox, effectiveness: e.target.value })} >
                                    <option value=""></option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                </select>

                                <label htmlFor="easeimplementation" className="float-left w-3/12 pt-2">Ease of Implementation</label>
                                <select id="easeimplementation" className="p-1 border rounded-sm mb-8 float-left w-9/12 disabled:bg-[#efefef]/30 disabled:text-primary-grey disabled:opacity-100" value={toolbox.ease_of_implementation} onChange={e => setToolbox({ ...toolbox, ease_of_implementation: e.target.value })} >
                                    <option value=""></option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                </select>

                            </fieldset>
                        </form>
                        <div className=" text-primary-white font-semibold w-full flex justify-end pb-2 text-lg mb-3">                    
                            <button className="bg-primary-green rounded-lg h-10 w-40 mr-6" onClick={handleSave}>{isEdit || isNew ? `Save` : `Edit`}</button>
                            <button className="bg-primary-grey rounded-lg h-10 w-40 mr-6" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default ToolboxForm;
